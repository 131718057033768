import React, { useCallback, useEffect, useRef } from 'react';
import RangeSliderInput from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { format } from 'date-fns';

const RangeSlider = ({ min, max, value, onChange, darkMode, resetKey }) => {
  const minTimestamp = min.getTime();
  const maxTimestamp = max.getTime();
  const valueTimestamps = value.map(date => date.getTime());
  const initialRender = useRef(true);
  
  // Reset the range slider to full range when resetKey changes
  useEffect(() => {
    // Skip the initial render to prevent unwanted resets
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    
    // Reset the range to min and max when resetKey changes
    if (resetKey) {
      const defaultRange = [new Date(minTimestamp), new Date(maxTimestamp)];
      onChange(defaultRange);
    }
  }, [resetKey, minTimestamp, maxTimestamp, onChange]);

  const handleChange = useCallback((newValue) => {
    const newDates = newValue.map(timestamp => new Date(timestamp));
    onChange(newDates);
  }, [onChange]);

  const formatDate = useCallback((date) => {
    return format(date, 'MMM d, yyyy HH:mm');
  }, []);

  return (
    <div className="w-full">
      <div className="relative pb-8">
        <RangeSliderInput
          min={minTimestamp}
          max={maxTimestamp}
          value={valueTimestamps}
          onInput={handleChange}
          step={3600000} // 1 hour in milliseconds
          className={`range-slider ${darkMode ? 'range-slider-dark' : ''}`}
        />
      </div>
      <div className={`flex justify-between text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        <span>{formatDate(value[0])}</span>
        <span>{formatDate(value[1])}</span>
      </div>
    </div>
  );
};

export default RangeSlider;