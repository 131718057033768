import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Moon, Sun, ChevronDown, BarChart2, Timer, Target, ShipWheel } from 'lucide-react';
import {
  NotificationPanel,
  ToastNotification
} from '../../components/notifications';
import PowerFlowModal from '../../components/powerFlowModal';

export default function ModifiedHeader({
  darkMode,
  setDarkMode,
  user,
  toastNotification,
  setToastNotification,
  notifications,
  markAllAsRead,
  clearNotifications,
  selectedMode,
  setSelectedMode
}) {
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const [isPowerFlowModalOpen, setIsPowerFlowModalOpen] = useState(false);
  const [isModeDropdownOpen, setIsModeDropdownOpen] = useState(false);
  const modeDropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (modeDropdownRef.current && !modeDropdownRef.current.contains(event.target)) {
        setIsModeDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotificationClick = () => {
    setIsNotificationPanelOpen(!isNotificationPanelOpen);
    if (!isNotificationPanelOpen) {
      markAllAsRead();
    }
  };

  const toggleDarkMode = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  }, [setDarkMode]);

  return (
    <div className={`h-14 flex items-center px-4 fixed w-full space-x-4 top-0 z-[1000] ${darkMode ? 'bg-gray-900' : 'bg-white'} border-b`}>
      {toastNotification && (
        <ToastNotification
          message={toastNotification}
          onClose={() => setToastNotification(null)}
          darkMode={darkMode}
        />
      )}
      {/* Logo */}
      <div className="flex items-center">
        <button href="" className="mr-6">
          <img
            src={darkMode ? 'https://www.thinklabs.ai/images/logo.png' : '/icons/logo-light.jpg'}
            alt="Thinklabs Logo"
            className="h-6 w-auto"
          />
        </button>

        {/* Mode Dropdown */}
        <div className="relative" ref={modeDropdownRef}>
          <button
            onClick={() => setIsModeDropdownOpen(!isModeDropdownOpen)}
            className={`text-lg font-semibold flex items-center ${darkMode ? 'text-white' : 'text-gray-900'} hover:bg-transparent focus:outline-none`}
          >
            {selectedMode}
            <ChevronDown className="ml-1 h-4 w-4" />
          </button>

          {isModeDropdownOpen && (
            <div
              className={`absolute top-full left-0 mt-2 w-56 rounded-md shadow-lg ${darkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
                } z-50`}
            >
              <div className="py-1">
                <button
                  onClick={() => {
                    setSelectedMode('DYNAMIC PLANNING');
                    setIsModeDropdownOpen(false);
                  }}
                  className={`block px-4 py-2 text-sm w-full text-left hover:bg-transparent ${selectedMode === 'DYNAMIC PLANNING'
                    ? darkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-900'
                    : darkMode
                      ? 'text-gray-300 hover:text-gray-300'
                      : 'text-gray-700 hover:text-gray-700'
                    }`}
                >
                  DYNAMIC PLANNING
                </button>
                <button
                  onClick={() => {
                    setSelectedMode('GRID ORCHESTRATION');
                    setIsModeDropdownOpen(false);
                  }}
                  className={`block px-4 py-2 text-sm w-full text-left hover:bg-transparent ${selectedMode === 'GRID ORCHESTRATION'
                    ? darkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-900'
                    : darkMode
                      ? 'text-gray-300 hover:text-300'
                      : 'text-gray-700 hover:text-gray-700'
                    }`}
                >
                  GRID ORCHESTRATION
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div style={{ padding: 8, background: '#3661EB', borderRadius: 4, justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
        <img src="/icons/ai.svg" alt="AI" className="w-4 h-4" />
        <div style={{ color: 'white', fontSize: 16, fontWeight: '700', wordWrap: 'break-word' }}>AI-based 8760 Power Flow</div>
      </div> */}

      {/* Spacer */}
      <div className="flex-1"></div>

      {/* PowerFlow Stats Button */}
      {selectedMode === 'DYNAMIC PLANNING' && (
        <button
          onClick={() => setIsPowerFlowModalOpen(true)}
          className={`flex items-center space-x-3 px-4 py-2 rounded-lg ml-4 transition-all duration-200 ${darkMode
            ? 'bg-gray-800 hover:bg-gray-700 text-gray-200'
            : 'bg-blue-50 hover:bg-blue-100 text-gray-700'
            } hover:shadow-md`}
        >
          {/* Accuracy Section */}
          <div className="flex items-center space-x-2">
            <Target className={`w-4 h-4 ${darkMode ? 'text-green-400' : 'text-green-500'
              }`} />
            <span className={`font-medium ${darkMode ? 'text-green-400' : 'text-green-600'
              }`}>
              99.7%
            </span>
          </div>

          {/* Divider */}
          <span className={`h-4 w-px ${darkMode ? 'bg-gray-700' : 'bg-gray-300'
            }`} />

          {/* PowerFlow Section */}
          <div className="flex items-center space-x-2">
            <BarChart2 className={`w-4 h-4 ${darkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            <span className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
              8760 PowerFlow
            </span>
            <Timer className={`w-4 h-4 ${darkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            <span className={darkMode ? 'text-gray-400' : 'text-gray-500'}>
              63 sec
            </span>
          </div>

          {/* Divider */}
          <span className={`h-4 w-px ${darkMode ? 'bg-gray-700' : 'bg-gray-300'
            }`} />

          {/* AI Section */}
          <div className="flex items-center space-x-2">
            <ShipWheel className={`w-4 h-4 ${darkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            <span className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
              Training
            </span>
            <Timer className={`w-4 h-4 ${darkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            <span className={darkMode ? 'text-gray-400' : 'text-gray-500'}>
              4.25 min
            </span>
          </div>
        </button>
      )}

      {/* Right section with actions */}
      <div className="flex items-center space-x-4">
        <div className="relative">
          <NotificationPanel
            isOpen={isNotificationPanelOpen}
            notifications={notifications}
            onClose={() => setIsNotificationPanelOpen(false)}
            onClear={clearNotifications}
            darkMode={darkMode}
          />
        </div>

        <Menu as="div" className="relative ml-3">
          <Menu.Button className="flex items-center hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100 rounded-full p-1">
            <img
              className="h-8 w-8 rounded-full"
              src={'/icons/user.svg'}
              alt=""
            />
            <ChevronDown className="ml-1 h-4 w-4 text-gray-400" aria-hidden="true" />
          </Menu.Button>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none ${darkMode ? 'bg-gray-800' : 'bg-white'
              }`}>
              <Menu.Item>
                {({ active }) => (
                  <div className={`px-4 py-2 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <Sun className="h-4 w-4" />
                        <span>Light</span>
                        <button
                          onClick={toggleDarkMode}
                          className={`relative inline-flex h-5 w-9 items-center rounded-full transition-colors ${darkMode ? 'bg-blue-600' : 'bg-gray-200'
                            }`}
                        >
                          <span
                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${darkMode ? 'translate-x-5' : 'translate-x-0.5'
                              }`}
                          />
                        </button>
                        <Moon className="h-4 w-4" />
                        <span>Dark</span>
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <PowerFlowModal
        isOpen={isPowerFlowModalOpen}
        onClose={() => setIsPowerFlowModalOpen(false)}
        darkMode={darkMode}
      />
    </div>
  );
}