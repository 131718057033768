import React, { useState, useEffect, useRef } from 'react';
import { ThumbsUp, ThumbsDown, SendHorizontal, Mic, Image, Type } from 'lucide-react';

const ChatWindow = ({ darkMode, onPreviewClick, messages: initialMessages = [], onMessagesUpdate }) => {
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Update local messages when prop changes
  useEffect(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  // const [messages, setMessages] = useState([
  //   {
  //     id: 1,
  //     time: '2:14 PM',
  //     sender: 'John Doe',
  //     content: 'Show me top 10 loaded lines, planned work for today.',
  //     avatar: 'https://ui-avatars.com/api/?name=JD&background=random'
  //   },
  //   {
  //     id: 2,
  //     time: '2:15 PM',
  //     sender: 'AI assistant',
  //     content: '2 line overloads',
  //     isAI: true,
  //     data: [
  //       {
  //         time: '8:00 am',
  //         lines: [
  //           { id: '1115', violation: '14.49%', status: 'Warning' },
  //           { id: '121', violation: '1.42%', status: 'Normal' }
  //         ]
  //       },
  //       {
  //         time: '10:00 am',
  //         lines: [
  //           { id: '1115', violation: '14.49%', status: 'Warning' },
  //           { id: '121', violation: '1.42%', status: 'Normal' }
  //         ]
  //       }
  //     ],
  //     recommendations: {
  //       title: 'AI Recommendations',
  //       content: 'ThinkLabs AI recommended to take action to reduce transformer limit temporarily for 3 hours.'
  //     }
  //   }
  // ]);

  const handleSend = () => {
    if (newMessage.trim()) {
      // Create user message
      const userMessage = {
        id: Date.now(),
        time: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
        sender: 'John Doe',
        content: newMessage,
        avatar: 'https://ui-avatars.com/api/?name=JD&background=random'
      };
      
      // Update messages state
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      
      // Propagate change to parent
      if (onMessagesUpdate) {
        onMessagesUpdate(updatedMessages);
      }
      
      setNewMessage('');
      
      // Simulate AI response with typing indicator
      setIsTyping(true);
      
      // Simulate AI response after a delay
      setTimeout(() => {
        setIsTyping(false);
        const aiResponse = {
          id: Date.now() + 1,
          time: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
          sender: 'AI assistant',
          content: 'I\'ve analyzed the data. Here are the results you requested:',
          isAI: true,
          data: [
            {
              time: 'Current time',
              lines: [
                { id: '1115', violation: '14.49%', status: 'Warning' },
                { id: '121', violation: '1.42%', status: 'Normal' },
                { id: '337', violation: '8.21%', status: 'Normal' }
              ]
            }
          ]
        };
        
        const finalMessages = [...updatedMessages, aiResponse];
        setMessages(finalMessages);
        
        // Propagate change to parent
        if (onMessagesUpdate) {
          onMessagesUpdate(finalMessages);
        }
      }, 1500);
    }
  };

  // useEffect(() => {
  //   // Use a timeout to ensure the DOM has updated
  //   const timeoutId = setTimeout(() => {
  //     scrollToBottom();
  //   }, 100);
    
  //   return () => clearTimeout(timeoutId);
  // }, [messages, isTyping]);

  const renderLineData = (lines) => (
    <div className="grid grid-cols-3 gap-2 text-sm mb-2">
      <div className="font-medium">Line #</div>
      <div className="font-medium">Violation %</div>
      <div className="font-medium">Status</div>
      {lines.map((line, idx) => (
        <React.Fragment key={idx}>
          <div>{line.id}</div>
          <div>{line.violation}</div>
          <div className={`${line.status === 'Warning' ? 'text-red-500' : 'text-green-500'} font-medium`}>
            {line.status}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  // Typing indicator component
  const TypingIndicator = () => (
    <div className="flex items-start space-x-2 animate-pulse">
      <img src="/icons/logo.png" alt="AI" className="w-6 h-6 rounded-full mt-1" />
      <div className="flex items-center space-x-1 bg-gray-100 dark:bg-gray-700 rounded-lg p-2">
        <div className="w-2 h-2 rounded-full bg-gray-400 dark:bg-gray-300 animate-bounce" style={{ animationDelay: '0ms' }}></div>
        <div className="w-2 h-2 rounded-full bg-gray-400 dark:bg-gray-300 animate-bounce" style={{ animationDelay: '150ms' }}></div>
        <div className="w-2 h-2 rounded-full bg-gray-400 dark:bg-gray-300 animate-bounce" style={{ animationDelay: '300ms' }}></div>
      </div>
    </div>
  );

  return (
    <div 
      className={`${darkMode ? 'bg-gray-900' : 'bg-white'} flex flex-col h-full`}
      style={{ 
        position: 'absolute',
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0,
        overflow: 'hidden'
      }}
    >
      {/* Chat Messages Container */}
      <div 
        ref={chatContainerRef}
        className={`flex-1 overflow-y-auto ${darkMode ? 'bg-gray-900' : 'bg-white'}`}
        style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: '80px', // Reserve space for input area
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: darkMode ? '#4B5563 #1F2937' : '#D1D5DB #F3F4F6'
        }}
      >
        <div className="space-y-6 px-2 py-4">
          {messages.map((message) => (
            <div key={message.id} className={`flex flex-col space-y-1 ${message.isAI ? '' : 'items-end'}`}>
              <div className={`flex items-center space-x-2 ${message.isAI ? '' : 'flex-row-reverse space-x-reverse'}`}>
                <img
                  src={message.isAI ? "/icons/logo.png" : message.avatar}
                  alt={message.sender}
                  className="w-6 h-6 rounded-full"
                />
                <span className={`font-medium ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>{message.sender}</span>
                <span className={`text-xs ${darkMode ? 'text-gray-500' : 'text-gray-500'}`}>{message.time}</span>
              </div>
              
              <div className={`
                max-w-[90%] rounded-lg px-4 py-2 
                ${message.isAI 
                  ? `${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'} ml-8` 
                  : `${darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'} mr-8`
                }
              `}>
                <p className="text-sm">{message.content}</p>
                
                {message.data && (
                  <div className="mt-3 space-y-3">
                    {message.data.map((timeSlot, idx) => (
                      <div key={idx} className={`p-3 rounded ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
                        <div className={`font-medium mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>{timeSlot.time}</div>
                        {renderLineData(timeSlot.lines)}
                      </div>
                    ))}
                  </div>
                )}
                
                {message.recommendations && (
                  <div className={`mt-3 p-3 rounded ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
                    <div className={`font-medium ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>{message.recommendations.title}</div>
                    <p className={`text-sm mt-1 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>{message.recommendations.content}</p>
                    <div className="flex items-center space-x-4 mt-3">
                      <button className={`flex items-center space-x-1 text-sm px-2 py-1 rounded ${
                        darkMode ? 'bg-gray-600 text-white hover:bg-gray-500' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      } transition-colors`}>
                        <ThumbsUp size={14} />
                        <span>Accept</span>
                      </button>
                      <button className={`text-sm ${darkMode ? 'text-blue-400' : 'text-blue-500'} hover:underline`}>
                        View details
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          
          {isTyping && <TypingIndicator />}
          
          {/* This empty div acts as a scroll target */}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Input Area - Fixed at bottom */}
      <div 
        className={`px-4 py-3 border-t ${darkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'}`}
        style={{ 
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '80px'
        }}
      >
        <div className="flex items-center space-x-2">
          <div className="flex-1 relative">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className={`w-full px-4 py-3 pr-24 rounded-lg border ${
                darkMode 
                  ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500' 
                  : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400'
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            />
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
              <button className={`p-1 rounded-full transition-colors ${
                darkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'
              }`}>
                <Type size={16} />
              </button>
              <button className={`p-1 rounded-full transition-colors ${
                darkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'
              }`}>
                <Mic size={16} />
              </button>
              <button className={`p-1 rounded-full transition-colors ${
                darkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'
              }`}>
                <Image size={16} />
              </button>
            </div>
          </div>
          <button
            onClick={handleSend}
            className={`p-3 rounded-lg ${
              darkMode
                ? 'bg-blue-600 hover:bg-blue-700'
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white transition-colors`}
          >
            <SendHorizontal size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;