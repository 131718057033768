import React, { useEffect, useState } from 'react';

const LoadingOverlay = ({ darkMode, progress, error, onRetry }) => {
  // Use local state for smoother progress transitions
  const [displayProgress, setDisplayProgress] = useState(0);
  
  // Ensure progress is a valid number and within bounds
  const safeProgress = !isNaN(progress) && isFinite(progress) 
    ? Math.min(Math.max(0, progress), 100)
    : 0;
  
  // Update display progress with a slight delay for smoother animation
  useEffect(() => {
    // Fast track to 0 or 100
    if (safeProgress === 0 || safeProgress === 100) {
      setDisplayProgress(safeProgress);
      return;
    }
    
    // For other values, use a slight delay for smoother transitions
    // Only update if the difference is significant
    if (Math.abs(displayProgress - safeProgress) > 1) {
      const timeout = setTimeout(() => {
        setDisplayProgress(prev => {
          // Move toward target progressively
          if (safeProgress > prev) {
            return Math.min(prev + 1, safeProgress);
          } else {
            return Math.max(prev - 1, safeProgress);
          }
        });
      }, 20); // Short delay for smooth animation
      
      return () => clearTimeout(timeout);
    }
  }, [safeProgress, displayProgress]);

  // Round the displayed percentage
  const displayValue = Math.round(displayProgress);

  return (
    <div className={`fixed inset-0 flex flex-col justify-center items-center ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      {error ? (
        <>
          <p className={`text-xl ${darkMode ? 'text-red-400' : 'text-red-600'} mb-4`}>Error loading data</p>
          <button
            onClick={onRetry}
            className={`px-4 py-2 rounded ${
              darkMode
                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            } transition-colors duration-300`}
          >
            Retry
          </button>
        </>
      ) : (
        <>
          <img 
            src="/icons/logo.png" 
            alt="Loading" 
            className="w-20 h-20 animate-spin"
          />
          <p className={`mt-4 text-xl ${darkMode ? 'text-white' : 'text-black'}`}>Loading...</p>
          <div className="w-64 h-2 mt-4 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full ${darkMode ? 'bg-blue-400' : 'bg-blue-600'} transition-all duration-300 ease-out`}
              style={{ width: `${displayValue}%` }}
            ></div>
          </div>
          <p className={`mt-2 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>{displayValue}% Complete</p>
        </>
      )}
    </div>
  );
};

export default LoadingOverlay;