import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { format, addMonths, subMonths, set, isSameDay, isWithinInterval, startOfYear, endOfYear, parseISO } from 'date-fns';

const DateTimePicker = ({
  selectedDate,
  onSelect,
  isOpen,
  onClose,
  darkMode,
  selectedMode,
  worstPerformingHour
}) => {
  // Track current mode to detect changes
  const [currentMode, setCurrentMode] = useState(selectedMode);
  const [prevWorstHour, setPrevWorstHour] = useState(worstPerformingHour);

  // Flag to determine if we're in Grid Orchestration mode
  const isGridMode = selectedMode === 'GRID ORCHESTRATION';

  const [currentMonth, setCurrentMonth] = useState(() => {
    if (selectedDate) {
      const parsedDate = typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate;
      return parsedDate;
    }
    return new Date(2024, 0, 1);
  });

  // Initialize minute value for quarter-hour intervals in Grid mode
  const [selectedMinute, setSelectedMinute] = useState(() => {
    if (selectedDate) {
      const parsedDate = typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate;
      const minutes = parsedDate.getMinutes();
      // Round to nearest 15 minutes
      return Math.round(minutes / 15) * 15 === 60 ? 0 : Math.round(minutes / 15) * 15;
    }
    return 0;
  });

  // Initialize hour from selectedDate
  const [selectedHour, setSelectedHour] = useState(() => {
    if (selectedDate) {
      const parsedDate = typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate;
      return parsedDate.getHours().toString().padStart(2, '0');
    }
    return '00';
  });

  const [tempSelectedDate, setTempSelectedDate] = useState(() => {
    if (selectedDate) {
      return typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate;
    }
    return null;
  });

  const wrapperRef = useRef(null);

  // Define the valid date range (2024 full year)
  const minDate = startOfYear(new Date(2024, 0, 1));
  const maxDate = endOfYear(new Date(2024, 11, 31));

  // Update when selectedDate changes (including hour changes)
  useEffect(() => {
    if (selectedDate) {
      const parsedDate = typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate;

      // Update month display to show the month of the selected date
      setCurrentMonth(parsedDate);

      // Update the selected hour from the parsed date
      setSelectedHour(parsedDate.getHours().toString().padStart(2, '0'));

      // Update the selected minute for Grid mode
      if (isGridMode) {
        const minutes = parsedDate.getMinutes();
        // Round to nearest 15 minutes
        setSelectedMinute(Math.round(minutes / 15) * 15 === 60 ? 0 : Math.round(minutes / 15) * 15);
      }

      // Update the temp selected date
      setTempSelectedDate(parsedDate);

      console.log(`DateTimePicker: Updated with date ${parsedDate} for ${selectedMode} mode`);
    } else {
      console.log(`DateTimePicker: No date provided for ${selectedMode} mode`);
    }

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, selectedDate, selectedMode, isGridMode]);

  // Handle mode changes and worst performing hour changes separately
  useEffect(() => {
    const modeChanged = currentMode !== selectedMode;

    if (modeChanged) {
      console.log(`DateTimePicker: Mode changed from ${currentMode} to ${selectedMode}`);
      setCurrentMode(selectedMode);

      // Reset all state when mode changes
      setTempSelectedDate(null);
      setSelectedHour('00');
      setSelectedMinute(0);
      setPrevWorstHour(null); // Force re-detection of worst hour

      // If we have a worst performing hour for this mode, use it after a short delay
      if (worstPerformingHour) {
        setTimeout(() => {
          try {
            const parsedDate = typeof worstPerformingHour === 'string'
              ? parseISO(worstPerformingHour)
              : worstPerformingHour;

            setCurrentMonth(parsedDate);
            setSelectedHour(parsedDate.getHours().toString().padStart(2, '0'));

            // Set minute for Grid mode
            if (selectedMode === 'GRID ORCHESTRATION') {
              const minutes = parsedDate.getMinutes();
              setSelectedMinute(Math.round(minutes / 15) * 15 === 60 ? 0 : Math.round(minutes / 15) * 15);
            }

            setTempSelectedDate(parsedDate);

            console.log(`DateTimePicker: Applied worst hour for ${selectedMode}: ${worstPerformingHour}`);
          } catch (error) {
            console.error('Error parsing worst performing hour:', error);
          }
        }, 100);
      }
    }
  }, [selectedMode, currentMode, worstPerformingHour]);

  const previousMonth = () => {
    // Disable month navigation in Grid mode
    if (isGridMode) return;

    const newDate = subMonths(currentMonth, 1);
    if (newDate >= minDate) {
      setCurrentMonth(newDate);
    }
  };

  const nextMonth = () => {
    // Disable month navigation in Grid mode
    if (isGridMode) return;

    const newDate = addMonths(currentMonth, 1);
    if (newDate <= maxDate) {
      setCurrentMonth(newDate);
    }
  };

  const isDateInRange = (date) => {
    return isWithinInterval(date, { start: minDate, end: maxDate });
  };

  const daysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    const firstDayOfWeek = firstDay.getDay();

    // Add empty cells for days before first of month
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(null);
    }

    // Add days of month
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const handleDateSelect = (date) => {
    // Disable date selection in Grid mode
    if (isGridMode) return;

    if (!isDateInRange(date)) return;

    // Create date at the selected hour
    const newDate = set(date, {
      hours: parseInt(selectedHour),
      minutes: isGridMode ? selectedMinute : 0,
      seconds: 0,
      milliseconds: 0
    });

    // Update the temp selected date
    setTempSelectedDate(newDate);
  };

  const handleTimeChange = (hours) => {
    setSelectedHour(hours);
    if (tempSelectedDate) {
      const newDate = set(tempSelectedDate, {
        hours: parseInt(hours),
        minutes: isGridMode ? selectedMinute : 0,
        seconds: 0,
        milliseconds: 0
      });
      setTempSelectedDate(newDate);
    }
  };

  const handleMinuteChange = (minutes) => {
    setSelectedMinute(parseInt(minutes));
    if (tempSelectedDate) {
      const newDate = set(tempSelectedDate, {
        hours: parseInt(selectedHour),
        minutes: parseInt(minutes),
        seconds: 0,
        milliseconds: 0
      });
      setTempSelectedDate(newDate);
    }
  };

  const handleOk = () => {
    if (tempSelectedDate) {
      // Format the date to match your data format exactly
      const formattedDate = format(tempSelectedDate, "yyyy-MM-dd'T'HH:mm:ss");
      console.log(`DateTimePicker: Selected date ${formattedDate} for ${selectedMode} mode`);
      onSelect(formattedDate);
    }
    onClose();
  };

  // Add a "Default" button to quickly select the default/worst-performing hour for current mode
  const handleDefault = () => {
    if (worstPerformingHour) {
      console.log(`DateTimePicker: Using worst hour for ${selectedMode}: ${worstPerformingHour}`);
      onSelect(typeof worstPerformingHour === 'string'
        ? worstPerformingHour
        : format(worstPerformingHour, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
    } else if (selectedDate) {
      // Fallback to currently selected date if no worst hour is available
      onSelect(typeof selectedDate === 'string'
        ? selectedDate
        : format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
    }
    onClose();
  };

  // Generate hour options based on mode
  const availableHours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));

  // Generate minute options for Grid mode (15-minute intervals)
  const availableMinutes = ['00', '15', '30', '45'];

  // Check if current tempSelectedDate is different from the provided selectedDate
  const hasChanges = () => {
    if (!tempSelectedDate || !selectedDate) return false;

    const parsedSelectedDate = typeof selectedDate === 'string'
      ? parseISO(selectedDate)
      : selectedDate;

    return (
      tempSelectedDate.getFullYear() !== parsedSelectedDate.getFullYear() ||
      tempSelectedDate.getMonth() !== parsedSelectedDate.getMonth() ||
      tempSelectedDate.getDate() !== parsedSelectedDate.getDate() ||
      tempSelectedDate.getHours() !== parsedSelectedDate.getHours() ||
      (isGridMode && tempSelectedDate.getMinutes() !== parsedSelectedDate.getMinutes())
    );
  };

  return (
    <div
      ref={wrapperRef}
      className={`absolute z-50 mt-1 p-4 rounded-lg shadow-lg w-[320px] ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        }`}
    >

      {isGridMode ? (
        // Grid Mode - Simplified UI with just time selection
        <div className="text-center py-4">
          <p className="mb-4">Time selection for Grid Orchestration:</p>
          {worstPerformingHour && tempSelectedDate && (
            <p className="font-medium mb-4">
              {format(tempSelectedDate, "MMMM d, yyyy")}
            </p>
          )}
        </div>
      ) : (
        // Dynamic Planning Mode - Full calendar UI
        <>
          {/* Calendar Header */}
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={previousMonth}
              disabled={currentMonth <= minDate}
              className={`hover:bg-transparent ${currentMonth <= minDate ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <span className="font-semibold">
              {format(currentMonth, 'MMMM yyyy')}
            </span>
            <button
              onClick={nextMonth}
              disabled={currentMonth >= maxDate}
              className={`hover:bg-transparent ${currentMonth >= maxDate ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>

          {/* Weekday Headers */}
          <div className="grid grid-cols-7 mb-2">
            {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
              <div key={index} className="text-center text-sm font-medium">
                {day}
              </div>
            ))}
          </div>

          {/* Calendar Grid */}
          <div className="grid grid-cols-7 gap-1">
            {daysInMonth().map((date, index) => (
              <div key={index} className="aspect-square">
                {date && isDateInRange(date) && (
                  <button
                    onClick={() => handleDateSelect(date)}
                    className={`w-full h-full flex items-center justify-center rounded-full text-sm 
                      ${tempSelectedDate && isSameDay(date, tempSelectedDate)
                        ? 'bg-[#96ACF0] text-white'
                        : ''
                      }`}
                  >
                    {date.getDate()}
                  </button>
                )}
                {date && !isDateInRange(date) && (
                  <div className="w-full h-full flex items-center justify-center text-sm text-gray-400">
                    {date.getDate()}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {/* Time Picker */}
      <div className="mt-4 flex items-center justify-center space-x-2">
        <select
          value={selectedHour}
          onChange={(e) => handleTimeChange(e.target.value)}
          className={`w-16 p-1 rounded border ${darkMode
              ? 'bg-gray-700 border-gray-600'
              : 'bg-white border-gray-300'
            }`}
        >
          {availableHours.map((hour) => (
            <option key={hour} value={hour}>{hour}</option>
          ))}
        </select>

        {isGridMode ? (
          // In Grid mode, show minutes dropdown
          <>
            <span>:</span>
            <select
              value={selectedMinute.toString()}
              onChange={(e) => handleMinuteChange(e.target.value)}
              className={`w-16 p-1 rounded border ${darkMode
                  ? 'bg-gray-700 border-gray-600'
                  : 'bg-white border-gray-300'
                }`}
            >
              {availableMinutes.map((minute) => (
                <option key={minute} value={minute}>{minute}</option>
              ))}
            </select>
          </>
        ) : (
          // In Dynamic mode, just show fixed minutes
          <span> : 00</span>
        )}
      </div>

      {/* Action Buttons */}
      <div className="mt-4 flex justify-between">
        {/* Use Default button - only show if worstPerformingHour is available */}
        {/* {worstPerformingHour && (
          <button
            onClick={handleDefault}
            className={`px-4 py-2 rounded ${
              darkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            Default
          </button>
        )} */}

        <div className={`${worstPerformingHour ? 'flex-1 text-right' : 'w-full flex justify-end'}`}>
          <button
            onClick={handleOk}
            className="px-4 py-2 rounded bg-black text-white hover:bg-black"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;