import React, { useState } from 'react';
import { ChevronUp, AlertTriangle } from 'lucide-react';

const StatCard = ({ label, value, icon: Icon }) => (
  <div className="flex items-center justify-between p-4 rounded-lg bg-gray-50 dark:bg-white-800">
    <div className="flex items-center space-x-3">
      {Icon && (
        <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
          <Icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
        </div>
      )}
      <div>
        <div className="text-sm text-gray-600 dark:text-gray-600">{label}</div>
        <div className="text-lg font-semibold text-gray-600 dark:text-black">{value}</div>
      </div>
    </div>
  </div>
);

const StateEstimationPanel = ({ darkMode = false, analysisValues }) => {
  const [timeframe, setTimeframe] = useState('1hour');
  const [violationsEnabled, setViolationsEnabled] = useState(true);
  const [availableMeterEnabled, setAvailableMeterEnabled] = useState(true);
  const [aiGeneratedEnabled, setAiGeneratedEnabled] = useState(true);
  const [activeTabP, setActiveTabP] = useState('1hour'); // Default to 'post' tab

  return (
    <div className="space-y-3 p-1">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-lg" style={{ color: '#3661EB', fontWeight: '700', wordWrap: 'break-word' }}>State Estimation Summary</h3>
          {/* <div className="flex rounded-lg overflow-hidden" style={{ border: '1px solid #E5E7EB' }}>
                          <button
                            onClick={() => setActiveTabP('1hour')}
                            className={`px-3 py-1 text-xs transition-colors ${activeTabP === '1hour'
                                ? 'bg-black text-white hover:bg-black'
                                : darkMode
                                  ? 'bg-transparent text-gray-400 hover:text-gray-300'
                                  : 'bg-transparent text-black hover:bg-gray-100'
                              }`}
                          >
                            Next 1 Hour
                          </button>
                          <button
                            onClick={() => setActiveTabP('24hour')}
                            className={`px-3 py-1 text-xs transition-colors ${activeTabP === '24hour'
                                ? 'bg-black text-white hover:bg-black'
                                : darkMode
                                  ? 'bg-transparent text-gray-400 hover:text-gray-300'
                                  : 'bg-transparent text-black hover:bg-gray-100'
                              }`}
                          >
                            Next 24 Hours
                          </button>
                        </div> */}
        </div>

        <div className="space-y-4">
          <div className="p-4 rounded-lg bg-gray-50 dark:bg-white-800">
            <div className="flex justify-between items-start">
              <div className="flex space-x-14">
                <div>
                  <div className="flex items-center justify-start gap-2">
                    <AlertTriangle className="h-5 w-5 text-red-500" />
                    <div className="text-sm text-gray-600 dark:text-gray-600">Total Violations</div>
                  </div>
                  <div className="text-2xl font-bold text-gray-600 dark:text-black mt-2">{analysisValues.totalViolationsCount}</div>
                </div>
                <div className="right-0" style={{ justifyContent: 'flex-start', alignItems: 'flex-end', gap: 2, display: 'flex' }}>
                  <div style={{ width: 10, height: 11, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                  <div style={{ width: 10, height: 21, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                  <div style={{ width: 10, height: 14, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                  <div style={{ width: 10, height: 25, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                  <div style={{ width: 10, height: 35, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                  <div style={{ width: 10, height: 45, background: '#3661EB', borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                </div>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm text-gray-600 dark:text-gray-600">Under voltage</div>
                <div className="flex items-center">
                  <div className="text-xl font-semibold text-gray-600 dark:text-black">{analysisValues.underVoltageCount}</div>
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600 dark:text-gray-600">Over voltage</div>
                <div className="flex items-center">
                  <div className="text-xl font-semibold text-gray-600 dark:text-black">{analysisValues.overVoltageCount}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="grid grid-cols-2 gap-4">
                          <StatCard
                            label="Constraints on assets"
                            value={analysisValues.assetConstraintsCount}
                          />
                          <StatCard
                            label="Congestion on lines"
                            value={analysisValues.linesCongestionCount}
                          />
                        </div> */}
        </div>
      </div>
    </div>
  );
};

export default StateEstimationPanel;