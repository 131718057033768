import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, parseISO } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const RightPanel = ({ isOpen, onClose, panelData, darkMode, selectedScenario, prePostMode = 'pre', selectedMode }) => {
  const [selectedPhases, setSelectedPhases] = useState(new Set());
  const [graphData, setGraphData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSelectedPhases(new Set(['A', 'B', 'C']));
    const fetchData = async () => {
      if (panelData?.data?.assetDetail?.bus_name || panelData?.data?.assetDetail?.line_name || panelData?.data?.name) {
        try {
          let param = '';
          let feederName = '';
          if (selectedScenario === 'base' && selectedMode !== 'GRID ORCHESTRATION') {
            param = '?scenario=base';
          } else if (selectedScenario === 'er4109') {
            param = '?scenario=er050-4109';
          } else if (selectedScenario === 'er4109_1') {
            param = '?scenario=er025-4109';
          } else if (selectedScenario === 'er4139' && selectedMode !== 'GRID ORCHESTRATION') {
            param = prePostMode === 'post' ? '?scenario=4139-post' : '?scenario=4139-pre';
          } else if (selectedScenario === 'er7633' && selectedMode !== 'GRID ORCHESTRATION') {
            // Use different parameter based on prePostMode
            param = prePostMode === 'post' ? '?scenario=7543-post' : '?scenario=7543-pre';
          } else if (selectedMode === 'GRID ORCHESTRATION' && (panelData.title === 'Transformer' || panelData.title === 'Line')) {
            param = '?scenario=base';
          } else if (selectedMode === 'GRID ORCHESTRATION') {
            param = '?scenario=dsse';
          }

          const isLargeNetwork = /^p9ulv/i.test(panelData.data.assetDetail?.bus_name) || /p9u/i.test(panelData.data.assetDetail?.line_name) || /p9u/i.test(panelData.data.name);
          if (isLargeNetwork) {
            feederName = '&feeder_name=large';
            if (selectedScenario === 'er1256' && selectedMode !== 'GRID ORCHESTRATION') {
              param = prePostMode === 'post' ? '?scenario=1155-post' : '?scenario=1155-pre';
            } else if (selectedMode === 'GRID ORCHESTRATION' && (panelData.title === 'Transformer' || panelData.title === 'Line')) {
              param = '?scenario=base';
            } else if (selectedMode === 'GRID ORCHESTRATION') {
              param = '?scenario=dsse';
            }
          } else {
            feederName = '&feeder_name=small';
          }

          const response = await fetch(
            `https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/asset-details/graph${param}&bus_name=${panelData.data.assetDetail?.bus_name || panelData.data.assetDetail?.line_name || panelData.data.name
            }${feederName}`
          );
          const jsonData = await response.json();
          let processedData;
          if (panelData.data.assetDetail?.bus_name) {
            processedData = jsonData.assetDetails.map(item => ({
              time: format(item.timestamp, "MMM d"),
              vmag_a: item.vmag_a,
              vmag_b: item.vmag_b,
              p: (item.p_a_kw + item.p_b_kw + item.p_c_kw) / 3,
              q: (item.q_a_kvar + item.q_b_kvar + item.q_c_kvar) / 3
            }));
          }
          if (panelData.data.assetDetail?.line_name || panelData.data.name) {
            if (panelData.title === 'Transformer') {
              processedData = jsonData.assetDetails.map(item => ({
                time: format(item.timestamp, "MMM d"),
                s_mag_a: item.s_mag_a,
                s_mag_b: item.s_mag_b,
                s_mag_c: item.s_mag_c,
                s_ang_a: item.s_ang_a,
                s_ang_b: item.s_ang_b,
                s_ang_c: item.s_ang_c
              }));
            }
            if (panelData.title === 'Line') {
              processedData = jsonData.assetDetails.map(item => ({
                time: format(item.timestamp, "MMM d"),
                i_mag_a: item.i_mag_a,
                i_mag_b: item.i_mag_b,
                i_mag_c: item.i_mag_c,
                i_ang_a: item.i_ang_a,
                i_ang_b: item.i_ang_b,
                i_ang_c: item.i_ang_c
              }));
            }
          }
          setGraphData(processedData);
        } catch (error) {
          console.error("Error fetching graph data:", error);
        }
      }
    };

    fetchData();
  }, [panelData, selectedScenario, prePostMode]);

  if (!panelData) return null;
  const { title, data } = panelData;
  console.log('panelData:', panelData);

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return (
          <ul className="list-disc pl-5">
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      } else {
        return (
          <div className="pl-2">
            {Object.entries(value).map(([subKey, subValue]) => (
              <div key={subKey}>
                <span className="font-medium">{subKey}</span> {renderValue(subValue)}
              </div>
            ))}
          </div>
        );
      }
    }
    if (value === undefined) return null;
    return ': ' + value?.toString();
  };

  const renderGraph = () => (
    <ResponsiveContainer width="100%" height={300}>
      {(panelData.title === 'Line' || panelData.title === 'Transformer') ? (
        <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis yAxisId="left" label={{ value: `${panelData.title === 'Transformer' ? 'MVA' : 'A'}`, angle: -90, position: 'insideLeft' }} />
          <YAxis yAxisId="right" orientation="right" label={{ value: 'Deg', angle: -90, position: 'insideRight' }} />
          <Tooltip contentStyle={{ backgroundColor: darkMode ? '#1F2937' : '#FFFFFF', border: 'none', borderRadius: '8px' }} />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_mag_a' : 'i_mag_a'}`} stroke="#8B5CF6" name={`${panelData.title === 'Transformer' ? 'S Mag A (MVA)' : 'I Mag A (A)'}`} strokeWidth={2} />
          <Line yAxisId="left" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_mag_b' : 'i_mag_b'}`} stroke="#10B981" name={`${panelData.title === 'Transformer' ? 'S Mag B (MVA)' : 'I Mag B (A)'}`} strokeWidth={2} />
          <Line yAxisId="left" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_mag_c' : 'i_mag_c'}`} stroke="#EF4444" name={`${panelData.title === 'Transformer' ? 'S Mag C (MVA)' : 'I Mag C (A)'}`} strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_ang_a' : 'i_ang_a'}`} stroke="#F59E0B" name={`${panelData.title === 'Transformer' ? 'S Ang A (Deg)' : 'I Ang A (Deg)'}`} strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_ang_b' : 'i_ang_b'}`} stroke="#6B7280" name={`${panelData.title === 'Transformer' ? 'S Ang B (Deg)' : 'I Ang B (Deg)'}`} strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey={`${panelData.title === 'Transformer' ? 's_ang_c' : 'i_ang_c'}`} stroke="#6366F1" name={`${panelData.title === 'Transformer' ? 'S Ang C (Deg)' : 'I Ang C (Deg)'}`} strokeWidth={2} />
        </LineChart>
      ) : (
        <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis yAxisId="left" label={{ value: 'kW/kVAR', angle: -90, position: 'insideLeft' }} />
          <YAxis yAxisId="right" orientation="right" label={{ value: 'V (p.u.)', angle: -90, position: 'insideRight' }} />
          <Tooltip contentStyle={{ backgroundColor: darkMode ? '#1F2937' : '#FFFFFF', border: 'none', borderRadius: '8px' }} />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="p" stroke="#8B5CF6" name="P (kW)" strokeWidth={2} />
          <Line yAxisId="left" type="monotone" dataKey="q" stroke="#10B981" name="Q (kVAR)" strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey="vmag_a" stroke="#F59E0B" name="Vmag A (pu)" strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey="vmag_b" stroke="#EF4444" name="Vmag B (pu)" strokeWidth={2} />
        </LineChart>
      )}
    </ResponsiveContainer>
  );

  const getAssetName = () => {
    if (data) {
      if (data.type === 'Line' || data.type === 'Transformer') {
        return data.assetDetail?.line_name || data.name;
      }
      if (data.type === 'Node' || data.type === 'Load') {
        return data.assetDetail?.bus_name;
      }
      return '';
    }
  };

  const renderPhaseTable = () => {
    // if (!data.assetDetail) return null;

    let phases = [];
    if (data.type === 'Line' && selectedMode !== 'GRID ORCHESTRATION') {
      const i_mag_a = data.assetDetail?.i_mag_a_i || 0;
      const i_mag_b = data.assetDetail?.i_mag_b_i || 0;
      const i_mag_c = data.assetDetail?.i_mag_c_i || 0;
      const i_ang_a = data.assetDetail?.i_ang_a_i || 0;
      const i_ang_b = data.assetDetail?.i_ang_b_i || 0;
      const i_ang_c = data.assetDetail?.i_ang_c_i || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;
      phases = [
        { name: 'A', i_mag: i_mag_a, i_ang: i_ang_a, violated: violations_a },
        { name: 'B', i_mag: i_mag_b, i_ang: i_ang_b, violated: violations_b },
        { name: 'C', i_mag: i_mag_c, i_ang: i_ang_c, violated: violations_c }
      ].filter(phase => (phase.i_mag || phase.i_ang) !== 0);
    } else if (data.type === 'Line' && selectedMode === 'GRID ORCHESTRATION') {
      const i_mag_a = 46.37;
      const i_mag_b = 45.56;
      const i_mag_c = 0.01;
      const i_ang_a = 0.72;
      const i_ang_b = -2.42;
      const i_ang_c = -0.02;
      const violations_a = false;
      const violations_b = false;
      const violations_c = false;
      phases = [
        { name: 'A', i_mag: i_mag_a, i_ang: i_ang_a, violated: violations_a },
        { name: 'B', i_mag: i_mag_b, i_ang: i_ang_b, violated: violations_b },
        { name: 'C', i_mag: i_mag_c, i_ang: i_ang_c, violated: violations_c }
      ].filter(phase => (phase.i_mag || phase.i_ang) !== 0);
    } else if (data.type === 'Transformer' && selectedMode !== 'GRID ORCHESTRATION') {
      const s_mag_a = data.assetDetail?.s_mag_a_i || 0;
      const s_mag_b = data.assetDetail?.s_mag_b_i || 0;
      const s_mag_c = data.assetDetail?.s_mag_c_i || 0;
      const s_ang_a = data.assetDetail?.s_ang_a_i || 0;
      const s_ang_b = data.assetDetail?.s_ang_b_i || 0;
      const s_ang_c = data.assetDetail?.s_ang_c_i || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;
      phases = [
        { name: 'A', s_mag: s_mag_a, s_ang: s_ang_a, violated: violations_a },
        { name: 'B', s_mag: s_mag_b, s_ang: s_ang_b, violated: violations_b },
        { name: 'C', s_mag: s_mag_c, s_ang: s_ang_c, violated: violations_c }
      ].filter(phase => (phase.s_mag || phase.s_ang) !== 0);
    } else if (data.type === 'Transformer' && selectedMode === 'GRID ORCHESTRATION') {
      const s_mag_a = 25.61;
      const s_mag_b = 25.82;
      const s_mag_c = 52.99;
      const s_ang_a = -2.94;
      const s_ang_b = -2.94;
      const s_ang_c = 0.23;
      const violations_a = false;
      const violations_b = false;
      const violations_c = false;
      phases = [
        { name: 'A', s_mag: s_mag_a, s_ang: s_ang_a, violated: violations_a },
        { name: 'B', s_mag: s_mag_b, s_ang: s_ang_b, violated: violations_b },
        { name: 'C', s_mag: s_mag_c, s_ang: s_ang_c, violated: violations_c }
      ].filter(phase => (phase.s_mag || phase.s_ang) !== 0);
    } else {
      const vmag_a = data.assetDetail?.vmag_a || 0;
      const vmag_b = data.assetDetail?.vmag_b || 0;
      const vmag_c = data.assetDetail?.vmag_c || 0;
      const v_ang_a = data.assetDetail?.v_ang_a || 0;
      const v_ang_b = data.assetDetail?.v_ang_b || 0;
      const v_ang_c = data.assetDetail?.v_ang_c || 0;
      const p_a = data.assetDetail?.p_a_kw || 0;
      const p_b = data.assetDetail?.p_b_kw || 0;
      const p_c = data.assetDetail?.p_c_kw || 0;
      const q_a = data.assetDetail?.q_a_kvar || 0;
      const q_b = data.assetDetail?.q_b_kvar || 0;
      const q_c = data.assetDetail?.q_c_kvar || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;

      phases = [
        { name: 'A', vmag: vmag_a, violated: violations_a, p: p_a, q: q_a, v_ang: v_ang_a },
        { name: 'B', vmag: vmag_b, violated: violations_b, p: p_b, q: q_b, v_ang: v_ang_b },
        { name: 'C', vmag: vmag_c, violated: violations_c, p: p_c, q: q_c, v_ang: v_ang_c }
      ].filter(phase => phase.vmag !== 0);
    }

    const filteredPhases = selectedPhases.size > 0
      ? phases.filter(phase => selectedPhases.has(phase.name))
      : phases;
    const hasViolations = phases.some(phase => phase.violated);

    const handlePhaseClick = (phaseName) => {
      setSelectedPhases(prev => {
        const newSelection = new Set(prev);
        if (newSelection.has(phaseName)) {
          newSelection.delete(phaseName);
        } else {
          newSelection.add(phaseName);
        }
        return newSelection;
      });
    };

    return (
      <div className="px-4 py-2">
        {/* Header */}
        <div className="flex justify-between items-start mb-4">
          {(data.type &&
            <div>
              <h2 className="text-lg font-medium">{title}</h2>
              <div className="flex items-center space-x-2 mt-1">
                <div className="text-sm text-gray-600">{getAssetName()}</div>
                <button className="p-1 hover:bg-gray-100 rounded transition-colors"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(getAssetName());
                      // Optional: Add toast notification or visual feedback
                    } catch (err) {
                      console.error('Failed to copy:', err);
                    }
                  }}
                  title="Copy asset name"
                >
                  <svg className="w-4 h-4 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="8" y="8" width="12" height="12" rx="2" strokeWidth="2" />
                  </svg>
                </button>
              </div>
              <div className={`inline-flex items-center px-3 py-1 mt-2 rounded-md text-sm ${hasViolations ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                }`}>
                {hasViolations ? `Phase ${phases.find(p => p.violated)?.name} Violated` : 'No Violation'}
              </div>
              <div className="text-sm text-gray-600 mt-2">

                {data.timestamp ? format(parseISO(data.timestamp), "EEE, dd MMM yyyy | HH:mm:ss 'GMT'") : ''}
              </div>
            </div>
          )}
          {(!data.type &&
            <div>
              <h2 className="text-lg font-medium">{title}</h2>
            </div>
          )}
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-lg">
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Phases Section */}
        {(data.type &&
          <div className="mt-4">
            <div className="text-sm font-medium text-gray-600 mb-2">Phases</div>
            <div className="flex items-center space-x-4">
              {['A', 'B', 'C'].map(phaseName => {
                const phaseData = phases.find(p => p.name === phaseName);
                const isPresent = !!phaseData;
                const isSelected = selectedPhases.has(phaseName);
                const isViolated = isPresent && phaseData.violated;
                return (
                  <div key={phaseName} className="flex items-center">
                    <motion.button
                      onClick={() => isPresent && handlePhaseClick(phaseName)}
                      className={`flex flex-col items-center px-4 py-2 border rounded ${!isPresent
                        ? 'opacity-40 cursor-not-allowed text-gray-400 border-gray-200 hover:bg-gray-200'
                        : 'border-gray-300 hover:border-gray-400 hover:bg-gray-300'
                        }`}
                    >
                      <span className={`text-sm font-medium mb-1 ${isViolated ? 'text-red-600' : 'text-gray-900'
                        }`}>{phaseName}</span>
                      <div className={`h-0.5 w-6 transition-colors ${isPresent && isSelected
                        ? isViolated
                          ? 'bg-red-600'
                          : 'bg-black'
                        : 'bg-transparent'
                        }`} />
                    </motion.button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Data Table */}
        {(data.type &&
          <div className="mt-6 overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <th className="border border-gray-300 px-4 py-2"></th>
                  {filteredPhases.map(phase => (
                    <th key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(data.type === 'Line') ? (
                  <>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Imag (A)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.i_mag.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Iang (rad)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.i_ang.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Violated</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">
                          <span className={phase.violated ? 'text-red-600' : 'text-green-600'}>
                            {phase.violated ? 'Yes' : 'No'}
                          </span>
                        </td>
                      ))}
                    </tr>
                  </>
                ) : data.type === 'Transformer' ? (
                  <>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Smag (kVA)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.s_mag.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Sang (rad)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.s_ang.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Violated</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">
                          <span className={phase.violated ? 'text-red-600' : 'text-green-600'}>
                            {phase.violated ? 'Yes' : 'No'}
                          </span>
                        </td>
                      ))}
                    </tr>
                  </>
                ) : data.type === 'Node' || data.type === 'Load' ? (
                  <>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Vmag (p.u.)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.vmag.toFixed(4)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Vang (rad)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.v_ang.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">P (kW)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.p.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Q (kVAR)</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">{phase.q.toFixed(2)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 font-medium">Violated</td>
                      {filteredPhases.map(phase => (
                        <td key={phase.name} className="border border-gray-300 px-4 py-2 text-center">
                          <span className={phase.violated ? 'text-red-600' : 'text-green-600'}>
                            {phase.violated ? 'Yes' : 'No'}
                          </span>
                        </td>
                      ))}
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        )}

        {/* Time Series Graph Button */}
        {(data.type === 'Node' || data.type === 'Load' || data.type === 'Transformer' || data.type === 'Line') && (
          <>
          <div className="mt-6">
            <motion.button
              onClick={() => setIsModalOpen(true)}
              className={`w-full px-4 py-2 rounded-lg ${darkMode
                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                : 'bg-black hover:bg-black text-white'
                } transition-colors duration-200 flex items-center justify-center`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              View Time Series Graph
            </motion.button>
          </div>
          {/* {(data.assetDetail?.batteryLocation === true && data.type === 'Load') && (
            <>
          <br></br>
          <div classname="mt-6">
              <motion.button
                onClick={() => setIsModalOpen(true)}
                className={`w-full px-4 py-2 rounded-lg ${darkMode
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-black hover:bg-black text-white'
                  } transition-colors duration-200 flex items-center justify-center`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                View Battery Discharge/Charge Profiles
              </motion.button>
          </div>
          </>
          )} */}
          </>
        )}

        {/* Additional Content */}
        {!data.type && (
          <div className="space-y-4 mt-6">
            {Object.entries(data).map(([key, value]) => {
              if (value !== null) {
                return (
                  <motion.div
                    key={key}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} shadow-md`}
                  >
                    <span className="font-semibold text-lg">{value === undefined ? 'No Asset Violations' : key}</span>
                    {renderValue(value)}
                  </motion.div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    );
  };

  const Modal = ({ isOpen, onClose, children }) => (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={`bg-${darkMode ? 'gray-800' : 'white'} p-6 rounded-lg w-11/12 max-w-3xl`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className={`text-xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                Time Series Graph for Asset: {data.assetDetail?.bus_name || data.assetDetail?.line_name || data.name}
              </h2>
              <button
                onClick={onClose}
                className={`p-1 rounded-full ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
                  } transition-colors duration-200`}
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className={`fixed right-4 ${data.type ? 'top-40' : 'top-60'} w-[370px] z-[1000] ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
            } rounded-lg shadow-xl ${data.type ? 'overflow-hidden' : 'overflow-y-auto h-[calc(100vh-20rem)]'}`}
        >
          {renderPhaseTable()}
        </motion.div>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {renderGraph()}
      </Modal>
    </AnimatePresence>
  );
};

export default RightPanel;