import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MapContainer, GeoJSON, TileLayer, Marker, Polyline, ZoomControl, LayersControl, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import RightPanel from './util/RightPanel/rightPanel';
import LoadingOverlay from './util/LoadingOverlay/loadingOverlay';
import Dashboard from './dashboard';
import EnergizationQueueModal from './components/energizationQueueModal';
import AssistantPreviewModal from './components/assistantPreviewModal';
import { X, ChevronLeft, ChevronDown } from 'lucide-react';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';
import 'leaflet.offline';
import { createTileLayerOffline, saveTiles, removeTiles } from 'leaflet.offline';
import OfflineTileLayer from './components/OfflineTileLayer';
import { processGeoJsonData } from './components/joyGeoJsonHandler';
import NetworkViewerTabs from './components/networkViewerTabs';
import NetworkWelcome from './components/networkWelcome';
import StateSummary from './components/stateSummary';
import SelectedTimestampDisplay from './components/selectedTimestampDisplay';
import { parseISO, format } from 'date-fns';

const customMarkerIcons = {
  node: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#3661EB"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  transformer: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.07322 1.42678L1.05163 6.44837C0.87965 6.62035 0.588267 6.4704 0.62825 6.2305L1.46518 1.2089C1.48527 1.08835 1.58957 1 1.71178 1H5.89645C6.11917 1 6.23071 1.26929 6.07322 1.42678Z" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M1.92678 1.42678L6.94837 6.44837C7.12035 6.62035 7.41173 6.4704 7.37175 6.2305L6.53482 1.2089C6.51473 1.08835 6.41043 1 6.28822 1H2.10355C1.88083 1 1.76929 1.26929 1.92678 1.42678Z" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  load: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.42571 3.30894C3.23043 3.62595 2.76958 3.62595 2.57429 3.30894L1.00546 0.762246C0.800248 0.429118 1.03991 -3.86776e-07 1.43117 -3.5257e-07L4.56883 -7.8267e-08C4.9601 -4.40615e-08 5.19976 0.429118 4.99454 0.762246L3.42571 3.30894Z" fill="#3661EB"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  switch: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="11" width="16" height="2" rx="1" fill="#3661EB"/>
            <circle cx="8" cy="12" r="3" fill="#3661EB" stroke="white" stroke-width="1"/>
            <circle cx="16" cy="12" r="3" fill="#3661EB" stroke="white" stroke-width="1"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  terminal: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="8" fill="#3661EB" stroke="white" stroke-width="1"/>
            <circle cx="12" cy="12" r="3" fill="white"/>
            <rect x="11" y="4" width="2" height="4" fill="#3661EB"/>
            <rect x="11" y="16" width="2" height="4" fill="#3661EB"/>
            <rect x="16" y="11" width="4" height="2" fill="#3661EB"/>
            <rect x="4" y="11" width="4" height="2" fill="#3661EB"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  faultIndicator: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3L20 18H4L12 3Z" fill="#FFA500" stroke="#FFA500"/>
            <circle cx="12" cy="14" r="1" fill="white"/>
            <rect x="11" y="8" width="2" height="4" fill="white"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  pothead: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="4" width="8" height="16" rx="2" fill="#3661EB"/>
            <rect x="10" y="7" width="4" height="10" fill="white"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedNode: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#DA3552"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedTransformer: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.07322 1.42678L1.05163 6.44837C0.87965 6.62035 0.588267 6.4704 0.62825 6.2305L1.46518 1.2089C1.48527 1.08835 1.58957 1 1.71178 1H5.89645C6.11917 1 6.23071 1.26929 6.07322 1.42678Z" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M1.92678 1.42678L6.94837 6.44837C7.12035 6.62035 7.41173 6.4704 7.37175 6.2305L6.53482 1.2089C6.51473 1.08835 6.41043 1 6.28822 1H2.10355C1.88083 1 1.76929 1.26929 1.92678 1.42678Z" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedLoad: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.42571 3.30894C3.23043 3.62595 2.76958 3.62595 2.57429 3.30894L1.00546 0.762246C0.800248 0.429118 1.03991 -3.86776e-07 1.43117 -3.5257e-07L4.56883 -7.8267e-08C4.9601 -4.40615e-08 5.19976 0.429118 4.99454 0.762246L3.42571 3.30894Z" fill="#DA3552"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedSwitch: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="11" width="16" height="2" rx="1" fill="#DA3552"/>
            <circle cx="8" cy="12" r="3" fill="#DA3552" stroke="white" stroke-width="1"/>
            <circle cx="16" cy="12" r="3" fill="#DA3552" stroke="white" stroke-width="1"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedTerminal: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="8" fill="#DA3552" stroke="white" stroke-width="1"/>
            <circle cx="12" cy="12" r="3" fill="white"/>
            <rect x="11" y="4" width="2" height="4" fill="#DA3552"/>
            <rect x="11" y="16" width="2" height="4" fill="#DA3552"/>
            <rect x="16" y="11" width="4" height="2" fill="#DA3552"/>
            <rect x="4" y="11" width="4" height="2" fill="#DA3552"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedFaultIndicator: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3L20 18H4L12 3Z" fill="#DA3552" stroke="#DA3552"/>
            <circle cx="12" cy="14" r="1" fill="white"/>
            <rect x="11" y="8" width="2" height="4" fill="white"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedPothead: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="4" width="8" height="16" rx="2" fill="#DA3552"/>
            <rect x="10" y="7" width="4" height="10" fill="white"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  batteryLoad: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.42571 3.30894C3.23043 3.62595 2.76958 3.62595 2.57429 3.30894L1.00546 0.762246C0.800248 0.429118 1.03991 -3.86776e-07 1.43117 -3.5257e-07L4.56883 -7.8267e-08C4.9601 -4.40615e-08 5.19976 0.429118 4.99454 0.762246L3.42571 3.30894Z" fill="#4CAF50"/>
            <circle cx="12" cy="12" r="9" fill="rgba(76, 175, 80, 0.2)" stroke="#4CAF50" stroke-width="1"/>
          </svg>`,
    className: 'custom-div-icon-battery-marker-icon',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  }),
  default: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3H3.5" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M3.5 1V5" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M9 3H6.5" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M6.5 1V5" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedDefault: L.divIcon({
    html: `<svg width="24" height="24" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3H3.5" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M3.5 1V5" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M9 3H6.5" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
            <path d="M6.5 1V5" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
          </svg>`,
    className: 'custom-div-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
};

const DashboardModal = ({ isOpen, onClose, darkMode, selectedScenario }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-[1000] overflow-y-auto">
      {/* Backdrop */}
      <div className="fixed bg-black bg-opacity-50" onClick={onClose}></div>

      {/* Modal */}
      <div className={`fixed left-80 right-0 top-12 bottom-0 ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
        {/* Header */}
        <div className={`flex items-center justify-between p-4 border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'
          }`}>
          <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
            Inspect Analysis Results
          </h2>
          <button
            onClick={onClose}
            className={`p-2 rounded-lg transition-colors ${darkMode
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-200'
              : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
              }`}
          >
            <X size={24} />
          </button>
        </div>

        {/* Dashboard Content */}
        <div className="h-[calc(100vh-4rem)] overflow-y-auto">
          <Dashboard darkMode={darkMode} selectedScenario={selectedScenario} />
        </div>
      </div>
    </div>
  );
};

const MAP_CENTERS = {
  small: [37.75255, -122.39221], // Center of small network
  large: [38.4353, -122.76453], // Center of large network
  joy: [-117.5601628605, 33.8724935386], // Center of small network
  cabana: [-117.5601628605, 34.8724935386], // Center of large network
  danish: [-117.5851917243, 33.9693929232], // Center of Danish network
};

const MAP_ZOOMS = {
  small: 17,
  large: 15,
  joy: 14,
  cabana: 14,
  danish: 14,
  both: 9  // Zoomed out to show both networks
};

const ModifiedGeoJsonViewer = ({ darkMode, selectedScenario, onScenarioChange, selectedNetworks, onNetworkSelection, selectedTimepoint, onTimeSelect, selectedMode, prePostMode, onPrePostToggle, worstPerformingHour, setWorstPerformingHour }) => {
  const [, setGeoJsonData] = useState([]);
  const [isCluster, setIsCluster] = useState(true);
  const [, setSelectedGeoJson] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(14);
  const [mapBounds, setMapBounds] = useState(null);
  const [visibleData, setVisibleData] = useState([]);
  const [detailTimestamp, setDetailTimestamp] = useState(null);
  let [violatedAssets, setViolatedAssets] = useState([]);
  let [violatedLines, setViolatedLines] = useState([]);
  let [violatedTransformers, setViolatedTransformers] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [lineDetails, setLineDetails] = useState(null);
  const [transformerDetails, setTransformerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [, setTotalAssets] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isQueueModalOpen, setIsQueueModalOpen] = useState(false);
  const [isAssistantPreviewOpen, setIsAssistantPreviewOpen] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);
  const [error, setError] = useState(null);
  const mapRef = useRef(null);
  const prevScenario = useRef(selectedScenario);
  const [mapCenter, setMapCenter] = useState(MAP_CENTERS.small);
  const [mapZoom, setMapZoom] = useState(MAP_ZOOMS.small);
  const [joyNetwork, setJoyNetwork] = useState(null);
  const [cabanaNetwork, setCabanaNetwork] = useState(null);
  const [danishNetwork, setDanishNetwork] = useState(null);
  // Add state to track active tab
  const [activeTab, setActiveTab] = useState('network');
  const [mapKey, setMapKey] = useState(0);
  const [showSummary, setShowSummary] = useState(true);
  // Add state to track the default timestamp (worst performing hour)
  const [defaultTimestamp, setDefaultTimestamp] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    message: ''
  });



  const ZOOM_THRESHOLD = 20; // Set the zoom level at which clustering should be disabled

  // Cache helper functions
  const getCacheKey = (network) => {
    return `geoJsonData_${network}_${selectedScenario}`;
  };

  const clearNetworkCaches = () => {
    ['small', 'large', 'joy', 'cabana', 'danish'].forEach(network => {
      localStorage.removeItem(getCacheKey(network));
    });
  };

  // Add this helper function to calculate center when both networks are selected
  const calculateCenterForBothNetworks = () => {
    const small = MAP_CENTERS.small;
    const large = MAP_CENTERS.large;

    return [
      (small[0] + large[0]) / 2,  // Average latitude
      (small[1] + large[1]) / 2   // Average longitude
    ];
  };

  // Enhanced function to handle tab switching
  const handleTabChange = (tab) => {
    const previousTab = activeTab;
    setActiveTab(tab);

    // If switching from dashboard to network, force map reset
    if (previousTab === 'dashboard' && tab === 'network') {
      // Increment map key to force a complete remount/rerender
      setMapKey(prevKey => prevKey + 1);

      // Reset clustering state
      setIsCluster(true);
      setCurrentZoom(mapZoom);

      // Use a timeout to allow the map component to fully remount
      setTimeout(() => {
        if (mapRef.current) {
          // Reset zoom and center
          mapRef.current.setZoom(mapZoom);

          // Adjust view based on selected networks
          if (selectedNetworks.small && selectedNetworks.large) {
            const bounds = L.latLngBounds([
              MAP_CENTERS.small,
              MAP_CENTERS.large
            ]);
            mapRef.current.fitBounds(bounds, {
              padding: [50, 50],
              maxZoom: MAP_ZOOMS.both
            });
          } else {
            const center = selectedNetworks.large ? MAP_CENTERS.large :
              selectedNetworks.joy ? MAP_CENTERS.joy : MAP_CENTERS.small;
            mapRef.current.setView(center, mapZoom, { animate: true });
          }
        }
      }, 100);
    }
  };


  const fetchWithProgress = async (url, progressStart, progressEnd) => {
    const response = await fetch(url);
    const contentLength = +response.headers.get('Content-Length');
    const reader = response.body.getReader();
    let receivedLength = 0;
    let chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
      receivedLength += value.length;

      // Calculate progress with validation
      const progressRange = progressEnd - progressStart;
      const progressIncrement = contentLength
        ? (progressRange * (receivedLength / contentLength))
        : 0;
      const currentProgress = progressStart + progressIncrement;

      // Ensure progress is always valid
      setLoadingProgress(Math.min(Math.max(0, Math.round(currentProgress)), 100));
    }

    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    return JSON.parse(new TextDecoder('utf-8').decode(chunksAll));
  };

  const closePanel = useCallback(() => {
    setIsPanelOpen(false);
    setSelectedFeature(null);
  }, []);

  // Initialize JOY network on component mount
  // useEffect(() => {
  //   const initJoyNetwork = async () => {
  //     try {
  //       const response = await fetch('/joy.geojson');
  //       const joyData = await response.json();

  //       // Process and set the JOY network
  //       const processedJoyData = processGeoJsonData(joyData);
  //       setJoyNetwork(processedJoyData);
  //     } catch (error) {
  //       console.error('Error loading JOY network:', error);
  //     }
  //   };

  //   const initCabanaNetwork = async () => {
  //     try {
  //       const response = await fetch('/cabana.geojson');
  //       const cabanaData = await response.json();

  //       // Process and set the Cabana network
  //       const processedCabanaData = processGeoJsonData(cabanaData);
  //       setCabanaNetwork(processedCabanaData);
  //     } catch (error) {
  //       console.error('Error loading Cabana network:', error);
  //     }
  //   };

  //   const initDanishNetwork = async () => {
  //     try {
  //       const response = await fetch('/danish.geojson');
  //       const danishData = await response.json();

  //       // Process and set the Cabana network
  //       const processedDanishData = processGeoJsonData(danishData);
  //       setDanishNetwork(processedDanishData);
  //     } catch (error) {
  //       console.error('Error loading Danish network:', error);
  //     }
  //   };

  //   initJoyNetwork();
  //   initCabanaNetwork();
  //   initDanishNetwork();
  // }, []);

  //multiselect
  const loadData = useCallback(async (networks, timestamp = null) => {
    // Add timestamp validation at the beginning of the function
    if (timestamp) {
      const isValidTimestamp = selectedMode === 'GRID ORCHESTRATION'
        ? timestamp.toString().startsWith('2025')
        : timestamp.toString().startsWith('2024');

      if (!isValidTimestamp) {
        console.error(`Invalid timestamp for ${selectedMode} mode: ${timestamp}`);
        // Use null instead of the invalid timestamp
        timestamp = selectedMode === 'GRID ORCHESTRATION' ? '2025-03-25T14:00:00Z' : null;
      }
    }
    setIsLoading(true);
    closePanel();
    setLoadingProgress(0); // Start at 0%
    setError(null);

    try {
      // Calculate total number of operations for progress tracking
      const networksToFetch = [];
      if (networks.small) networksToFetch.push('small');
      if (networks.large) networksToFetch.push('large');

      // Count total operations for accurate progress calculation
      const totalOperations = networksToFetch.length + // Network data fetching
        (networksToFetch.length > 0 ? 1 : 0) + // Analysis data
        (networksToFetch.length > 0 ? 1 : 0) + // Violations data
        (networksToFetch.length > 0 ? 1 : 0);  // Asset details

      // Progress per operation (evenly distributed)
      const progressPerOperation = totalOperations > 0 ? 100 / totalOperations : 0;
      let completedOperations = 0;

      // --- 1. Network Data Fetching ---
      // Create a progress update helper function
      const updateProgress = (increment = 1) => {
        completedOperations += increment;
        const newProgress = Math.min(Math.floor((completedOperations / totalOperations) * 100), 99);
        setLoadingProgress(newProgress);
      };

      // Fetch network data
      let data = await Promise.all(networksToFetch.map(async (network) => {
        const cacheKey = getCacheKey(network);
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          if (parsedData.scenario === selectedScenario) {
            // Update progress for this network
            updateProgress();
            return parsedData;
          }
        }

        const response = await fetch(
          `https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/geojson?feederName=${network}`
        );
        const jsonData = await response.json();

        const networkData = {
          ...jsonData,
          feederName: network,
          scenario: selectedScenario
        };

        // Update progress for this network
        updateProgress();
        return networkData;
      }));

      // Add special networks (JOY, Cabana, Danish)
      // if (networks.joy && joyNetwork) {
      //   data.push({
      //     ...processGeoJsonData(joyNetwork),
      //     feederName: 'joy',
      //     scenario: selectedScenario
      //   });
      // }
      // if (networks.cabana && cabanaNetwork) {
      //   data.push({
      //     ...processGeoJsonData(cabanaNetwork),
      //     feederName: 'cabana',
      //     scenario: selectedScenario
      //   });
      // }
      // if (networks.danish && danishNetwork) {
      //   data.push({
      //     ...processGeoJsonData(danishNetwork),
      //     feederName: 'danish',
      //     scenario: selectedScenario
      //   });
      // }

      // Update state with fetched network data
      setGeoJsonData(data);
      setFilteredData(data);

      // Skip remaining operations if no networks fetched
      if (networksToFetch.length === 0) {
        setViolatedAssets([]);
        setViolatedLines([]);
        setViolatedTransformers([]);
        setAssetDetails([]);
        setDetailTimestamp(null);
        setLineDetails([]);
        setTransformerDetails([]);
        setIsLoading(false);
        setLoadingProgress(100); // Ensure we hit 100% when complete
        return;
      }

      // --- 2. Analysis Data ---
      const getScenarioParam = (scenario) => {
        if (selectedMode === 'GRID ORCHESTRATION') {
          return 'dsse';
        }
        if (scenario === 'er7633') {
          return prePostMode === 'post' ? '7543-post' : '7543-pre';
        }
        if (scenario === 'er4139') {
          return prePostMode === 'post' ? '4139-post' : '4139-pre';
        }
        if (scenario === 'er1256') {
          return prePostMode === 'post' ? '1155-post' : '1155-pre';
        }

        // Default mappings for other scenarios
        switch (scenario) {
          case 'base': return 'base';
          case 'er4109': return 'er050-4109';
          case 'er4109_1': return 'er025-4109';
          default: return 'base';
        }
      };

      // Helper function to check if scenario is available for network
      const isScenarioAvailableForNetwork = (network, scenario) => {
        if (network === 'large') {
          return ['base', 'er1256'].includes(scenario);
        }
        return true; // Small network has all scenarios
      };

      // Fetch analysis data
      let analysisData = {};
      if (networksToFetch.length > 0) {
        const analysisPromises = [];

        // Setup analysis data requests
        for (const network of networksToFetch) {
          if (isScenarioAvailableForNetwork(network, selectedScenario)) {
            const endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/analysis';
            const params = new URLSearchParams();
            // Use 'dsse' scenario for GRID ORCHESTRATION mode
            if (selectedMode === 'GRID ORCHESTRATION') {
              params.append('scenario', 'dsse');
              // For GRID ORCHESTRATION mode, we might need to pass the timestamp too
              if (timestamp) {
                params.append('timestamp', timestamp);
              }
              console.log(`Fetching analysis data for GRID ORCHESTRATION, network: ${network}, timestamp: ${timestamp || 'default'}`);
            } else {
              params.append('scenario', getScenarioParam(selectedScenario));
            }
            params.append('feeder_name', network);
            analysisPromises.push(fetch(`${endpoint}?${params.toString()}`).then(r => r.json()));
          }
        }

        if (analysisPromises.length > 0) {
          const analysisResults = await Promise.all(analysisPromises);
          analysisResults.forEach(result => {
            if (result && !result.message) {
              analysisData = { ...analysisData, ...result };
            }
          });
        }

        // Update progress for analysis data
        updateProgress();
      }
      setAnalysisData(analysisData);

      // --- 3. Violations Data ---
      // Add the common timestamp parameter
      const timestampParam = timestamp ? `&timestamp=${timestamp}` : '';

      // Fetch violated assets
      let violationsData = { busViolations: [], lineViolations: [], transformerViolations: [] };
      if (networksToFetch.length > 0) {
        const violationsPromises = [];

        // Setup violation data requests
        for (const network of networksToFetch) {
          if (isScenarioAvailableForNetwork(network, selectedScenario)) {
            const endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/max-violations';
            const params = new URLSearchParams();
            if (selectedMode === 'GRID ORCHESTRATION') {
              params.append('scenario', 'dsse');
            }
            else {
              params.append('scenario', getScenarioParam(selectedScenario));
            }
            params.append('feeder_name', network);
            violationsPromises.push(fetch(`${endpoint}?${params.toString()}${timestampParam}`).then(r => r.json()));
          }
        }

        if (violationsPromises.length > 0) {
          const violationsResults = await Promise.all(violationsPromises);
          let worstPerformingHour1 = null;

          // Store the current mode at the time of processing results to avoid race conditions
          const currentMode = selectedMode;

          violationsResults.forEach(result => {
            if (result && !result.message) {
              violationsData.busViolations = [...violationsData.busViolations, ...result.busViolations];
              violationsData.lineViolations = [...violationsData.lineViolations, ...result.lineViolations];
              violationsData.transformerViolations = [...violationsData.transformerViolations, ...result.transformerViolations];

              if (result.timestamp) {
                // Only process timestamp if we're still in the same mode
                if (selectedMode === currentMode) {
                  worstPerformingHour1 = result.timestamp;
                  violationsData.timestamp = result.timestamp;
                  console.log(`Found worst performing hour for ${currentMode}: ${worstPerformingHour}`);
                }
              }
            }
          });

          if (worstPerformingHour1 && !timestamp) {
            // Store the current mode to avoid race conditions during async operations
            const currentMode = selectedMode;
            console.log(`Processing worst hour for ${currentMode}: ${worstPerformingHour1}`);
            // Store the worst performing hour in the appropriate state based on mode
            // and notify the parent component
            // Only set the timestamp if we're still in the same mode
            if (selectedMode === currentMode) {
              // Set local timestamp state
              setDetailTimestamp(worstPerformingHour1);

              // Create a timestamp object with metadata to help downstream components
              const timestampWithMetadata = {
                value: worstPerformingHour1,
                mode: currentMode,
                isWorstPerformingHour: true
              };

              // If this is a fresh data load (not selecting an existing timestamp),
              // update the worst performing hour in the appropriate state
              if (setWorstPerformingHour) {
                setWorstPerformingHour(worstPerformingHour1, currentMode);
              }

              // For GRID ORCHESTRATION mode, we may get a different format of timestamp
              // Ensure it's properly formatted before using as default
              if (currentMode === 'GRID ORCHESTRATION') {
                console.log("Setting GRID ORCHESTRATION worst performing hour:", worstPerformingHour1);

                // Set it as the default timestamp if not already set
                if (!worstPerformingHour) {
                  setDefaultTimestamp(worstPerformingHour1);
                }

                // Only update selected timepoint if not already set
                if (onTimeSelect && !selectedTimepoint) {
                  onTimeSelect(worstPerformingHour1);
                }
              } else {
                // Standard behavior for Dynamic Planning mode
                console.log("Setting DYNAMIC PLANNING worst performing hour:", worstPerformingHour1);
                if (!worstPerformingHour) {
                  setDefaultTimestamp(worstPerformingHour1);
                }

                if (onTimeSelect && !selectedTimepoint) {
                  onTimeSelect(worstPerformingHour1);
                }
              }
            } else {
              console.log(`Mode changed while fetching data, discarding timestamp for ${currentMode}`);
            }
          }
        }

        // Update progress for violations data
        updateProgress();
      }

      // Update violated assets state
      const violatedAssetsList = violationsData.busViolations.map(asset => asset.bus_name.toLowerCase());
      setViolatedAssets(violatedAssetsList);

      const violatedLinesList = violationsData.lineViolations.map(asset => asset.name);
      setViolatedLines(violatedLinesList);

      const violatedTransformersList = violationsData.transformerViolations.map(asset => asset.name);
      setViolatedTransformers(violatedTransformersList);

      // --- 4. Asset Details ---
      let detailsData = { busDetails: [], lineDetails: [], transformerDetails: [], timestamp: null };
      if (networksToFetch.length > 0) {
        const detailsPromises = [];

        // Setup asset details requests
        for (const network of networksToFetch) {
          if (isScenarioAvailableForNetwork(network, selectedScenario)) {
            const endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/max-violations/asset-details';
            const params = new URLSearchParams();
            if (selectedMode === 'GRID ORCHESTRATION') {
              params.append('scenario', 'dsse');
            }
            else {
              params.append('scenario', getScenarioParam(selectedScenario));
            }
            params.append('feeder_name', network);
            detailsPromises.push(fetch(`${endpoint}?${params.toString()}${timestampParam}`).then(r => r.json()));
          }
        }

        if (detailsPromises.length > 0) {
          const detailsResults = await Promise.all(detailsPromises);
          detailsResults.forEach(result => {
            if (result && !result.message) {
              detailsData.busDetails = [...detailsData.busDetails, ...result.busDetails];
              detailsData.lineDetails = [...detailsData.lineDetails, ...result.lineDetails];
              detailsData.transformerDetails = [...detailsData.transformerDetails, ...result.transformerDetails];
              detailsData.timestamp = result.timestamp || detailsData.timestamp;
            }
          });
        }

        // Update progress for asset details
        updateProgress();
      }

      // Update asset details state
      setAssetDetails(detailsData.busDetails);
      setDetailTimestamp(detailsData.timestamp);
      setLineDetails(detailsData.lineDetails);
      setTransformerDetails(detailsData.transformerDetails);

      setWorstPerformingHour(detailsData.timestamp || 'N/A');
      setTotalAssets(detailsData.busDetails.length || 0);
      setVisibleData([]);

      // Final progress update
      setLoadingProgress(100);
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load data. Please check your connection and try again.');
    } finally {
      setIsLoading(false);
    }
  }, [closePanel, selectedScenario, prePostMode, selectedTimepoint, onTimeSelect]);

  // When selectedTimepoint changes from parent, trigger data loading
  useEffect(() => {
    if (selectedTimepoint) {
      loadData(selectedNetworks, selectedTimepoint);
    }
  }, [selectedTimepoint, loadData, selectedNetworks]);

  // Load data initially and when dependencies change
  useEffect(() => {
    // Only load default data if no timestamp is selected
    if (!selectedTimepoint) {
      loadData(selectedNetworks);
    } else {
      // If we have a timestamp but dependencies changed, reload with that timestamp
      loadData(selectedNetworks, selectedTimepoint);
    }
  }, [selectedScenario, loadData, selectedNetworks, selectedTimepoint]);

  useEffect(() => {
    // Update map center and zoom based on selected networks
    if (selectedNetworks.small && selectedNetworks.large) {
      setMapCenter(calculateCenterForBothNetworks());
      setMapZoom(MAP_ZOOMS.both);
    } else if (selectedNetworks.large) {
      setMapCenter(MAP_CENTERS.large);
      setMapZoom(MAP_ZOOMS.large);
    } else if (selectedNetworks.small) {
      setMapCenter(MAP_CENTERS.small);
      setMapZoom(MAP_ZOOMS.small);
    }
  }, [selectedNetworks]);

  // Add this function to handle map repositioning
  const handleMapReposition = useCallback(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      if (selectedNetworks.small && selectedNetworks.large) {
        // Create bounds that include both network centers
        const bounds = L.latLngBounds([
          MAP_CENTERS.small,
          MAP_CENTERS.large
        ]);
        // Fit map to these bounds with some padding
        map.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: MAP_ZOOMS.both
        });
      } else {
        // Single network selected - center on that network
        const center = selectedNetworks.large ? MAP_CENTERS.large : selectedNetworks.joy ? MAP_CENTERS.joy : MAP_CENTERS.small;
        console.log(center);
        const zoom = selectedNetworks.large ? MAP_ZOOMS.large : selectedNetworks.joy ? MAP_ZOOMS.joy : MAP_ZOOMS.small;
        map.setView(center, zoom, {
          animate: true
        });
      }
    }
  }, [selectedNetworks]);

  // Update the useEffect that handles network selection
  useEffect(() => {
    handleMapReposition();
  }, [selectedNetworks, handleMapReposition]);

  const handleRetry = () => {
    loadData(selectedNetworks);
  };

  //multiselect
  const handleNetworkFilterChange = async (networks) => {
    onNetworkSelection(networks);
    await loadData(networks, selectedTimepoint); // Pass the network selection to loadData
  };

  // Handle time selection from DateTimePicker
  const handleTimeSelect = useCallback((timepoint) => {
    if (onTimeSelect) {
      onTimeSelect(timepoint); // Update parent component state
    }

    // If timepoint is provided, fetch data for that specific time
    if (timepoint) {
      console.log(`Selected timepoint for data fetching: ${timepoint}`);
      loadData(selectedNetworks, timepoint);
    } else {
      // If timepoint is null, load default data
      loadData(selectedNetworks);
    }
  }, [loadData, onTimeSelect, selectedNetworks]);

  // Handle clearing the timestamp
  const handleClearTimestamp = useCallback(() => {
    if (onTimeSelect) {
      if (defaultTimestamp) {
        onTimeSelect(defaultTimestamp);
      }
      else {
        onTimeSelect(null);
      }
    }
    if (defaultTimestamp) {
      loadData(selectedNetworks, defaultTimestamp);
    }
    else {
      loadData(selectedNetworks);
    }
  }, [loadData, onTimeSelect, selectedNetworks]);

  useEffect(() => {
    if (mapBounds && currentZoom >= ZOOM_THRESHOLD) {
      const visible = filteredData.filter(data => {
        if (data.type === 'FeatureCollection') {
          return data.features.some(feature => isFeatureVisible(feature, mapBounds));
        } else if (data.type === 'Feature') {
          return isFeatureVisible(data, mapBounds);
        }
        return false;
      });
      setVisibleData(visible);
    } else {
      setVisibleData([]);
    }
  }, [filteredData, mapBounds, currentZoom]);

  const isFeatureVisible = (feature, bounds) => {
    if (feature.geometry.type === 'Point') {
      const [lon, lat] = feature.geometry.coordinates;
      return bounds.contains([lat, lon]);
    } else if (feature.geometry.type === 'LineString') {
      return feature.geometry.coordinates.some(([lon, lat]) => bounds.contains([lat, lon]));
    }
    return false;
  };

  const MapEvents = () => {
    const map = useMapEvents({
      click: (e) => {
        if (e.originalEvent.target.classList.contains('leaflet-container')) {
          closePanel();
        }
      },
      moveend: () => {
        setMapBounds(map.getBounds());
      },
      zoomend: () => {
        const newZoom = map.getZoom();
        setCurrentZoom(newZoom);

        // Explicitly update clustering state based on zoom level
        const shouldCluster = newZoom < ZOOM_THRESHOLD;
        setIsCluster(shouldCluster);

        setMapBounds(map.getBounds());
      },
    });

    useEffect(() => {
      if (map) {
        const initialZoom = map.getZoom();
        setCurrentZoom(initialZoom);
        setIsCluster(initialZoom < ZOOM_THRESHOLD);
      }
    }, [map, mapKey]);

    return null;
  };

  const isBatteryLocation = (feature) => {
    if (!analysisData?.node_location || prePostMode !== 'post') return false;

    const batteryLocation = analysisData.node_location;

    if (feature.properties.type === 'Load') {
      const loadId = feature.properties.name.split('_')[1];
      return loadId === batteryLocation.toLowerCase() || feature.properties.name === batteryLocation.toLowerCase();
    }

    return feature.properties.name === batteryLocation.toLowerCase();
  };

  const handleBackToCluster = () => {
    setSelectedGeoJson(null);
    setIsCluster(true);
    closePanel();
  };

  const createTooltipContent = (feature) => {
    let isViolated = false;
    let assetId = feature.properties.name;
    let phases = [];
    let batteryIndicator = false;

    // Get battery location from analysis data if in post mode
    const batteryLocation = prePostMode === 'post' &&
      analysisData?.node_location ?
      analysisData.node_location : null;

    // Determine if this asset is the battery location
    if (batteryLocation) {
      if (feature.properties.type === 'Load') {
        const loadId = feature.properties.name.split('_')[1];
        batteryIndicator = loadId === batteryLocation.toLowerCase() ||
          feature.properties.name === batteryLocation.toLowerCase();
      } else {
        batteryIndicator = feature.properties.name === batteryLocation.toLowerCase();
      }
    }

    // Standard tooltip content creation (existing logic)
    if (feature.geometry.type === 'LineString') {
      isViolated = violatedLines.includes(feature.properties.name);

      // For lines, check if there's detailed phase information
      const lineDetail = lineDetails?.find(line => line.line_name.startsWith(feature.properties.name));
      if (lineDetail) {
        // Determine available phases based on current values
        if (lineDetail.i_mag_a_i && lineDetail.i_mag_a_i !== 0) phases.push('A');
        if (lineDetail.i_mag_b_i && lineDetail.i_mag_b_i !== 0) phases.push('B');
        if (lineDetail.i_mag_c_i && lineDetail.i_mag_c_i !== 0) phases.push('C');
      } else {
        phases = ['A', 'B', 'C']; // Default if no details available
      }
    } else if (feature.properties.type === 'Transformer') {
      isViolated = violatedTransformers.includes(feature.properties.name);

      // Check for transformer phase details
      const transformerDetail = transformerDetails?.find(
        transformer => transformer.line_name.startsWith(feature.properties.name)
      );

      if (transformerDetail) {
        if (transformerDetail.s_mag_a_i && transformerDetail.s_mag_a_i !== 0) phases.push('A');
        if (transformerDetail.s_mag_b_i && transformerDetail.s_mag_b_i !== 0) phases.push('B');
        if (transformerDetail.s_mag_c_i && transformerDetail.s_mag_c_i !== 0) phases.push('C');
      } else {
        phases = ['A', 'B', 'C']; // Default
      }
    } else if (feature.properties.type === 'Load') {
      assetId = feature.properties.name.split('_')[1];
      isViolated = violatedAssets.includes(assetId);

      // Get load phase details
      const assetDetail = assetDetails?.find(asset => asset.bus_name.toLowerCase().startsWith(assetId));
      if (assetDetail) {
        if (assetDetail.vmag_a && assetDetail.vmag_a !== 0) phases.push('A');
        if (assetDetail.vmag_b && assetDetail.vmag_b !== 0) phases.push('B');
        if (assetDetail.vmag_c && assetDetail.vmag_c !== 0) phases.push('C');
      } else {
        phases = ['A', 'B', 'C']; // Default
      }
    } else {
      isViolated = violatedAssets.includes(feature.properties.name);

      // Node or other asset type
      const assetDetail = assetDetails?.find(asset => asset.bus_name.toLowerCase().startsWith(feature.properties.name));
      if (assetDetail) {
        if (assetDetail.vmag_a && assetDetail.vmag_a !== 0) phases.push('A');
        if (assetDetail.vmag_b && assetDetail.vmag_b !== 0) phases.push('B');
        if (assetDetail.vmag_c && assetDetail.vmag_c !== 0) phases.push('C');
      } else {
        phases = ['A', 'B', 'C']; // Default
      }
    }

    // If no phases detected, use default
    if (phases.length === 0) {
      phases = ['A', 'B', 'C'];
    }

    // Create tooltip HTML content with optional battery indicator
    let tooltipContent = `
      <div class="tooltip-content">
        <div class="tooltip-header">${feature.properties.type}</div>
        <div class="tooltip-row">ID: ${assetId}</div>
        <div class="tooltip-row">Phases: ${phases.join(', ')}</div>
        <div class="tooltip-row ${isViolated ? 'tooltip-violated' : ''}">
          Status: ${isViolated ? 'Violated' : 'Normal'}
        </div>`;

    // Add battery indicator row if this is the battery location
    if (batteryIndicator) {
      const batteryDetails = analysisData?.solution ?
        `${analysisData.solution.batteryCapacity} / ${analysisData.solution.batteryEnergy}` :
        'Energy Storage';

      tooltipContent += `
        <div class="tooltip-row tooltip-battery">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="vertical-align: middle; margin-right: 4px;">
            <path d="M11.3334 4.66669V3.33335C11.3334 2.96669 11.2001 2.65558 10.9334 2.40002C10.6667 2.14447 10.35 2.01669 9.98337 2.01669H6.01671C5.65004 2.01669 5.33893 2.14447 5.08337 2.40002C4.82782 2.65558 4.70004 2.96669 4.70004 3.33335V4.66669H2.66671C2.30004 4.66669 1.98615 4.79724 1.72504 5.05835C1.46393 5.31946 1.33337 5.63335 1.33337 6.00002V12.6667C1.33337 13.0334 1.46393 13.3473 1.72504 13.6084C1.98615 13.8695 2.30004 14 2.66671 14H13.3334C13.7 14 14.0139 13.8695 14.275 13.6084C14.5362 13.3473 14.6667 13.0334 14.6667 12.6667V6.00002C14.6667 5.63335 14.5362 5.31946 14.275 5.05835C14.0139 4.79724 13.7 4.66669 13.3334 4.66669H11.3334ZM6.00004 3.33335H10V4.66669H6.00004V3.33335ZM13.3334 12.6667H2.66671V6.00002H13.3334V12.6667ZM8.00004 12C8.31115 12 8.57782 11.8889 8.80004 11.6667C9.02226 11.4445 9.13337 11.1778 9.13337 10.8667V9.13335H10.8667C11.1778 9.13335 11.4445 9.02224 11.6667 8.80002C11.8889 8.5778 12 8.31113 12 8.00002C12 7.6889 11.8889 7.42224 11.6667 7.20002C11.4445 6.9778 11.1778 6.86669 10.8667 6.86669H9.13337V5.13335C9.13337 4.82224 9.02226 4.55558 8.80004 4.33335C8.57782 4.11113 8.31115 4.00002 8.00004 4.00002C7.68893 4.00002 7.42226 4.11113 7.20004 4.33335C6.97782 4.55558 6.86671 4.82224 6.86671 5.13335V6.86669H5.13337C4.82226 6.86669 4.5556 6.9778 4.33337 7.20002C4.11115 7.42224 4.00004 7.6889 4.00004 8.00002C4.00004 8.31113 4.11115 8.5778 4.33337 8.80002C4.5556 9.02224 4.82226 9.13335 5.13337 9.13335H6.86671V10.8667C6.86671 11.1778 6.97782 11.4445 7.20004 11.6667C7.42226 11.8889 7.68893 12 8.00004 12Z" fill="#4CAF50"/>
          </svg>
          Battery: ${batteryDetails}
        </div>`;
    }

    tooltipContent += `</div>`;

    return tooltipContent;
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const tooltipContent = createTooltipContent(feature);

      // Important: Create a custom tooltip implementation to avoid the black border issue
      // This approach uses a custom DIV element instead of Leaflet's built-in tooltip
      if (feature.geometry.type === 'LineString') {
        // Create a tooltip div when hovering
        const customTooltip = L.DomUtil.create('div', `custom-tooltip ${darkMode ? 'dark-custom-tooltip' : 'light-custom-tooltip'}`);
        customTooltip.innerHTML = tooltipContent;
        customTooltip.style.display = 'none';
        document.body.appendChild(customTooltip);

        // Show tooltip on mouseover
        layer.on('mouseover', function (e) {
          // Position tooltip near mouse
          customTooltip.style.display = 'block';
          positionTooltip(e, customTooltip);
        });

        // Update tooltip position when mouse moves
        layer.on('mousemove', function (e) {
          positionTooltip(e, customTooltip);
        });

        // Hide tooltip on mouseout
        layer.on('mouseout', function () {
          customTooltip.style.display = 'none';
        });

        // Clean up tooltip on click to prevent double tooltips
        layer.on('click', function () {
          customTooltip.style.display = 'none';
        });

        // Clean up when map is destroyed
        mapRef.current?.on('unload', function () {
          if (customTooltip && customTooltip.parentNode) {
            customTooltip.parentNode.removeChild(customTooltip);
          }
        });

      } else {
        // For non-line features, bind tooltip normally
        layer.bindTooltip(tooltipContent, {
          permanent: false,
          direction: 'top',
          className: `${darkMode ? 'dark-tooltip' : 'light-tooltip'}`
        });
      }

      // Helper function to position the custom tooltip
      function positionTooltip(e, tooltipElement) {
        const point = mapRef.current.latLngToContainerPoint(e.latlng);
        tooltipElement.style.position = 'absolute';
        tooltipElement.style.left = `${point.x + 100}px`;
        tooltipElement.style.top = `${point.y - tooltipElement.offsetHeight + 150}px`;
        tooltipElement.style.zIndex = '1000';
      }
      layer.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        setSelectedFeature(feature);
        let assetId;
        if (feature.properties.type === 'Transformer') {
          assetId = feature.properties.name;
          const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        } else {
          if (feature.properties.type === 'Load') {
            assetId = feature.properties.name.split('_')[1];
          } else {
            assetId = feature.properties.name;
          }
          const assetDetail = assetDetails?.find(asset => asset.bus_name.toLowerCase().startsWith(assetId));
          if (isBatteryLocation(feature)) {
            assetDetail.batteryLocation = true;
          }
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        }
      });

      if (feature.geometry.type === 'LineString') {
        layer.on('click', (e) => {
          L.DomEvent.stopPropagation(e);
          setSelectedFeature(feature);
          const assetDetail = lineDetails?.find(asset => asset.line_name.startsWith(feature.properties.name));
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        });

        // Update mouseover/mouseout handlers
        layer.on('mouseover', () => {
          const isViolated = violatedLines.includes(feature.properties.name);
          layer.setStyle({
            weight: 5,
            color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'),
            opacity: 0.9
          });
        });

        layer.on('mouseout', () => {
          if (selectedFeature && selectedFeature.properties.name === feature.properties.name) {
            // If this is the selected feature, maintain the selection style
            const isViolated = violatedLines.includes(feature.properties.name);
            layer.setStyle({
              weight: 5,
              color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'),
              opacity: 0.9
            });
          } else {
            // Reset to default style
            const isViolated = violatedLines.includes(feature.properties.name);
            layer.setStyle({
              weight: 3,
              color: isViolated ? '#e53e3e' : '#2f855a',
              opacity: 0.9
            });
          }
        });
      }
    }
  };

  const haloIcon = L.divIcon({
    html: `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-color="#ecc94b" flood-opacity="0.2"/>
                <feComposite in2="blur" operator="in"/>
                <feMerge>
                  <feMergeNode/>
                  <feMergeNode in="SourceGraphic"/>
                </feMerge>
              </filter>
              <radialGradient id="aura" cx="50%" cy="50%" r="70%">
                <stop offset="0%" stop-color="#ecc94b" stop-opacity="0.2"/>
                <stop offset="60%" stop-color="#ecc94b" stop-opacity="0.1"/>
                <stop offset="100%" stop-color="#ecc94b" stop-opacity="0"/>
              </radialGradient>
            </defs>
            <circle cx="12" cy="12" r="10" fill="url(#aura)"/>
            <circle cx="12" cy="12" r="6" fill="#ecc94b" fill-opacity="0.15" filter="url(#glow)"/>
            <circle cx="12" cy="12" r="5.75" stroke="#ecc94b" stroke-opacity="0.3" stroke-width="0.5"/>
          </svg>`,
    className: 'halo-icon',
    iconSize: [60, 60],
    iconAnchor: [30, 30],
  });

  const createHaloOverlay = (feature) => {
    if (!selectedFeature || feature.properties.name !== selectedFeature.properties.name) {
      return null;
    }

    if (feature.geometry?.type === 'LineString') {
      const { coordinates } = feature.geometry;
      const latlngs = coordinates.map(([lon, lat]) => [lat, lon]);
      return (
        <Polyline
          positions={latlngs}
          pathOptions={{ color: darkMode ? '#faf089' : '#ecc94b', weight: 15, opacity: 0.5 }}
        />
      );
    }
    if (feature.geometry?.type === 'Point') {
      const { coordinates } = feature.geometry;
      return (
        <Marker
          position={[coordinates[1], coordinates[0]]}
          icon={haloIcon}
        />
      );
    }
    return null;
  };
  //return svg icon based on asset type
  const getMarkerSVG = (type, isViolated) => {
    switch (type) {
      case 'Node':
        return isViolated ? customMarkerIcons.violatedNode : customMarkerIcons.node;
      case 'Transformer':
        return isViolated ? customMarkerIcons.violatedTransformer : customMarkerIcons.transformer;
      case 'Load':
        return isViolated ? customMarkerIcons.violatedLoad : customMarkerIcons.load;
      case 'Switch':
        return isViolated ? customMarkerIcons.violatedSwitch : customMarkerIcons.switch;
      case 'Terminal':
        return isViolated ? customMarkerIcons.violatedTerminal : customMarkerIcons.terminal;
      case 'FaultIndicator':
        return isViolated ? customMarkerIcons.violatedFaultIndicator : customMarkerIcons.faultIndicator;
      case 'Pothead':
        return isViolated ? customMarkerIcons.violatedPothead : customMarkerIcons.pothead;
      default:
        return isViolated ? customMarkerIcons.violatedNode : customMarkerIcons.node;
    }
  };

  const createClusterCustomIcon = (cluster) => {
    const childCount = cluster.getChildCount();

    // Check if we're in pre mode (show violations) or post mode (don't highlight violations)
    const shouldShowViolations = prePostMode === 'pre' || prePostMode === undefined;

    // Only count violations if we're in pre mode
    const violatedCount = shouldShowViolations ?
      cluster.getAllChildMarkers().filter(marker =>
        (violatedAssets.includes(marker.feature?.properties?.name) ||
          violatedTransformers.includes(marker.feature?.properties?.name)) &&
        isCluster
      ).length : 0;

    let className = 'marker-cluster-custom';
    let count = childCount;

    // Only apply violation styling in pre mode
    if (shouldShowViolations && violatedCount > 0) {
      className += ' marker-cluster-violated';
      count = violatedCount;
    }

    return L.divIcon({
      html: `<div><span>${count}</span></div>`,
      className: className,
      iconSize: L.point(40, 40)
    });
  };

  const pointToLayer = (feature, latlng) => {
    if (feature.geometry.type === 'Point') {
      let isViolated;
      let isBattery = false;
      let zIndexOffset = 0;  // Base zIndex

      // Check if this is a battery location
      isBattery = prePostMode === 'post' && isBatteryLocation(feature);

      if (prePostMode === 'pre' || prePostMode === undefined) {
        // Determine violation state based on type
        switch (feature.properties.type) {
          case 'Node':
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 400;  // Base priority
            break;
          case 'Load':
            isViolated = violatedAssets.includes(feature.properties.name.split('_')[1]);
            if (isBatteryLocation(feature)) {
              zIndexOffset = 800;  // Highest priority for battery
            }
            else {
              zIndexOffset = 600;
            }
            break;
          case 'Switch':
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 1000;  // Highest priority for switches
            break;
          case 'Terminal':
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 750;  // Between Pothead and Transformer priority
            break;
          case 'FaultIndicator':
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 900;  // High priority for indicators
            break;
          case 'Pothead':
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 700;
            break;
          case 'Transformer':
            isViolated = violatedTransformers.includes(feature.properties.name);
            zIndexOffset = 800;
            break;
          default:
            isViolated = violatedAssets.includes(feature.properties.name);
            zIndexOffset = 400;
        }
      }

      let icon;

      // Battery icon takes precedence in post mode
      if (isBattery) {
        icon = customMarkerIcons.batteryLoad;
      } else if (isViolated) {
        switch (feature.properties.type) {
          case 'Node':
            icon = customMarkerIcons.violatedNode;
            break;
          case 'Transformer':
            icon = customMarkerIcons.violatedTransformer;
            break;
          case 'Load':
            icon = customMarkerIcons.violatedLoad;
            break;
          case 'Switch':
            icon = customMarkerIcons.violatedSwitch;
            break;
          case 'Terminal':
            icon = customMarkerIcons.violatedTerminal;
            break;
          case 'FaultIndicator':
            icon = customMarkerIcons.violatedFaultIndicator;
            break;
          case 'Pothead':
            icon = customMarkerIcons.violatedPothead;
            break;
          default:
            icon = customMarkerIcons.violatedNode;
        }
      } else {
        switch (feature.properties.type) {
          case 'Node':
            icon = customMarkerIcons.node;
            break;
          case 'Transformer':
            icon = customMarkerIcons.transformer;
            break;
          case 'Load':
            icon = customMarkerIcons.load;
            break;
          case 'Switch':
            icon = customMarkerIcons.switch;
            break;
          case 'Terminal':
            icon = customMarkerIcons.terminal;
            break;
          case 'FaultIndicator':
            icon = customMarkerIcons.faultIndicator;
            break;
          case 'Pothead':
            icon = customMarkerIcons.pothead;
            break;
          default:
            icon = customMarkerIcons.node;
        }
      }

      const markerOptions = {
        icon,
        className: isViolated ? 'violated-marker' : (isBattery ? 'battery-marker' : ''),
        zIndexOffset: zIndexOffset
      };
      const marker = L.marker(latlng, markerOptions);
      const tooltipContent = createTooltipContent(feature);
      marker.bindTooltip(tooltipContent, {
        permanent: false,
        direction: 'top',
        offset: [0, -5], // Slight offset to prevent overlap with the icon
        className: `${darkMode ? 'dark-tooltip' : 'light-tooltip'}`
      });
      marker.on('click', (e) => {
        L.DomEvent.stopPropagation(e);

        // Find all markers at this location
        const clickedLatLng = marker.getLatLng();
        const overlappingMarkers = [];

        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            const layerLatLng = layer.getLatLng();
            // Check if markers are at the same location (within a small threshold)
            if (clickedLatLng.distanceTo(layerLatLng) < 1) {
              // Only include markers with valid features and properties
              if (layer.feature?.properties?.name && layer.feature?.properties?.type) {
                overlappingMarkers.push(layer);
              }
            }
          }
        });

        // If there are overlapping markers, show selection menu
        if (overlappingMarkers.length > 1 && !isBatteryLocation(feature)) {
          const div = document.createElement('div');
          div.className = `${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} shadow-lg rounded-lg p-2 text-sm`;

          // Filter out duplicates and ensure every marker has valid properties
          const uniqueMarkers = overlappingMarkers
            .filter((m, i, arr) =>
              arr.findIndex((a) => a.feature?.properties.name === m.feature?.properties.name) === i)
            .filter(m => m.feature?.properties.name && m.feature?.properties.type);

          const content = `
            <div class="font-medium mb-2">Select Asset:</div>
            <div class="space-y-1">
              ${uniqueMarkers.map((m, i) => `
                <button class="w-full text-left px-3 py-2 rounded hover:bg-${darkMode ? 'gray-700' : 'gray-100'} asset-select" data-index="${i}">
                  ${getMarkerSVG(m.feature?.properties.type,
            isViolated).options.html}
                  ${m.feature?.properties.type}: ${m.feature?.properties.name}
                </button>
              `).join('')}
            </div>
          `;

          div.innerHTML = content;

          // Create and open popup, but don't set selected feature or open panel yet
          const popup = L.popup({
            closeButton: true,
            className: 'asset-selection-popup',
            offset: [0, -10]
          })
            .setLatLng(clickedLatLng)
            .setContent(div)
            .openOn(mapRef.current);

          // Add click handlers to buttons
          div.querySelectorAll('.asset-select').forEach((button, index) => {
            button.addEventListener('click', () => {
              // Only set selected feature and open panel after user selects from popup
              const selectedFeature = uniqueMarkers[index].feature;
              setSelectedFeature(selectedFeature);

              let assetId;
              if (selectedFeature.properties.type === 'Transformer') {
                assetId = selectedFeature.properties.name;
                const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
                setPanelData({
                  title: selectedFeature.properties.type,
                  data: {
                    ...selectedFeature.properties,
                    timestamp: detailTimestamp,
                    assetDetail: assetDetail
                  }
                });
                setIsPanelOpen(true);
              } else {
                if (selectedFeature.properties.type === 'Load') {
                  assetId = selectedFeature.properties.name.split('_')[1];
                } else {
                  assetId = selectedFeature.properties.name;
                }
                const assetDetail = assetDetails?.find(asset => asset.bus_name.startsWith(assetId));
                setPanelData({
                  title: selectedFeature.properties.type,
                  data: {
                    ...selectedFeature.properties,
                    timestamp: detailTimestamp,
                    assetDetail: assetDetail
                  }
                });
                setIsPanelOpen(true);
              }
              mapRef.current.closePopup();
            });
          });

        } else if (overlappingMarkers.length === 1) {
          // Single marker - proceed with normal selection
          setSelectedFeature(feature);

          let assetId;
          if (feature.properties.type === 'Transformer') {
            assetId = feature.properties.name;
            const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
            setPanelData({
              title: feature.properties.type,
              data: {
                ...feature.properties,
                timestamp: detailTimestamp,
                assetDetail: assetDetail
              }
            });
          } else {
            if (feature.properties.type === 'Load') {
              assetId = feature.properties.name.split('_')[1];
            } else {
              assetId = feature.properties.name;
            }
            const assetDetail = assetDetails?.find(asset => asset.bus_name.startsWith(assetId));
            setPanelData({
              title: feature.properties.type,
              data: {
                ...feature.properties,
                timestamp: detailTimestamp,
                assetDetail: assetDetail
              }
            });
          }
          setIsPanelOpen(true);
        }
      });

      return marker;
    }
  };

  useEffect(() => {
    // Only proceed if we have all the necessary data and map is initialized
    if (prePostMode === 'post' &&
      analysisData?.node_location &&
      mapRef.current &&
      filteredData.length > 0 &&
      !isLoading) {

      console.log("Attempting to focus on battery location:", analysisData.node_location);

      // Add a small delay to ensure the map is fully initialized
      setTimeout(() => {
        try {
          // Find the battery asset in the data
          const batteryLocation = analysisData.node_location;
          let batteryCoords = null;

          // Search through all features to find the battery location
          filteredData.forEach(data => {
            if (data.type === 'FeatureCollection') {
              data.features.forEach(feature => {
                // Check if this is a Load matching our battery location
                if (feature.properties.type === 'Load') {
                  const loadId = feature.properties.name.split('_')[1];
                  if (loadId === batteryLocation.toLowerCase() || feature.properties.name === batteryLocation.toLowerCase()) {
                    if (feature.geometry.type === 'Point') {
                      batteryCoords = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
                      console.log("Found battery coordinates:", batteryCoords);
                    }
                  }
                }
                // Check if it's another type of asset matching our battery location
                else if (feature.properties.name === batteryLocation.toLowerCase()) {
                  if (feature.geometry.type === 'Point') {
                    batteryCoords = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
                    console.log("Found battery coordinates:", batteryCoords);
                  }
                }
              });
            }
          });

          // If we found the battery location, safely zoom to it
          if (batteryCoords && mapRef.current) {
            console.log("Zooming to battery location");
            // Get current zoom level and set target zoom (don't zoom out if already zoomed in)
            const currentZoom = mapRef.current.getZoom();
            const targetZoom = Math.max(currentZoom, 20);

            // Safely set view
            mapRef.current.setView(batteryCoords, targetZoom, {
              animate: true,
              duration: 1
            });

            // Create a simple notification without relying on DOM manipulation
            // Use React state for notification instead of direct DOM manipulation
            setNotification({
              show: true,
              message: `Battery solution at ${batteryLocation}`
            });

            // Hide notification after 5 seconds
            setTimeout(() => {
              setNotification({
                show: false,
                message: ''
              });
            }, 5000);
          } else {
            console.log("Could not find battery coordinates or map reference is invalid");
          }
        } catch (error) {
          console.error("Error focusing on battery location:", error);
        }
      }, 500); // Short delay to ensure map is ready
    }
  }, [prePostMode, analysisData, filteredData, isLoading, darkMode]);

  const getLineStyle = (feature) => {
    // Only show violations in pre mode
    const isViolated = (prePostMode === 'pre' || prePostMode === undefined) &&
      violatedLines.includes(feature.properties.name);

    const isSelected = selectedFeature && selectedFeature.properties.name === feature.properties.name;

    // Base color based on violation status (only in pre mode)
    const baseColor = isViolated ? '#e53e3e' : '#2f855a';

    // If selected, modify the style while preserving the violation color
    if (isSelected) {
      return {
        color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'), // Brighter red if violated, else selection color
        weight: 5,
        opacity: 0.9,
        fillOpacity: 0.7
      };
    }

    // Default style
    return {
      color: baseColor,
      fillColor: baseColor,
      weight: 3,
      fillOpacity: 0.5
    };
  };

  const renderGeoJsonLayer = (data, index) => {
    if (data.type === 'FeatureCollection') {
      return (
        <React.Fragment key={`collection-${index}`}>
          <GeoJSON
            data={data}
            onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
            style={getLineStyle}
          />
          {data.features.map((feature, featureIndex) => (
            <React.Fragment key={`halo-${index}-${featureIndex}`}>
              {createHaloOverlay(feature)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    } else if (data.type === 'Feature') {
      if (data.geometry.type === 'Point') {
        let iconUrl = '';
        if (data.properties.type === 'Transformer') {
          iconUrl = customMarkerIcons.transformer.options.iconUrl;
        } else if (data.properties.type === 'Node') {
          iconUrl = customMarkerIcons.node.options.iconUrl;
        } else if (data.properties.type === 'Load') {
          iconUrl = customMarkerIcons.load.options.iconUrl;
        } else if (data.properties.type === 'Switch') {
          iconUrl = customMarkerIcons.switch.options.iconUrl;
        } else {
          iconUrl = customMarkerIcons.default.options.iconUrl;
        }
        return (
          <React.Fragment key={`feature-${data.properties.name}-${index}`}>
            {createHaloOverlay(data)}
            <Marker
              position={[data.geometry.coordinates[1], data.geometry.coordinates[0]]}
              icon={L.icon({
                iconUrl: iconUrl,
                iconSize: [24, 24],
                iconAnchor: [12, 12],
              })}
              eventHandlers={{
                click: (e) => {
                  L.DomEvent.stopPropagation(e);
                  setSelectedFeature(data);
                  setPanelData({
                    title: data.properties.type,
                    data: data.properties
                  });
                  setIsPanelOpen(true);
                }
              }}
            />
          </React.Fragment>
        );
      }
    }
    return null;
  };

  const handleSearchSelect = (suggestion) => {
    const { feature, center } = suggestion;

    // Set selected feature
    setSelectedFeature(feature);

    // Zoom to the selected feature
    if (mapRef.current) {
      const map = mapRef.current;

      // If we're in cluster view and zoom level is below threshold, zoom in
      if (currentZoom < ZOOM_THRESHOLD) {
        // For lines, use the center point calculated in MapSearch
        map.setView([center[1], center[0]], ZOOM_THRESHOLD);
      } else {
        map.setView([center[1], center[0]], currentZoom);
      }
    }

    // Setup panel data based on feature type
    if (feature.geometry.type === 'LineString') {
      const assetDetail = lineDetails?.find(asset => asset.line_name.startsWith(feature.properties.name));
      setPanelData({
        title: feature.properties.type,
        data: {
          ...feature.properties,
          timestamp: detailTimestamp,
          assetDetail: assetDetail
        }
      });
    } else {
      let assetId;
      if (feature.properties.type === 'Transformer') {
        assetId = feature.properties.name;
        const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
        setPanelData({
          title: feature.properties.type,
          data: {
            ...feature.properties,
            timestamp: detailTimestamp,
            assetDetail: assetDetail
          }
        });
      } else {
        if (feature.properties.type === 'Load') {
          assetId = feature.properties.name.split('_')[1];
        } else {
          assetId = feature.properties.name;
        }
        const assetDetail = assetDetails?.find(asset => asset.bus_name.startsWith(assetId));
        setPanelData({
          title: feature.properties.type,
          data: {
            ...feature.properties,
            timestamp: detailTimestamp,
            assetDetail: assetDetail
          }
        });
      }
    }

    setIsPanelOpen(true);
  };

  const handleNetworkSearch = (searchQuery) => {
    if (!searchQuery) {
      setFilteredData(filteredData);
      return;
    }

    const filtered = filteredData.filter(data => {
      if (data.type === 'FeatureCollection') {
        return data.features.some(feature =>
          feature.properties.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          feature.properties.type?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      return false;
    });

    setFilteredData(filtered);
  };

  return (
    <>
      {notification.show && (
        <div className={`fixed top-20 left-1/2 transform -translate-x-1/2 z-[2000] px-4 py-2 rounded ${darkMode ? 'bg-green-800 text-white' : 'bg-green-100 text-green-800'
          } shadow-lg`}>
          <div className="flex items-center space-x-2">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3334 4.66669V3.33335C11.3334 2.96669 11.2001 2.65558 10.9334 2.40002C10.6667 2.14447 10.35 2.01669 9.98337 2.01669H6.01671C5.65004 2.01669 5.33893 2.14447 5.08337 2.40002C4.82782 2.65558 4.70004 2.96669 4.70004 3.33335V4.66669H2.66671C2.30004 4.66669 1.98615 4.79724 1.72504 5.05835C1.46393 5.31946 1.33337 5.63335 1.33337 6.00002V12.6667C1.33337 13.0334 1.46393 13.3473 1.72504 13.6084C1.98615 13.8695 2.30004 14 2.66671 14H13.3334C13.7 14 14.0139 13.8695 14.275 13.6084C14.5362 13.3473 14.6667 13.0334 14.6667 12.6667V6.00002C14.6667 5.63335 14.5362 5.31946 14.275 5.05835C14.0139 4.79724 13.7 4.66669 13.3334 4.66669H11.3334Z" fill="currentColor" />
            </svg>
            <span>{notification.message}</span>
          </div>
        </div>
      )}
      <NetworkViewerTabs
        darkMode={darkMode}
        selectedScenario={selectedScenario}
        onScenarioChange={onScenarioChange}
        analysisData={analysisData}
        isLoading={isLoading}
        selectedNetworks={selectedNetworks}
        onNetworkFilterChange={handleNetworkFilterChange}
        onDashboardOpen={() => setIsDashboardOpen(true)}
        onQueueOpen={() => setIsQueueModalOpen(true)}
        onPreviewOpen={() => setIsAssistantPreviewOpen(true)}
        geoJsonData={filteredData}
        onSelect={handleSearchSelect}
        selectedTimepoint={selectedTimepoint}
        onTimeSelect={handleTimeSelect}
        onSearch={handleNetworkSearch}
        onTabChange={handleTabChange} // Pass tab change handler
        activeTab={activeTab} // Pass active tab state
        selectedMode={selectedMode}
        defaultTimestamp={defaultTimestamp}
        worstPerformingHour={worstPerformingHour}
        onClearTimestamp={handleClearTimestamp}
        prePostMode={prePostMode}
        onPrePostToggle={onPrePostToggle}
        loadingProgress={loadingProgress}
        error={error}
        onRetry={handleRetry}
        setWorstPerformingHour={setWorstPerformingHour}
      >
        {!Object.values(selectedNetworks).some(Boolean) || !selectedScenario ? (
          <NetworkWelcome
            selectedMode={selectedMode}
            darkMode={darkMode}
          />
        ) : (
          <div className={`relative h-full ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} overflow-x-hidden pt-24`}>
            {isLoading || error ? (
              <LoadingOverlay
                darkMode={darkMode}
                progress={loadingProgress}
                error={error}
                onRetry={handleRetry}
              />
            ) : (
              <>
                {/* Map container - adjusted left position */}
                <div className="absolute left-0 right-0 top-0 bottom-0">
                  {activeTab === 'network' && (
                    <MapContainer
                      ref={mapRef}
                      center={mapCenter}
                      zoom={mapZoom}
                      className="w-full h-full"
                      zoomControl={false}
                      maxZoom={30}
                      key={`map-${mapKey}-${selectedScenario}-${JSON.stringify(selectedNetworks)}-${prePostMode}-${selectedMode}`}
                    >
                      <MapEvents />
                      <LayersControl position="bottomright">
                        <LayersControl.BaseLayer checked={darkMode} name="Dark (Offline Available)">
                          {/* Only render the active map style with offline capability */}
                          {darkMode && (
                            <OfflineTileLayer 
                              url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                              maxZoom={30}
                              maxNativeZoom={20}
                              darkMode={true}
                            />
                          )}
                          {!darkMode && (
                            <TileLayer
                              url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                              maxZoom={30}
                              maxNativeZoom={20}
                            />
                          )}
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked={!darkMode} name="Light (Offline Available)">
                          {/* Only render the active map style with offline capability */}
                          {!darkMode && (
                            <OfflineTileLayer 
                              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                              maxZoom={30}
                              maxNativeZoom={20}
                              darkMode={false}
                            />
                          )}
                          {darkMode && (
                            <TileLayer
                              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                              maxZoom={30}
                              maxNativeZoom={20}
                            />
                          )}
                        </LayersControl.BaseLayer>
                      </LayersControl>
                      <ZoomControl position="bottomleft" />

                      <MarkerClusterGroup
                        chunkedLoading
                        spiderfyOnMaxZoom={false}
                        disableClusteringAtZoom={ZOOM_THRESHOLD}
                        iconCreateFunction={createClusterCustomIcon}
                        key={`cluster-${mapKey}`}
                      >
                        {filteredData.map((data, index) =>
                          currentZoom < ZOOM_THRESHOLD || !visibleData.includes(data)
                            ? renderGeoJsonLayer(data, index)
                            : null
                        )}
                      </MarkerClusterGroup>
                      {currentZoom >= ZOOM_THRESHOLD && visibleData.map((data, index) => renderGeoJsonLayer(data, index))}
                    </MapContainer>
                  )}
                  {/* Summary panel - only in GRID ORCHESTRATION mode */}
                  {/* {selectedMode === 'GRID ORCHESTRATION' && showSummary && (
                    <div className="absolute right-0 top-0 bottom-0 w-[300px] z-[1000] shadow-lg">
                      <StateSummary
                        darkMode={darkMode}
                        onMinimize={() => setShowSummary(false)}
                      />
                    </div>
                  )} */}
                  {selectedMode === 'GRID ORCHESTRATION' && !showSummary && (
                    <button
                      onClick={() => setShowSummary(true)}
                      className="absolute top-4 right-4 px-3 py-2 bg-white rounded-lg shadow-lg z-[1000] flex items-center hover:bg-white"
                    >
                      <span className="mr-2 font-medium text-gray-800">Summary</span>
                      <ChevronDown className="w-4 h-4 text-gray-700" />
                    </button>
                  )}
                </div>

                {/* Rest of the components remain the same */}
                <DashboardModal
                  isOpen={isDashboardOpen}
                  onClose={() => setIsDashboardOpen(false)}
                  darkMode={darkMode}
                  selectedScenario={selectedScenario}
                />

                <EnergizationQueueModal
                  isOpen={isQueueModalOpen}
                  onClose={() => setIsQueueModalOpen(false)}
                  darkMode={darkMode}
                />

                <AssistantPreviewModal
                  isOpen={isAssistantPreviewOpen}
                  onClose={() => setIsAssistantPreviewOpen(false)}
                  darkMode={darkMode}
                />

                {/* {!isCluster && (
            <div className="absolute left-80 right-0 bottom-4 flex justify-center items-center z-[1000]">
              <button
                className={`${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white px-4 py-2 rounded-md shadow-md transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                onClick={handleBackToCluster}
              >
                Back to Cluster View
              </button>
            </div>
          )} */}

                {/* Right Panel - no changes needed */}
                <RightPanel
                  isOpen={isPanelOpen}
                  onClose={closePanel}
                  panelData={panelData}
                  darkMode={darkMode}
                  selectedScenario={selectedScenario}
                  prePostMode={prePostMode}
                  selectedMode={selectedMode}
                />
              </>
            )}
          </div>
        )}
      </NetworkViewerTabs>
    </>
  );
};

export default ModifiedGeoJsonViewer;