import { useEffect, useRef, useState } from 'react';
import { format, parseISO, eachHourOfInterval } from 'date-fns';
import { ChevronDown } from 'lucide-react';

const HourPicker = ({ startTime, endTime, onHourSelect, isOpen, onClose, darkMode }) => {
    const [hours, setHours] = useState([]);
    const pickerRef = useRef(null);
  
    useEffect(() => {
      if (startTime && endTime) {
        const start = parseISO(startTime);
        const end = parseISO(endTime);
        const hoursList = eachHourOfInterval({ start, end }).map(date => format(date, 'HH:00'));
        setHours(hoursList);
      }
    }, [startTime, endTime]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen, onClose]);
  
    if (!isOpen) return null;
  
    return (
      <div 
        ref={pickerRef}
        className={`absolute right-0 mt-1 ${
          darkMode ? 'bg-gray-800' : 'bg-white'
        } rounded-lg shadow-lg border ${
          darkMode ? 'border-gray-700' : 'border-gray-200'
        } max-h-48 overflow-y-auto w-16 z-50`}
      >
        {hours.map((hour) => (
          <button
            key={hour}
            onClick={() => onHourSelect(hour)}
            className={`w-full text-center py-1 text-sm ${
              darkMode 
                ? 'hover:bg-gray-700 text-gray-200' 
                : 'hover:bg-gray-100 text-gray-700'
            }`}
          >
            {hour}
          </button>
        ))}
      </div>
    );
  };

  const TooltipPosition = ({ 
    containerRef, 
    timelineRef, 
    bucketWidth, 
    index, 
    time, 
    endTime, 
    darkMode,
    onTimeSelect 
  }) => {
    const [isHourPickerOpen, setIsHourPickerOpen] = useState(false);
    const tooltipRef = useRef(null);
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const selectedDate = parseISO(time);
  
    useEffect(() => {
      if (tooltipRef.current && containerRef.current) {
        const timelineRect = containerRef.current.querySelector('.timeline-chart').getBoundingClientRect();
        const bucketLeft = (index * bucketWidth) - timelineRef.current.scrollLeft;
        const bucketCenter = bucketLeft + (bucketWidth / 2);
        const tooltipWidth = tooltipRef.current.offsetWidth;
        let tooltipLeft = timelineRect.left + bucketCenter - (tooltipWidth / 2);
  
        const viewportWidth = window.innerWidth;
        if (tooltipLeft < 0) {
          tooltipLeft = 0;
        } else if (tooltipLeft + tooltipWidth > viewportWidth) {
          tooltipLeft = viewportWidth - tooltipWidth;
        }
  
        const tooltipTop = timelineRect.bottom + 8;
  
        setPosition({
          left: tooltipLeft,
          top: tooltipTop,
        });
      }
    }, [containerRef, bucketWidth, index, time, endTime, timelineRef]);
  
    const handleHourSelect = (hour) => {
        const [selectedHour] = hour.split(':');
        const newDate = new Date(selectedDate);
        newDate.setHours(parseInt(selectedHour, 10), 0, 0, 0);
        const formattedDate = format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        onTimeSelect(formattedDate);
        setIsHourPickerOpen(false);
      };
  
    return (
      <div
        ref={tooltipRef}
        className={`fixed ${
          darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'
        } rounded text-xs whitespace-nowrap z-[1000]`}
        style={{
          left: position.left,
          top: position.top
        }}
      >
        <button
          onClick={() => setIsHourPickerOpen(!isHourPickerOpen)}
          className="px-2 py-1 w-full text-left flex items-center justify-between gap-2"
        >
          <span>
          {format(parseISO(time), "dd MMM yyyy | HH:mm")} 
          - {format(parseISO(endTime), "HH:mm")}
        </span>
          <ChevronDown size={14} className={`transform transition-transform ${isHourPickerOpen ? 'rotate-180' : ''}`} />
        </button>
  
        <HourPicker
          startTime={time}
          endTime={endTime}
          onHourSelect={handleHourSelect}
          isOpen={isHourPickerOpen}
          onClose={() => setIsHourPickerOpen(false)}
          darkMode={darkMode}
        />
      </div>
    );
  };

  const HoverTooltip = ({ time, containerRef, timelineRef, bucketWidth, index, darkMode }) => {
    const tooltipRef = useRef(null);
    const [position, setPosition] = useState({ left: 0, top: 0 });
  
    useEffect(() => {
      const updatePosition = () => {
        if (!tooltipRef.current || !containerRef.current || !timelineRef.current) return;
  
        const timelineRect = timelineRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        
        const scrollOffset = timelineRef.current.scrollLeft;
        const bucketLeft = (index * bucketWidth) - scrollOffset;
        const bucketCenter = bucketLeft + (bucketWidth / 2);
        
        let tooltipLeft = timelineRect.left + bucketCenter;
        tooltipLeft -= tooltipRect.width / 2;
        
        // Keep tooltip within viewport bounds
        const minLeft = 0;
        const maxLeft = window.innerWidth - tooltipRect.width;
        tooltipLeft = Math.max(minLeft, Math.min(tooltipLeft, maxLeft));
        
        // Position above the bucket
        const tooltipTop = timelineRect.top - tooltipRect.height - 8;
  
        setPosition({
          left: tooltipLeft,
          top: tooltipTop
        });
      };
  
      updatePosition();
      
      const handleResize = () => {
        requestAnimationFrame(updatePosition);
      };
  
      const handleScroll = () => {
        requestAnimationFrame(updatePosition);
      };
  
      window.addEventListener('resize', handleResize);
      timelineRef.current?.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        timelineRef.current?.removeEventListener('scroll', handleScroll);
      };
    }, [containerRef, timelineRef, bucketWidth, index]);
  
    return (
      <div
        ref={tooltipRef}
        className={`fixed ${
          darkMode ? 'bg-gray-800' : 'bg-gray-700'
        } text-white px-2 py-1 rounded text-xs whitespace-nowrap z-[999] shadow-lg`}
        style={{
          left: position.left,
          top: position.top
        }}
      >
        {format(parseISO(time), "dd MMM yyyy")}
      </div>
    );
  };

const TimelineBucket = ({ bucket, idx, isSelected, bucketWidth, handleClick, containerRef, timelineRef, darkMode, data, onTimeSelect }) => {
    const [showHoverTooltip, setShowHoverTooltip] = useState(false);

    return (
        <div
            key={bucket.time}
            className="flex-shrink-0 relative group cursor-pointer"
            style={{ width: `${bucketWidth}px` }}
            onClick={handleClick}
            onMouseEnter={() => setShowHoverTooltip(true)}
            onMouseLeave={() => setShowHoverTooltip(false)}
        >
            <div className="h-8 flex items-center justify-center">
                <div
                    className={`transition-all duration-200 rounded-sm ${bucket.violations ? 'bg-red-400' : 'bg-gray-200'
                        }`}
                    style={{
                        width: `${Math.max(bucketWidth * 0.8, 2)}px`,
                        height: isSelected ? '32px' : `${Math.max(bucket.total * 2, 4)}px`
                    }}
                />
            </div>

            {showHoverTooltip && !isSelected && (
                <HoverTooltip
                    time={bucket.time}
                    containerRef={containerRef}
                    timelineRef={timelineRef}
                    bucketWidth={bucketWidth}
                    index={idx}
                />
            )}

            {/* Keep existing selection tooltip logic */}
            {isSelected && (
                <>
                    <TooltipPosition
                        containerRef={containerRef}
                        timelineRef={timelineRef}
                        bucketWidth={bucketWidth}
                        index={idx}
                        time={data[bucket.originalIndices[0]].time}
                        endTime={data[bucket.originalIndices[bucket.originalIndices.length - 1]].time}
                        darkMode={darkMode}
                        onTimeSelect={onTimeSelect}
                    />
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="w-3 h-3 bg-blue-500 rounded-full" />
                    </div>
                </>
            )}
        </div>
    );
};
  

export { TimelineBucket, HoverTooltip };