import { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

export default function Legend({ darkMode }) {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
        <div className={`relative flex-shrink-0 ${
      darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
    } rounded-lg shadow-sm`}>
            <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center space-x-2 px-3 py-1.5 font-medium hover:bg-transparent"
            >
            <span>Legend</span>
            {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </button>
        
            {isOpen && (
            <div className="absolute mb-2 right-0 w-48 rounded-lg shadow-lg border border-gray-200 p-2">
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#3661EB"/>
                        </svg>
                        <span>Node</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.07322 1.42678L1.05163 6.44837C0.87965 6.62035 0.588267 6.4704 0.62825 6.2305L1.46518 1.2089C1.48527 1.08835 1.58957 1 1.71178 1H5.89645C6.11917 1 6.23071 1.26929 6.07322 1.42678Z" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
                            <path d="M1.92678 1.42678L6.94837 6.44837C7.12035 6.62035 7.41173 6.4704 7.37175 6.2305L6.53482 1.2089C6.51473 1.08835 6.41043 1 6.28822 1H2.10355C1.88083 1 1.76929 1.26929 1.92678 1.42678Z" stroke="#3661EB" stroke-width="0.75" stroke-linecap="round"/>
                        </svg>
                        <span>Transformer</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.42571 3.30894C3.23043 3.62595 2.76958 3.62595 2.57429 3.30894L1.00546 0.762246C0.800248 0.429118 1.03991 -3.86776e-07 1.43117 -3.5257e-07L4.56883 -7.8267e-08C4.9601 -4.40615e-08 5.19976 0.429118 4.99454 0.762246L3.42571 3.30894Z" fill="#3661EB"/>
                        </svg>
                        <span>Load</span>
                    </div>
                    {/* <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4" y="11" width="16" height="2" rx="1" fill="#3661EB"/>
                            <circle cx="8" cy="12" r="3" fill="#3661EB" stroke="white" stroke-width="1"/>
                            <circle cx="16" cy="12" r="3" fill="#3661EB" stroke="white" stroke-width="1"/>
                        </svg>
                        <span>Switch</span>
                    </div> */}
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#DA3552"/>
                        </svg>
                        <span>Violated Node</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.42571 3.30894C3.23043 3.62595 2.76958 3.62595 2.57429 3.30894L1.00546 0.762246C0.800248 0.429118 1.03991 -3.86776e-07 1.43117 -3.5257e-07L4.56883 -7.8267e-08C4.9601 -4.40615e-08 5.19976 0.429118 4.99454 0.762246L3.42571 3.30894Z" fill="#DA3552"/>
                        </svg>
                        <span>Violated Load</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.07322 1.42678L1.05163 6.44837C0.87965 6.62035 0.588267 6.4704 0.62825 6.2305L1.46518 1.2089C1.48527 1.08835 1.58957 1 1.71178 1H5.89645C6.11917 1 6.23071 1.26929 6.07322 1.42678Z" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
                            <path d="M1.92678 1.42678L6.94837 6.44837C7.12035 6.62035 7.41173 6.4704 7.37175 6.2305L6.53482 1.2089C6.51473 1.08835 6.41043 1 6.28822 1H2.10355C1.88083 1 1.76929 1.26929 1.92678 1.42678Z" stroke="#DA3552" stroke-width="0.75" stroke-linecap="round"/>
                        </svg>
                        <span>Violated Transformer</span>
                    </div>
                    {/* <div className="flex items-center space-x-2">
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4" y="11" width="16" height="2" rx="1" fill="#DA3552"/>
                            <circle cx="8" cy="12" r="3" fill="#DA3552" stroke="white" stroke-width="1"/>
                            <circle cx="16" cy="12" r="3" fill="#DA3552" stroke="white" stroke-width="1"/>
                        </svg>
                        <span>Violated Switch</span>
                    </div> */}
                </div>
            </div>
            )}
        </div>
    );
  };