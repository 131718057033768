import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';

const NetworkSelection = ({ networks, selectedNetworks, onNetworkChange, scenarios, selectedScenario, onScenarioChange, darkMode, selectedMode }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(!Object.values(selectedNetworks).some(Boolean));

  const networkOptions = [
    { id: 'small', name: 'P1U', scenarios: ['base', 'er4109', 'er4109_1', 'er4139', 'er7633'] },
    { id: 'large', name: 'P9U', scenarios: ['base', 'er1256'] },
    // { id: 'joy', name: 'JOY Network', scenarios: ['base'] },
    // { id: 'cabana', name: 'CABANA Network', scenarios: ['base'] },
    // { id: 'danish', name: 'DANISH Network', scenarios: ['base'] }
  ];

  const disabledNetworks = [
    { id: 'medium', name: 'P5U', scenarios: ['base'] },
    { id: 'xl', name: 'P3U', scenarios: ['base'] },
    { id: 'xs', name: 'P7U', scenarios: ['base'] },
    { id: '2xl', name: 'P8U', scenarios: ['base'] },
    { id: '2xs', name: 'P2U', scenarios: ['base'] },
  ];

  // Update showSearch when networks changes
  useEffect(() => {
    setShowSearch(!Object.values(selectedNetworks).some(Boolean));
  }, [selectedNetworks]);

  const filteredNetworks = searchQuery
    ? networkOptions.filter(network =>
      network.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  const selectedNetwork = networkOptions.find(network => selectedNetworks[network.id]);

  // Get available scenarios for selected network
  let availableScenarios = selectedNetwork
    ? scenarios.filter(scenario => selectedNetwork.scenarios.includes(scenario.value))
    : [];

  if (selectedMode === 'GRID ORCHESTRATION') {
    availableScenarios = [{ value: 'base', label: 'Base Scenario' }];
  }

  const handleNetworkSelect = (networkId) => {
    const newSelection = {
      large: false,
      small: false,
      joy: false,
      cabana: false,
      danish: false,
      [networkId]: true
    };
    onNetworkChange(newSelection);
    onScenarioChange('');  // Clear scenario when network changes
    setSearchQuery('');
  };

  return (
    <div className="space-y-4">
      {showSearch ? (
        <div className="px-4 space-y-2">
          <div style={{ color: '#6C6C6C', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>Step 1</div>
          <div style={{ width: 200, height: 28, paddingTop: 6, paddingBottom: 6, borderRadius: 6, alignItems: 'center', display: 'inline-flex' }}>
            <p className='mb-4 text-sm' style={{ color: 'black', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>Substation: Pacifica</p> <br />
          </div>
          <div style={{ width: 200, height: 28, paddingTop: 6, paddingBottom: 6, borderRadius: 6, alignItems: 'center', display: 'inline-flex' }}>
            <p className='mb-4 text-sm' style={{ color: '#3661EB', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>Select Network</p>
          </div>

          <div className="space-y-2">
            {networkOptions.map((network) => (
              <button
                key={network.id}
                onClick={() => handleNetworkSelect(network.id)}
                className={`w-full p-3 text-left rounded-lg transition-colors ${darkMode
                  ? selectedNetworks[network.id]
                    ? 'bg-blue-900 text-white'
                    : 'text-gray-200 hover:bg-gray-700'
                  : selectedNetworks[network.id]
                    ? 'bg-blue-50 text-blue-900'
                    : 'text-gray-900 hover:bg-gray-100'
                  }`}
              >
                {network.name} Network
              </button>
            ))}
            {disabledNetworks.map((network) => (
              <button
                key={network.id}
                className="w-full p-3 text-left rounded-lg cursor-not-allowed
                text-gray-900 hover:bg-gray-100"
              >
                {network.name} Network
              </button>
            ))}
          </div>
          {/* <div className="relative">
            
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search networks..."
              className={`w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500
                text-black`}
            />
          </div>
          
          {searchQuery && (
            <div className="mt-2 space-y-2">
              {filteredNetworks.map(network => (
                <button
                  key={network.id}
                  onClick={() => handleNetworkSelect(network.id)}
                  className="w-full p-3 text-left hover:bg-gray-200 rounded-lg"
                >
                  {network.name}
                </button>
              ))}
            </div>
          )} */}
        </div>
      ) : !selectedScenario && (
        <div className="px-4 space-y-2">
          <p className="text-sm mb-4" style={{ color: '#3661EB', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>Select Scenario</p>
          {availableScenarios.map(scenario => (
            <button
              key={scenario.value}
              onClick={() => onScenarioChange(scenario.value)}
              className="w-full p-3 text-left hover:bg-gray-200 rounded-lg"
            >
              {scenario.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default NetworkSelection;