import React from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const PowerFlowModal = ({ isOpen, onClose, darkMode }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed -inset-4 bg-black bg-opacity-50 flex items-center justify-center z-[2000]"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
            className={`w-full max-w-lg rounded-xl shadow-2xl ${darkMode ? 'bg-gray-800' : 'bg-white'
              } overflow-hidden mx-4`}
            onClick={e => e.stopPropagation()}
          >
            <div className={`px-6 py-4 flex justify-between items-center border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'
              }`}>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'
                }`}>
                Performance Highlights of AI-based Dynamic Planning
              </h3>
              <button
                onClick={onClose}
                className={`p-2 rounded-full transition-colors ${darkMode
                    ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200'
                    : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                  }`}
              >
                <X size={20} />
              </button>
            </div>

            <div className="p-6">
              <div className="space-y-6">
                {/* Status Overview Card */}
                <div className={`rounded-xl p-5 ${darkMode ? 'bg-gray-700' : 'bg-blue-50'
                  }`}>
                  <div className="flex justify-between items-center mb-4">
                    <h4 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                      8760 Power Flow Performance
                    </h4>
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <div>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                        AI Accuracy
                      </p>
                      <p className={`text-2xl font-bold text-green-500`}>
                        99.7%
                      </p>
                      <p className={`text-xs mt-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                        for 3-phase AC unbalanced power flow
                      </p>
                    </div>
                    <div>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                        Processing Time
                      </p>
                      <p className={`text-2xl font-bold ${darkMode ? 'text-blue-400' : 'text-blue-600'
                        }`}>
                        63 sec
                      </p>
                      <p className={`text-xs mt-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                        for a large circuit
                      </p>
                    </div>
                    <div>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                        Training Time
                      </p>
                      <p className={`text-2xl font-bold ${darkMode ? 'text-yellow-400' : 'text-yellow-600'
                        }`}>
                        4.25 min
                      </p>
                      <p className={`text-xs mt-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                        for AI digital twin
                      </p>
                    </div>
                  </div>
                </div>

                {/* Performance Details Card */}
                <div className={`rounded-xl p-5 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'
                  }`}>
                  <div className="space-y-6">
                    <div>
                      <div className="flex justify-center items-center">
                        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'
                          }`}>
                          7.2 msec per power flow run on average
                        </p>
                      </div>
                    </div>

                    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-600' : 'bg-gray-100'
                      }`}>
                      <p className={`text-sm leading-relaxed ${darkMode ? 'text-gray-300' : 'text-gray-700'
                        }`}>
                        Horizontal scalability to run thousands of simultaneous time series power flows across entire utility service territories and at multiple forecast sensitivities
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PowerFlowModal;