import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './dashboard';
import './index.css';
import ViolationSummary from './violationSummary';
import ModifiedGeoJsonViewer from './modifiedGeoJsonViewer';
import ModifiedHeader from './util/Header/modifiedHeader';
import { useNotifications } from './hooks/useNotifications';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    return savedDarkMode !== null ? JSON.parse(savedDarkMode) : false;
  });
  const [selectedScenario, setSelectedScenario] = useState('');
  const [toastNotification, setToastNotification] = useState(null);
  const [selectedTimepoint, setSelectedTimepoint] = useState(null);

  // Separate worst performing hour timestamps for each mode
  const [dynamicPlanningWorstHour, setDynamicPlanningWorstHour] = useState(null);
  const [gridOrchestrationWorstHour, setGridOrchestrationWorstHour] = useState(null);

  const [selectedNetworks, setSelectedNetworks] = useState({
    large: false,
    small: false,
    joy: false,
    cabana: false,
    danish: false,
  });

  // Add new state for tracking the selected mode
  const [selectedMode, setSelectedMode] = useState('DYNAMIC PLANNING');
  // Track previous mode to detect changes
  const [prevMode, setPrevMode] = useState('DYNAMIC PLANNING');

  // Add state for pre/post toggle
  const [prePostMode, setPrePostMode] = useState('pre');

  const isValidTimestampForMode = (timestamp, mode) => {
    if (!timestamp) return false;

    try {
      // Grid Orchestration should only use 2025 timestamps
      if (mode === 'GRID ORCHESTRATION') {
        return timestamp.startsWith('2025');
      }
      // Dynamic Planning should only use 2024 timestamps
      else {
        return timestamp.startsWith('2024');
      }
    } catch (error) {
      console.error(`Error validating timestamp: ${error}`);
      return false;
    }
  };

  // Get the appropriate worst hour based on the current mode
  const getCurrentWorstHour = () => {
    console.log(`Getting worst hour for mode: ${selectedMode}`);
    console.log(`Current Grid worst hour: ${gridOrchestrationWorstHour}`);
    console.log(`Current Dynamic worst hour: ${dynamicPlanningWorstHour}`);

    if (selectedMode === 'GRID ORCHESTRATION') {
      console.log(`Returning Grid Orchestration worst hour: ${gridOrchestrationWorstHour}`);
      return gridOrchestrationWorstHour;
    } else {
      console.log(`Returning Dynamic Planning worst hour: ${dynamicPlanningWorstHour}`);
      return dynamicPlanningWorstHour;
    }
  };

  const handleTimeSelect = (timepoint) => {
    console.log(`App.js handleTimeSelect: ${timepoint} for ${selectedMode}`);

    setSelectedTimepoint(timepoint);

    // If this is a worst performing hour update from child component,
    // store it in the appropriate state variable
    if (timepoint && timepoint.isWorstPerformingHour) {
      if (selectedMode === 'GRID ORCHESTRATION') {
        setGridOrchestrationWorstHour(timepoint);
      } else {
        setDynamicPlanningWorstHour(timepoint);
      }
    }
  };

  // Update the worst performing hour setter for children to use
  const setWorstPerformingHour = (timestamp, mode) => {
    console.log(`App.js setWorstPerformingHour: ${timestamp} for ${mode}`);

    if (mode === 'GRID ORCHESTRATION') {
      const oldHour = gridOrchestrationWorstHour;
      setGridOrchestrationWorstHour(timestamp);
      console.log(`Updated GRID ORCHESTRATION worst hour from ${oldHour} to ${timestamp}`);
    } else {
      const oldHour = dynamicPlanningWorstHour;
      setDynamicPlanningWorstHour(timestamp);
      console.log(`Updated DYNAMIC PLANNING worst hour from ${oldHour} to ${timestamp}`);
    }
  };

  // Handle mode switch - reset timepoint and ensure we use the correct worst hour
  const handleModeChange = (newMode) => {
    console.log(`App.js handleModeChange: ${selectedMode} -> ${newMode}`);

    // Store the current mode before updating
    const oldMode = selectedMode;

    // First update the mode indicator to trigger UI updates
    setSelectedMode(newMode);

    // Reset timepoint to null to prevent any previous timestamp being used
    setSelectedTimepoint(null);

    // Reset pre/post mode when switching to Grid Orchestration
    if (newMode === 'GRID ORCHESTRATION') {
      setPrePostMode('pre');
      setSelectedScenario('base'); // Reset scenario when switching to Grid Orchestration
      // CRITICAL: Validate the Grid Orchestration worst hour
      if (gridOrchestrationWorstHour && !isValidTimestampForMode(gridOrchestrationWorstHour, 'GRID ORCHESTRATION')) {
        console.log(`Resetting invalid Grid Orchestration worst hour: ${gridOrchestrationWorstHour}`);
        setGridOrchestrationWorstHour(null);
      }

      // Apply Grid Orchestration's worst hour if available and valid
      if (isValidTimestampForMode(gridOrchestrationWorstHour, 'GRID ORCHESTRATION')) {
        setTimeout(() => setSelectedTimepoint(gridOrchestrationWorstHour), 50);
      }
    } else {
      // Switching to Dynamic Planning

      // CRITICAL: Validate the Dynamic Planning worst hour
      if (dynamicPlanningWorstHour && !isValidTimestampForMode(dynamicPlanningWorstHour, 'DYNAMIC PLANNING')) {
        console.log(`Resetting invalid Dynamic Planning worst hour: ${dynamicPlanningWorstHour}`);
        setDynamicPlanningWorstHour(null);
      }

      // Apply Dynamic Planning's worst hour if available and valid
      if (isValidTimestampForMode(dynamicPlanningWorstHour, 'DYNAMIC PLANNING')) {
        setTimeout(() => setSelectedTimepoint(dynamicPlanningWorstHour), 50);
      }
    }
  };

  // Handle "Reset" button in Timeline, ensuring we use the correct worst hour
  const handleClearTimestamp = (mode) => {
    console.log(`App.js handleClearTimestamp for ${mode}`);

    if (mode === 'GRID ORCHESTRATION') {
      // Validate the worst hour
      if (gridOrchestrationWorstHour && isValidTimestampForMode(gridOrchestrationWorstHour, 'GRID ORCHESTRATION')) {
        console.log(`Using validated Grid Orchestration worst hour: ${gridOrchestrationWorstHour}`);
        setSelectedTimepoint(gridOrchestrationWorstHour);
      } else {
        // Invalid or missing worst hour
        console.log(`No valid Grid Orchestration worst hour available`);
        setSelectedTimepoint(null);
      }
    } else {
      // Dynamic Planning mode
      if (dynamicPlanningWorstHour && isValidTimestampForMode(dynamicPlanningWorstHour, 'DYNAMIC PLANNING')) {
        console.log(`Using validated Dynamic Planning worst hour: ${dynamicPlanningWorstHour}`);
        setSelectedTimepoint(dynamicPlanningWorstHour);
      } else {
        // Invalid or missing worst hour
        console.log(`No valid Dynamic Planning worst hour available`);
        setSelectedTimepoint(null);
      }
    }
  };

  useEffect(() => {
    // Add debugging at the top of this useEffect
    console.log(`App.js mode change effect triggered.`);
    console.log(`Previous mode: ${prevMode}, Current mode: ${selectedMode}`);
    console.log(`Dynamic Planning worst hour: ${dynamicPlanningWorstHour}`);
    console.log(`Grid Orchestration worst hour: ${gridOrchestrationWorstHour}`);

    // Rest of your existing code...
  }, [selectedMode, prevMode, dynamicPlanningWorstHour, gridOrchestrationWorstHour]);

  // Mock user object with email - replace with actual Microsoft OAuth email when available
  const user = {
    email: 'user@example.com' // Replace this with the actual Microsoft OAuth email
  };

  const { addNotification, notifications, unreadCount, markAllAsRead, clearNotifications } = useNotifications(user?.email);

  const handleScenarioChange = (newScenario) => {
    const scenarioMessages = {
      'base': 'Base Case',
      'er4109': 'Interconnection (50 kW): P1ULV4109',
      'er4109_1': 'Interconnection (25 kW): P1ULV4109',
      'er4139': 'Load Interconnection (2.5x): P1ULV4139',
      'er7633': 'Load Interconnection (2.5x): P1ULV7543',
      'er1256': 'Load Interconnection (3x): P9ULV71155',
    };

    const message = scenarioMessages[newScenario] || '';
    const notificationMessage = `Scenario changed to: ${message}`;

    setToastNotification(notificationMessage);
    setTimeout(() => setToastNotification(null), 2000);

    addNotification(notificationMessage);
    setSelectedScenario(newScenario);

    // Clear the selected timepoint when scenario changes
    setSelectedTimepoint(null);
    setPrePostMode('pre'); // Reset Pre/Post mode when scenario changes

    // Also reset the worst performing hour for the current mode
    if (selectedMode === 'GRID ORCHESTRATION') {
      setGridOrchestrationWorstHour(null);
    } else {
      setDynamicPlanningWorstHour(null);
    }
  };

  // Add network selection handler
  const handleNetworkSelection = (networks) => {
    setSelectedNetworks(networks);

    // Clear the selected timepoint when network selection changes
    setSelectedTimepoint(null);
    setPrePostMode('pre'); // Reset Pre/Post mode when network selection changes

    // Also reset the worst performing hour for the current mode
    if (selectedMode === 'GRID ORCHESTRATION') {
      setGridOrchestrationWorstHour(null);
    } else {
      setDynamicPlanningWorstHour(null);
    }
  };

  // Add pre/post toggle handler
  const handlePrePostToggle = (mode) => {
    console.log(`Toggling mode from ${prePostMode} to ${mode}`);
    setPrePostMode(mode);

    // Reset selected timepoint when switching modes
    if (prePostMode !== mode) {
      setSelectedTimepoint(null);

      // Also reset the worst performing hour for the current mode
      if (selectedMode === 'GRID ORCHESTRATION') {
        setGridOrchestrationWorstHour(null);
      } else {
        setDynamicPlanningWorstHour(null);
      }
    }
  };

  // Listen for global mode change events
  useEffect(() => {
    const handleGlobalModeChange = (event) => {
      console.log(`App.js received global mode change event: ${event.detail.previousMode} -> ${event.detail.currentMode}`);

      // Ensure our local state matches the global state
      if (event.detail.currentMode !== selectedMode) {
        setSelectedMode(event.detail.currentMode);
      }
    };

    window.addEventListener('modeChanged', handleGlobalModeChange);

    return () => {
      window.removeEventListener('modeChanged', handleGlobalModeChange);
    };
  }, [selectedMode]);

  // Watch for mode changes and apply the correct worst hour
  useEffect(() => {
    // Only run if there was an actual mode change (not on first render)
    if (prevMode !== selectedMode && prevMode !== 'DYNAMIC PLANNING') {
      console.log(`Mode changed from ${prevMode} to ${selectedMode}, applying correct worst hour`);

      // Get the appropriate worst hour for the new mode
      const worstHour = selectedMode === 'GRID ORCHESTRATION'
        ? gridOrchestrationWorstHour
        : dynamicPlanningWorstHour;

      // If we have a worst hour for this mode, apply it after a short delay
      if (worstHour) {
        console.log(`Setting timepoint to ${worstHour} for ${selectedMode}`);
        setTimeout(() => {
          setSelectedTimepoint(worstHour);
        }, 300);
      } else {
        // No worst hour for this mode, just clear the selection
        setSelectedTimepoint(null);
      }
    }

    // Update previous mode
    setPrevMode(selectedMode);
  }, [selectedMode, prevMode, dynamicPlanningWorstHour, gridOrchestrationWorstHour]);

  const memoizedRoutes = useMemo(() => (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/geojson" replace />}
      />

      <Route
        path="/dashboard"
        element={
          <Dashboard
            darkMode={darkMode}
            selectedScenario={selectedScenario}
            onScenarioChange={handleScenarioChange}
            selectedTimepoint={selectedTimepoint}
          />
        }
      />

      <Route
        path="/geojson"
        element={
          <ModifiedGeoJsonViewer
            darkMode={darkMode}
            selectedScenario={selectedScenario}
            onScenarioChange={handleScenarioChange}
            selectedNetworks={selectedNetworks}
            onNetworkSelection={handleNetworkSelection}
            selectedTimepoint={selectedTimepoint}
            onTimeSelect={handleTimeSelect}
            selectedMode={selectedMode} // Pass the selected mode
            prePostMode={prePostMode} // Pass the Pre/Post mode
            onPrePostToggle={handlePrePostToggle} // Pass the Pre/Post toggle handler
            worstPerformingHour={getCurrentWorstHour()} // Pass the appropriate worst hour
            setWorstPerformingHour={setWorstPerformingHour} // Pass the setter function
            onClearTimestamp={handleClearTimestamp} // Pass the clear timestamp handler
          />
        }
      />

      <Route
        path="/ai-insights"
        element={
          <ViolationSummary darkMode={darkMode} />
        }
      />

      <Route
        path="*"
        element={<Navigate to="/geojson" replace />}
      />
    </Routes>
  ), [darkMode, selectedScenario, selectedNetworks, selectedTimepoint, selectedMode, prePostMode]); // Add selectedMode to dependencies

  return (
    <Router>
      <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
        <ModifiedHeader
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          user={user}
          toastNotification={toastNotification}
          setToastNotification={setToastNotification}
          notifications={notifications}
          unreadCount={unreadCount}
          markAllAsRead={markAllAsRead}
          clearNotifications={clearNotifications}
          selectedMode={selectedMode} // Pass the selected mode
          setSelectedMode={handleModeChange} // Pass the setter function
        />
        <main className="pt-14">
          {memoizedRoutes}
        </main>
      </div>
    </Router>
  );
}

export default App;