import React, { useState, useEffect } from 'react';

const KeyObservations = ({ observations, darkMode }) => {
  // Track if this is the first view and if we should animate
  const [isFirstView, setIsFirstView] = useState(() => {
    return localStorage.getItem('hasViewedAnalysisTab') !== 'true';
  });
  
  // Store visible characters for each observation during animation
  const [visibleChars, setVisibleChars] = useState({});
  
  // Track if animation is complete
  const [animationComplete, setAnimationComplete] = useState(false);
  
  // Constants for the typing animation
  const TYPING_SPEED = 30; // milliseconds per character
  const PAUSE_BETWEEN_OBSERVATIONS = 300; // milliseconds pause between observations
  
  useEffect(() => {
    // Only run animation if this is the first view and there are observations
    if (isFirstView && Object.values(observations).filter(Boolean).length > 0) {
      // Mark as seen for future visits
      localStorage.setItem('hasViewedAnalysisTab', 'true');
      
      // Initialize visible characters count for each observation
      const initialVisibleChars = {};
      Object.keys(observations).forEach(key => {
        if (observations[key]) {
          initialVisibleChars[key] = 0;
        }
      });
      
      setVisibleChars(initialVisibleChars);
      setAnimationComplete(false);
      
      // Get keys of valid observations
      const observationKeys = Object.keys(observations).filter(key => observations[key]);
      
      // Function to type each observation one by one
      const typeObservations = async () => {
        for (let k = 0; k < observationKeys.length; k++) {
          const key = observationKeys[k];
          const text = observations[key];
          
          // Type each character one by one
          for (let i = 1; i <= text.length; i++) {
            // Update visible characters
            setVisibleChars(prev => ({
              ...prev,
              [key]: i
            }));
            
            // Wait before typing next character
            await new Promise(resolve => setTimeout(resolve, TYPING_SPEED));
          }
          
          // Pause between observations
          if (k < observationKeys.length - 1) {
            await new Promise(resolve => setTimeout(resolve, PAUSE_BETWEEN_OBSERVATIONS));
          }
        }
        
        // Animation complete
        setAnimationComplete(true);
      };
      
      // Start the typing animation
      typeObservations();
    } else {
      // If not first view, show all text immediately
      setAnimationComplete(true);
    }
  }, [isFirstView, observations]);

  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <ul className="list-disc pl-5 space-y-1">
        {Object.entries(observations).map(([key, observation], index) => {
          if (!observation) return null;
          
          // If animation is complete or not first view, show full text
          if (animationComplete || !isFirstView) {
            return (
              <li key={index} className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {observation}
              </li>
            );
          }
          
          // During animation, only show the currently visible characters
          const visibleText = observation.substring(0, visibleChars[key] || 0);
          
          return (
            <li key={index} className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {visibleText}
              {(visibleChars[key] < observation.length) && 
                <span className="inline-block animate-pulse">|</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default KeyObservations;