import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.offline';

// OfflineTileLayer component that automatically saves tiles for offline use
const OfflineTileLayer = ({ url, attribution, maxZoom, maxNativeZoom, darkMode, zoomSnap, zoomDelta, minZoom, maxBounds }) => {
  const map = useMap();
  const [status, setStatus] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Create offline tile layer with all provided props
    const offlineTileLayer = L.tileLayer.offline(url, {
      attribution,
      maxZoom: maxZoom || 18,
      maxNativeZoom: maxNativeZoom || 18,
      zoomSnap,
      zoomDelta,
      minZoom,
      maxBounds
    }).addTo(map);

    // Event handlers for offline tile operations
    offlineTileLayer.on('savestart', (e) => {
      setStatus('Saving tiles...');
      setProgress(0);
    });

    offlineTileLayer.on('savetileend', (e) => {
      const percent = Math.round((e.storagesize / e.totaltiles) * 100);
      setProgress(percent);
    });

    offlineTileLayer.on('loadend', (e) => {
      setStatus('Tiles saved!');
      setTimeout(() => setStatus(''), 2000);
    });

    offlineTileLayer.on('tilesremoved', () => {
      setStatus('Tiles removed');
      setTimeout(() => setStatus(''), 2000);
    });
    
    // Automatic tile saving on map idle
    const handleMapIdle = () => {
      // Get current view bounds
      const bounds = map.getBounds();
      const zoom = map.getZoom();
      
      // We'll save tiles for current zoom level and one level in/out
      const minZoom = Math.max(12, zoom - 1);
      const maxZoom = Math.min(16, zoom + 1);
      
      // Use the public API method to save tiles
      if (typeof offlineTileLayer.saveTiles === 'function') {
        offlineTileLayer.saveTiles(
          bounds,
          { minZoom, maxZoom },
          null, // No success callback to avoid confirmation dialog
          (err) => console.error('Error saving tiles:', err)
        );
      }
    };
    
    // Add event listener for map idle
    map.on('moveend', handleMapIdle);

    return () => {
      // Cleanup
      map.off('moveend', handleMapIdle);
      if (offlineTileLayer) {
        map.removeLayer(offlineTileLayer);
      }
    };
  }, [map, url, attribution, maxZoom, maxNativeZoom]);

  return (
    <>
      {status && (
        <div style={{
          position: 'absolute',
          bottom: '60px',
          right: '10px',
          backgroundColor: 'white',
          padding: '8px',
          borderRadius: '4px',
          zIndex: 1000,
          boxShadow: '0 1px 5px rgba(0,0,0,0.65)'
        }}>
          <div>{status}</div>
          {progress > 0 && progress < 100 && (
            <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '3px', marginTop: '5px' }}>
              <div style={{
                width: `${progress}%`,
                backgroundColor: '#4CAF50',
                height: '10px',
                borderRadius: '3px',
                transition: 'width 0.3s'
              }} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OfflineTileLayer;