import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { format, parseISO, eachHourOfInterval, startOfYear, endOfYear, addHours } from 'date-fns';
import { ChevronDown, ZoomIn, ZoomOut, RotateCcw } from 'lucide-react';
import MapSearch from './mapSearch';
import Legend from './legend';
import DateTimePicker from './dateTimePicker';
import { HoverTooltip } from './tooltips';

export default function Timeline({
  darkMode,
  selectedScenario,
  geoJsonData,
  onSelect,
  selectedNetworks,
  onTimeSelect,
  selectedTimepoint: externalSelectedTimepoint,
  showSearchAndLegend = true,
  defaultTimestamp,
  onClearTimestamp,
  prePostMode,
  isLoading = false,
  loadingProgress = 0,
  error = null,
  onRetry = null,
  selectedMode,
  worstPerformingHour
}) {
  const [data, setData] = useState([]);
  const [selectedTimepoint, setSelectedTimepoint] = useState(null);
  const [zoom, setZoom] = useState(selectedMode === 'GRID ORCHESTRATION' ? 4 : 0.68);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [hoveredBucket, setHoveredBucket] = useState(null);
  const [prevScenario, setPrevScenario] = useState(selectedScenario);
  const [prevNetworks, setPrevNetworks] = useState(selectedNetworks);
  const [prevMode, setPrevMode] = useState(selectedMode);
  // Add state to track worst hour changes
  const [prevWorstHour, setPrevWorstHour] = useState(worstPerformingHour);
  // Flag to determine if we're in Grid Orchestration mode
  const isGridMode = selectedMode === 'GRID ORCHESTRATION';

  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const timelineRef = useRef(null);

  // Track and respond to external timepoint changes
  useEffect(() => {
    if (externalSelectedTimepoint !== selectedTimepoint) {
      setSelectedTimepoint(externalSelectedTimepoint);

      if (externalSelectedTimepoint) {
        const isValidDate = selectedMode === 'GRID ORCHESTRATION'
          ? externalSelectedTimepoint.startsWith('2025') // Grid dates are in 2025
          : externalSelectedTimepoint.startsWith('2024'); // Dynamic dates are in 2024
        console.log(`Timeline received external timepoint: ${externalSelectedTimepoint} for ${selectedMode} mode`);
        const adjustedTimepoint = isValidDate ? externalSelectedTimepoint : null;
        externalSelectedTimepoint = adjustedTimepoint;
      } else if (externalSelectedTimepoint === null) {
        console.log(`Timeline received null timepoint for ${selectedMode} mode`);
      }
    }
  }, [externalSelectedTimepoint, selectedMode, selectedTimepoint]);

  // Reset selection when scenario changes
  useEffect(() => {
    if (prevScenario !== selectedScenario) {
      console.log(`Timeline: Scenario changed from ${prevScenario} to ${selectedScenario}, resetting selection`);
      setPrevScenario(selectedScenario);
      setSelectedTimepoint(null);
      setHoveredBucket(null);
      if (timelineRef.current) {
        timelineRef.current.scrollLeft = 0;
      }
    }
  }, [selectedScenario, prevScenario]);

  // Reset selection when network selection changes
  useEffect(() => {
    const networkChanged = JSON.stringify(prevNetworks) !== JSON.stringify(selectedNetworks);
    if (networkChanged) {
      console.log("Timeline: Network selection changed, resetting selection");
      setPrevNetworks(selectedNetworks);
      setSelectedTimepoint(null);
      setHoveredBucket(null);
      if (timelineRef.current) {
        timelineRef.current.scrollLeft = 0;
      }
    }
  }, [selectedNetworks, prevNetworks]);

  // Handle worst performing hour changes
  useEffect(() => {
    const worstHourChanged = worstPerformingHour !== prevWorstHour;

    if (worstHourChanged) {
      console.log(`Timeline: Worst hour changed from ${prevWorstHour || 'null'} to ${worstPerformingHour || 'null'}`);
      setPrevWorstHour(worstPerformingHour);

      // If there's no selected timepoint and we have a valid worst hour,
      // use it as the selected timepoint
      if (!selectedTimepoint && worstPerformingHour) {
        setSelectedTimepoint(worstPerformingHour);
        // Also notify parent of this selection
        if (onTimeSelect) {
          console.log(`Timeline: Setting worst hour as selected timepoint: ${worstPerformingHour}`);
          onTimeSelect(worstPerformingHour);
        }
      }
    }
  }, [worstPerformingHour, prevWorstHour, selectedTimepoint, onTimeSelect]);

  // Reset selection when mode changes
  useEffect(() => {
    if (prevMode !== selectedMode) {
      console.log(`Timeline: Mode changed from ${prevMode} to ${selectedMode}, adjusting timeline`);
      setPrevMode(selectedMode);

      // Adjust zoom based on mode
      setZoom(selectedMode === 'GRID ORCHESTRATION' ? 4 : 0.68);

      // Reset selection state
      setSelectedTimepoint(null);
      setHoveredBucket(null);

      // Reset scroll position
      if (timelineRef.current) {
        timelineRef.current.scrollLeft = 0;
      }

      // Fetch new data specific to this mode
      fetchData();

      // Force re-processing of the worst performing hour
      setPrevWorstHour(null);

      // Apply the worst performing hour for this mode if available
      if (worstPerformingHour) {
        console.log(`Timeline: Applying worst hour after mode change: ${worstPerformingHour}`);
        setTimeout(() => {
          setSelectedTimepoint(worstPerformingHour);
          scrollToTimestamp(worstPerformingHour);
          if (onTimeSelect) onTimeSelect(worstPerformingHour);
        }, 300);
      }
    }
  }, [selectedMode, prevMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      // setLoading(true);
      const baseEndpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph';

      // Determine if we're in GRID ORCHESTRATION mode
      const isGridOrchestration = selectedMode === 'GRID ORCHESTRATION';

      // Define valid scenarios for each network
      const validScenarios = {
        small: ['base', 'er4109', 'er4109_1', 'er4139', 'er7633'],
        large: ['base', 'er1256']
      };

      // Updated Scenario parameter mapping to include Pre/Post mode
      const getScenarioParam = (scenario, invalid) => {
        // In GRID ORCHESTRATION mode, always use 'dsse'
        if (isGridOrchestration && invalid === 0) {
          return 'dsse';
        }
        if (scenario === 'er7633') {
          return prePostMode === 'post' ? '7543-post' : '7543-pre';
        }
        if (scenario === 'er4139') {
          return prePostMode === 'post' ? '4139-post' : '4139-pre';
        }
        if (scenario === 'er1256') {
          return prePostMode === 'post' ? '1155-post' : '1155-pre';
        }

        // Default mappings for other scenarios
        switch (scenario) {
          case 'base': return 'base';
          case 'er4109': return 'er050-4109';
          case 'er4109_1': return 'er025-4109';
          default: return 'base';
        }
      };

      const fetchForNetwork = async (networkType) => {
        const scenario = getScenarioParam(selectedScenario, 0);
        const feederParam = networkType === 'large' ? '&feeder_name=large' : '&feeder_name=small';

        // Only fetch underVoltage and overVoltage endpoints for GRID ORCHESTRATION mode
        if (isGridOrchestration || prePostMode === 'post') {
          const responses = await Promise.all([
            fetch(`${baseEndpoint}/underVoltage?scenario=${scenario}${feederParam}`),
            fetch(`${baseEndpoint}/overVoltage?scenario=${scenario}${feederParam}`),
            // Return empty arrays for line and transformer violations in Grid mode
            Promise.resolve({ json: () => Promise.resolve([]) }),
            Promise.resolve({ json: () => Promise.resolve([]) })
          ]);

          return Promise.all(responses.map(r => r.json ? r.json() : r));
        } else {
          // Fetch all endpoints for other modes
          const responses = await Promise.all([
            fetch(`${baseEndpoint}/underVoltage?scenario=${scenario}${feederParam}`),
            fetch(`${baseEndpoint}/overVoltage?scenario=${scenario}${feederParam}`),
            fetch(`${baseEndpoint}/lineviolations?scenario=${scenario === 'dsse' ? getScenarioParam(selectedScenario, 1) : scenario}${feederParam}`),
            fetch(`${baseEndpoint}/transformerviolations?scenario=${scenario === 'dsse' ? getScenarioParam(selectedScenario, 1) : scenario}${feederParam}`)
          ]);

          return Promise.all(responses.map(r => r.json()));
        }
      };

      // Initialize the timeline for the whole year
      const yearStart = startOfYear(new Date(2024, 0, 1));
      const yearEnd = endOfYear(new Date(2024, 11, 31));
      const hours = eachHourOfInterval({ start: yearStart, end: yearEnd });

      // Initialize the timeline data points differently based on mode
      let timepoints = {};

      if (isGridOrchestration) {
        // For GRID ORCHESTRATION, we expect 12 hours of 15-minute data
        // Use 2025-03-25 as a sample date as shown in the example
        const baseDate = new Date(2025, 2, 25, 14, 0, 0); // Mar 25, 2025, 14:00

        // Create timepoints for 12 hours with 15-minute intervals
        for (let i = 0; i < 12 * 4; i++) { // 12 hours * 4 (15-min intervals)
          const currentTime = addHours(baseDate, i / 4); // Divide by 4 to get hours
          const timeKey = format(currentTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
          timepoints[timeKey] = { under: 0, over: 0, line: 0, power: 0, time: timeKey };
        }

        // Check if we have a default timestamp from a "worst performing hour" 
        // and ensure it's included in our timepoints
        if (worstPerformingHour) {
          try {
            // Add the worst performing hour if it's not already in the list
            if (!timepoints[worstPerformingHour]) {
              timepoints[worstPerformingHour] = {
                under: 0, over: 0, line: 0, power: 0,
                time: worstPerformingHour
              };
              console.log(`Added worst performing hour to GRID ORCHESTRATION timepoints: ${worstPerformingHour}`);
            }
          } catch (error) {
            console.error("Error processing worst performing hour for GRID ORCHESTRATION mode:", error);
          }
        }
      } else {
        // For DYNAMIC PLANNING mode, use the full year as before
        hours.forEach(hour => {
          const timeKey = format(hour, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
          timepoints[timeKey] = { under: 0, over: 0, line: 0, power: 0, time: timeKey };
        });

        // Ensure worst performing hour is included
        if (worstPerformingHour && !timepoints[worstPerformingHour]) {
          timepoints[worstPerformingHour] = {
            under: 0, over: 0, line: 0, power: 0,
            time: worstPerformingHour
          };
          console.log(`Added worst performing hour to DYNAMIC PLANNING timepoints: ${worstPerformingHour}`);
        }
      }

      // Fetch data for all selected networks if the scenario is valid for that network
      const networkPromises = [];

      // In GRID ORCHESTRATION mode, always fetch data if networks are selected
      // regardless of scenario compatibility
      if (isGridOrchestration) {
        if (selectedNetworks.small) {
          console.log("Fetching GRID ORCHESTRATION data for small network");
          networkPromises.push(fetchForNetwork('small'));
        }
        if (selectedNetworks.large) {
          console.log("Fetching GRID ORCHESTRATION data for large network");
          networkPromises.push(fetchForNetwork('large'));
        }
      } else {
        // For other modes, check scenario compatibility
        // For small network
        if (selectedNetworks.small && validScenarios.small.includes(selectedScenario)) {
          networkPromises.push(fetchForNetwork('small'));
        }

        // For large network
        if (selectedNetworks.large && validScenarios.large.includes(selectedScenario)) {
          networkPromises.push(fetchForNetwork('large'));
        }
      }

      if (networkPromises.length > 0) {
        const networkResults = await Promise.all(networkPromises);
        networkResults.forEach(([under, over, line, power]) => {
          if (!under.message) under.forEach(v => {
            if (timepoints[v.timestamp]) {
              timepoints[v.timestamp].under += v.count;
            } else if (isGridOrchestration) {
              // For GRID ORCHESTRATION, handle possible different timestamp formats
              try {
                const parsedDate = parseISO(v.timestamp);
                const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                if (timepoints[formattedDate]) {
                  timepoints[formattedDate].under += v.count;
                } else {
                  // Add the timestamp if it didn't exist
                  timepoints[v.timestamp] = { under: v.count, over: 0, line: 0, power: 0, time: v.timestamp };
                }
              } catch (error) {
                console.error(`Error processing timestamp ${v.timestamp}:`, error);
                // Add the timestamp as-is if parsing fails
                timepoints[v.timestamp] = { under: v.count, over: 0, line: 0, power: 0, time: v.timestamp };
              }
            }
          });
          if (!over.message) over.forEach(v => {
            if (timepoints[v.timestamp]) {
              timepoints[v.timestamp].over += v.count;
            } else if (isGridOrchestration) {
              try {
                const parsedDate = parseISO(v.timestamp);
                const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                if (timepoints[formattedDate]) {
                  timepoints[formattedDate].over += v.count;
                } else {
                  timepoints[v.timestamp] = { under: 0, over: v.count, line: 0, power: 0, time: v.timestamp };
                }
              } catch (error) {
                console.error(`Error processing timestamp ${v.timestamp}:`, error);
                timepoints[v.timestamp] = { under: 0, over: v.count, line: 0, power: 0, time: v.timestamp };
              }
            }
          });
          if (Array.isArray(line)) line.forEach(v => {
            if (timepoints[v.timestamp]) {
              timepoints[v.timestamp].line += v.violations;
            } else if (isGridOrchestration) {
              try {
                const parsedDate = parseISO(v.timestamp);
                const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                if (timepoints[formattedDate]) {
                  timepoints[formattedDate].line += v.violations;
                } else {
                  timepoints[v.timestamp] = { under: 0, over: 0, line: v.violations, power: 0, time: v.timestamp };
                }
              } catch (error) {
                console.error(`Error processing timestamp ${v.timestamp}:`, error);
                timepoints[v.timestamp] = { under: 0, over: 0, line: v.violations, power: 0, time: v.timestamp };
              }
            }
          });
          if (Array.isArray(power)) power.forEach(v => {
            if (timepoints[v.timestamp]) {
              timepoints[v.timestamp].power += v.violations;
            } else if (isGridOrchestration) {
              try {
                const parsedDate = parseISO(v.timestamp);
                const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                if (timepoints[formattedDate]) {
                  timepoints[formattedDate].power += v.violations;
                } else {
                  timepoints[v.timestamp] = { under: 0, over: 0, line: 0, power: v.violations, time: v.timestamp };
                }
              } catch (error) {
                console.error(`Error processing timestamp ${v.timestamp}:`, error);
                timepoints[v.timestamp] = { under: 0, over: 0, line: 0, power: v.violations, time: v.timestamp };
              }
            }
          });
        });
      }

      // If we have a worst performing hour, ensure it's highlighted in the data
      if (worstPerformingHour && timepoints[worstPerformingHour]) {
        // Only use the worst hour if it's appropriate for the current mode
        const isValidDate = selectedMode === 'GRID ORCHESTRATION'
          ? worstPerformingHour.startsWith('2025') // Grid dates are in 2025
          : worstPerformingHour.startsWith('2024'); // Dynamic dates are in 2024

        if (!isValidDate) {
          console.log(`Ignoring worst hour ${worstPerformingHour} - not valid for ${selectedMode} mode`);
          // Don't add mock violations for invalid dates
          return;
        }
      }

      const sortedData = Object.values(timepoints).sort((a, b) => {
        try {
          return parseISO(a.time).getTime() - parseISO(b.time).getTime();
        } catch (error) {
          // If parsing fails, use string comparison
          return a.time.localeCompare(b.time);
        }
      });

      setData(sortedData);
    } catch (error) {
      console.error('Error fetching timeline data:', error);
      setData([]);
    }
  }, [selectedScenario, selectedNetworks, prePostMode, selectedMode, worstPerformingHour]);

  // Fetch data when dependencies change
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to combine hours into buckets based on zoom level
  const getBucketedData = useMemo(() => {
    if (!data.length) return [];

    // Adjust bucket size calculation based on mode and zoom
    const isGridOrchestration = selectedMode === 'GRID ORCHESTRATION';

    // For GRID ORCHESTRATION, we want smaller buckets since we have 15-min intervals
    const bucketSize = isGridOrchestration
      ? Math.max(1, Math.floor(4 / zoom)) // 4 data points per hour for 15-min intervals
      : Math.max(1, Math.floor(12 / zoom)); // Original logic for hourly data

    const buckets = [];

    for (let i = 0; i < data.length; i += bucketSize) {
      const bucket = data.slice(i, i + bucketSize);
      const totalViolations = bucket.reduce((sum, point) =>
        sum + point.under + point.over + point.line + point.power, 0);

      // Check if this bucket contains the worst performing hour
      const containsWorstHour = worstPerformingHour &&
        bucket.some(item => item.time === worstPerformingHour);

      buckets.push({
        time: bucket[0].time,
        endTime: bucket[bucket.length - 1].time,
        total: totalViolations,
        violations: totalViolations > 0,
        isWorstHour: containsWorstHour,
        originalIndices: bucket.map((_, idx) => i + idx)
      });
    }

    return buckets;
  }, [data, zoom, selectedMode, worstPerformingHour]);

  // Find selected bucket index
  const findBucketIndexForTimestamp = useCallback((timestamp) => {
    if (!timestamp || !getBucketedData || !data) return null;

    try {
      const selectedTime = new Date(timestamp).getTime();

      // Find the bucket where the selected time falls exactly within its range
      return getBucketedData.findIndex(bucket => {
        try {
          const bucketStartTime = new Date(bucket.time).getTime();
          const bucketEndTime = new Date(bucket.endTime).getTime();
          // Add a small buffer (1 second) to account for potential rounding
          return selectedTime >= bucketStartTime - 1000 && selectedTime <= bucketEndTime + 1000;
        } catch (error) {
          console.error(`Error comparing times for bucket with timestamp ${bucket.time}:`, error);
          return false;
        }
      });
    } catch (error) {
      console.error(`Error finding bucket for timestamp ${timestamp}:`, error);
      return null;
    }
  }, [data, getBucketedData]);

  const isTimeInBucketRange = (timestamp, bucket) => {
    if (!bucket || !data || !timestamp) return false;

    try {
      const selectedTime = new Date(timestamp).getTime();
      const bucketStartTime = new Date(data[bucket.originalIndices[0]].time).getTime();
      const bucketEndTime = new Date(data[bucket.originalIndices[bucket.originalIndices.length - 1]].time).getTime();

      return selectedTime >= bucketStartTime && selectedTime <= bucketEndTime;
    } catch (error) {
      console.error(`Error checking if time ${timestamp} is in bucket range:`, error);
      return false;
    }
  };

  const handleDropdownSelect = (idx) => {
    const selectedData = data[idx];
    if (selectedData) {
      const newTimepoint = selectedData.time;
      setSelectedTimepoint(newTimepoint);

      console.log(`Timeline handleDropdownSelect: Selected date ${newTimepoint} for ${selectedMode} mode`);

      if (onTimeSelect) {
        onTimeSelect(newTimepoint);
      }

      setIsDropdownOpen(false);

      // Scroll to the selected bucket
      if (timelineRef.current) {
        const bucketIndex = findBucketIndexForTimestamp(newTimepoint);
        if (bucketIndex !== null) {
          const scrollPosition = bucketIndex * bucketWidth;
          timelineRef.current.scrollLeft = scrollPosition - (timelineRef.current.clientWidth / 2);
        }
      }
    }
  };

  const handleTimeSelect = useCallback((timepoint) => {
    console.log(`Timeline handleTimeSelect called with: ${timepoint} for ${selectedMode} mode`);

    // Update local state
    setSelectedTimepoint(timepoint);

    // Notify parent component
    if (onTimeSelect) {
      onTimeSelect(timepoint);
    }
  }, [onTimeSelect, selectedMode]);

  const handleScroll = (e) => {
    if (timelineRef.current) {
      setScrollLeft(e.target.scrollLeft);
    }
  };

  const handleZoomIn = () => {
    const newZoom = Math.min(zoom * 1.5, 8);
    setZoom(newZoom);
  };

  const handleZoomOut = () => {
    // Set different minimum zoom levels based on mode
    const minZoom = selectedMode === 'GRID ORCHESTRATION' ? 1.5 : 0.68;
    const newZoom = Math.max(zoom / 1.5, minZoom);
    setZoom(newZoom);
  };

  const handleReset = () => {
    // Set initial zoom based on mode
    setZoom(selectedMode === 'GRID ORCHESTRATION' ? 4 : 0.68);
    setHoveredBucket(null);
    setScrollLeft(0);

    console.log(`Timeline handleReset: Resetting to default time for ${selectedMode} mode`);

    // Always use the parent's clear handler if available
    if (onClearTimestamp) {
      onClearTimestamp(selectedMode);
    }
    // No parent handler, fall back to local logic
    else {
      // Try to use worstPerformingHour
      if (worstPerformingHour) {
        console.log(`Using worst hour for ${selectedMode}: ${worstPerformingHour}`);
        setSelectedTimepoint(worstPerformingHour);

        if (onTimeSelect) {
          onTimeSelect(worstPerformingHour);
        }

        // Scroll to the worst performing hour
        setTimeout(() => {
          scrollToTimestamp(worstPerformingHour);
        }, 100);
      }
      // Last resort - use a default time appropriate for the mode
      else {
        const defaultTime = isGridMode
          ? "2025-03-25T14:00:00.000Z"  // Default time for Grid mode
          : "2024-01-01T12:00:00.000Z"; // Default time for Dynamic mode

        console.log(`No worst hour available, using default time: ${defaultTime}`);
        setSelectedTimepoint(defaultTime);

        if (onTimeSelect) {
          onTimeSelect(defaultTime);
        }
      }
    }

    if (timelineRef.current) {
      timelineRef.current.scrollLeft = 0;
    }
  };

  // Calculate bucket width based on mode and zoom
  const bucketWidth = useMemo(() => {
    const isGridOrchestration = selectedMode === 'GRID ORCHESTRATION';

    // For GRID ORCHESTRATION (15-min intervals), use smaller bucket width by default
    const baseWidth = isGridOrchestration ? 8 : 6;

    return Math.max(3, baseWidth * zoom);
  }, [zoom, selectedMode]);

  // Add this as a new function in your Timeline component, after the other utility functions
  const scrollToTimestamp = useCallback((timestamp) => {
    if (!timelineRef.current || !timestamp) return;

    const bucketIndex = findBucketIndexForTimestamp(timestamp);
    if (bucketIndex === null) return;

    const bucketPosition = bucketIndex * bucketWidth;
    const containerWidth = timelineRef.current.clientWidth;
    const scrollPosition = bucketPosition - (containerWidth / 2);

    timelineRef.current.scrollTo({
      left: Math.max(0, scrollPosition),
      behavior: 'smooth'
    });
  }, [findBucketIndexForTimestamp, bucketWidth]);

  // Scroll to worst performing hour on initial load if no timepoint is selected
  useEffect(() => {
    // If we have a worst performing hour and no selected timepoint, use the worst hour
    if (worstPerformingHour && !externalSelectedTimepoint && !selectedTimepoint) {
      console.log(`Timeline: Using worst hour for ${selectedMode}: ${worstPerformingHour}`);

      // Set as the selected timepoint
      setSelectedTimepoint(worstPerformingHour);

      // Notify parent component
      if (onTimeSelect) {
        onTimeSelect(worstPerformingHour);
      }

      // Scroll to it after a short delay to ensure the timeline is ready
      setTimeout(() => {
        scrollToTimestamp(worstPerformingHour);
      }, 300);
    }
  }, [worstPerformingHour, externalSelectedTimepoint, selectedTimepoint, scrollToTimestamp, selectedMode, onTimeSelect]);

  // Scroll to selected timepoint when it changes
  useEffect(() => {
    if (externalSelectedTimepoint && externalSelectedTimepoint !== selectedTimepoint) {
      console.log(`Timeline scrolling to external timepoint: ${externalSelectedTimepoint} for ${selectedMode}`);
      setSelectedTimepoint(externalSelectedTimepoint);
      scrollToTimestamp(externalSelectedTimepoint);
    }
  }, [externalSelectedTimepoint, scrollToTimestamp, selectedMode, selectedTimepoint]);

  // In Timeline component, where you set up the scroll handler
  useEffect(() => {
    const timelineElement = timelineRef.current;
    if (timelineElement) {
      timelineElement.addEventListener('scroll', handleScroll);
      return () => timelineElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const refreshTooltipState = useCallback(() => {
    if (selectedTimepoint && timelineRef.current) {
      // Find the bucket containing the selected timepoint
      const bucketIndex = findBucketIndexForTimestamp(selectedTimepoint);
      if (bucketIndex !== null) {
        // Force tooltip recalculation by scrolling to the selected timepoint
        const scrollPosition = bucketIndex * bucketWidth;
        timelineRef.current.scrollLeft = scrollPosition - (timelineRef.current.clientWidth / 2);

        // Force a state update to trigger re-render of tooltip
        setSelectedTimepoint(prev => {
          if (prev) {
            // Create a new string with the same value to force re-render
            return String(prev);
          }
          return prev;
        });
      }
    }
  }, [selectedTimepoint, findBucketIndexForTimestamp, bucketWidth]);

  useEffect(() => {
    // We need to detect when the component becomes visible again after tab switch
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When component becomes visible again
        if (entry.isIntersecting && selectedTimepoint) {
          // Give the DOM time to fully initialize
          setTimeout(refreshTooltipState, 150);
        }
      },
      { threshold: 0.1 }
    );

    // Observe the timeline container
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, selectedTimepoint, refreshTooltipState]);

  // Listen for tab switch and mode change events
  useEffect(() => {
    const handleTabSwitch = () => {
      if (selectedTimepoint) {
        setTimeout(refreshTooltipState, 100);
      }
    };

    const handleModeChange = (event) => {
      console.log(`Timeline detected mode change event: ${event.detail.previousMode} -> ${event.detail.currentMode}`);
      // Reset data for the new mode
      fetchData();
    };

    window.addEventListener('tabSwitchToNetwork', handleTabSwitch);
    window.addEventListener('modeChanged', handleModeChange);

    return () => {
      window.removeEventListener('tabSwitchToNetwork', handleTabSwitch);
      window.removeEventListener('modeChanged', handleModeChange);
    };
  }, [selectedTimepoint, refreshTooltipState, fetchData]);

  // Update the DateTimePicker section in the Timeline component
  const renderDateTimePicker = () => (
    <div className="flex-shrink-0 w-36 relative" ref={dropdownRef}>
      <button
        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        className={`w-full flex items-center justify-between px-2 py-1.5 rounded border hover:bg-transparent ${darkMode
            ? 'bg-gray-700 border-gray-600 text-gray-200'
            : 'bg-white border-gray-300 text-gray-700'
          }`}
      >
        <span className="text-xs">
          {selectedTimepoint || worstPerformingHour
            ? format(parseISO(selectedTimepoint || worstPerformingHour), "dd MMM yyyy | HH:mm")
            : 'Select time'}
        </span>
        <ChevronDown size={14} />
      </button>

      {isDatePickerOpen && (
        <DateTimePicker
          selectedDate={selectedTimepoint || worstPerformingHour}
          onSelect={(date) => {
            console.log(`DateTimePicker onSelect: ${date} for ${selectedMode}`);
            handleTimeSelect(date);
            scrollToTimestamp(date);
            setIsDatePickerOpen(false);
          }}
          isOpen={isDatePickerOpen}
          onClose={() => setIsDatePickerOpen(false)}
          darkMode={darkMode}
          selectedMode={selectedMode} // Pass current mode
          worstPerformingHour={worstPerformingHour} // Pass worst hour for current mode
        />
      )}
    </div>
  );

  return (
    <div className={`h-16 ${darkMode ? 'bg-gray-800' : 'bg-white'
      } border-t z-[900] flex items-center px-4 gap-4`}>
      {/* Mode Indicator */}
      <div className="flex-shrink-0">
        <span className={`text-sm font-medium px-2 py-1 rounded-lg ${selectedMode === 'GRID ORCHESTRATION'
            ? 'bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100'
            : 'bg-blue-100 text-blue-800 dark:bg-blue-700 dark:text-blue-100'
          }`}>
          {selectedMode}
        </span>
      </div>

      {/* Search Component */}
      {showSearchAndLegend && (
        <div className="flex-shrink-0 w-48">
          <MapSearch
            darkMode={darkMode}
            geoJsonData={geoJsonData}
            onSelect={onSelect}
          />
        </div>
      )}

      {/* Time Selector */}
      {showSearchAndLegend &&
        renderDateTimePicker()}

      {/* Timeline Chart */}
      {isLoading && (
        <div className="flex-grow h-12 px-2 bg-white border border-[#EAEAEC] flex items-center">
          <div className="w-full h-8 bg-gray-200 rounded-sm animate-pulse" />
        </div>
      )}
      {showSearchAndLegend && !isLoading && (
        <div className="flex-grow h-12 px-2 bg-white border border-[#EAEAEC] flex items-center">
          <div className="flex-1 relative h-full overflow-hidden" ref={containerRef}>
            <div
              className="absolute inset-0 overflow-x-auto hide-scrollbar overflow-y-hidden timeline-chart"
              ref={timelineRef}
              onScroll={handleScroll}
            >
              <div
                className="h-full flex items-center"
                style={{
                  width: `${getBucketedData.length * bucketWidth}px`,
                  minWidth: '100%'
                }}
              >
                {getBucketedData.map((bucket, idx) => {
                  const isSelected = (selectedTimepoint || worstPerformingHour) &&
                    isTimeInBucketRange(selectedTimepoint || worstPerformingHour, bucket);

                  // Highlight worst performing hour differently
                  const isWorstHour = bucket.isWorstHour;

                  return (
                    <div
                      key={bucket.time}
                      className="flex-shrink-0 relative group cursor-pointer"
                      style={{ width: `${bucketWidth}px` }}
                      onClick={() => handleDropdownSelect(bucket.originalIndices[0])}
                      onMouseEnter={() => setHoveredBucket(idx)}
                      onMouseLeave={() => setHoveredBucket(null)}
                    >
                      <div className="h-8 flex items-center justify-center">
                        <div
                          className={`transition-all duration-200 rounded-sm ${bucket.violations
                              ? 'bg-red-400'
                              : 'bg-gray-200'
                            }`}
                          style={{
                            width: `${Math.max(bucketWidth * 0.8, 2)}px`,
                            height: isSelected ? '32px' : `${Math.max(bucket.total * 2, 4)}px`
                          }}
                        />
                      </div>

                      {isSelected && (
                        <>
                          {/* Selected point indicator */}
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="w-3 h-3 bg-blue-500 rounded-full shadow-md" />
                          </div>
                        </>
                      )}
                      {hoveredBucket === idx && !isSelected && (
                       <HoverTooltip
                         time={bucket.time}
                         containerRef={containerRef}
                         timelineRef={timelineRef}
                         bucketWidth={bucketWidth}
                         index={idx}
                         darkMode={darkMode}
                       />
                     )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Zoom Controls */}
      {showSearchAndLegend && (
        <div className="flex-shrink-0 flex items-center space-x-1.5 text-gray-600">
          <button
            onClick={handleZoomIn}
            className="p-1 rounded hover:bg-gray-100"
          >
            <ZoomIn size={16} />
          </button>
          <button
            onClick={handleZoomOut}
            className="p-1 rounded hover:bg-gray-100"
          >
            <ZoomOut size={16} />
          </button>
          <button
            onClick={handleReset}
            className="p-1 rounded hover:bg-gray-100"
          >
            <RotateCcw size={16} />
          </button>
        </div>
      )}
      {/* Legend */}
      {showSearchAndLegend && (
        <Legend darkMode={darkMode} />
      )}
    </div>
  );
}