import React, { useState, useEffect } from 'react';
import { MessageSquare, Plus, ArrowLeft } from 'lucide-react';
import ChatWindow from './chatWindow';

const AssistantContainer = ({ darkMode, onPreviewClick }) => {
  // Chat sessions state
  const [chatSessions, setChatSessions] = useState([
    {
      id: '1',
      title: 'Top 10 loaded lines',
      description: 'Show me top 10 loaded lines, planned work for today.',
      timestamp: 'Yesterday',
      messages: [
        {
          id: 1,
          time: '2:14 PM',
          sender: 'John Doe',
          content: 'Show me top 10 loaded lines, planned work for today.',
          avatar: 'https://ui-avatars.com/api/?name=JD&background=random'
        },
        {
          id: 2,
          time: '2:15 PM',
          sender: 'AI assistant',
          content: '2 line overloads',
          isAI: true,
          data: [
            {
              time: '8:00 am',
              lines: [
                { id: '1115', violation: '14.49%', status: 'Warning' },
                { id: '121', violation: '1.42%', status: 'Normal' }
              ]
            },
            {
              time: '10:00 am',
              lines: [
                { id: '1115', violation: '14.49%', status: 'Warning' },
                { id: '121', violation: '1.42%', status: 'Normal' }
              ]
            }
          ],
          recommendations: {
            title: 'AI Recommendations',
            content: 'ThinkLabs AI recommended to take action to reduce transformer limit temporarily for 3 hours.'
          }
        }
      ]
    },
    {
      id: '2',
      title: 'Summarize forecasted power flow for P1U on 3 Jul 2024',
      description: 'Feeder characteristics - nodes, voltage level, rated capacity, Feeder load profiles',
      timestamp: 'Yesterday',
      messages: [
        {
          id: 1,
          time: '9:48',
          sender: 'John Doe',
          content: 'Can you analyze the power flow voltage violations?',
          avatar: 'https://ui-avatars.com/api/?name=JD&background=random'
        },
        {
          id: 2,
          time: '9:49',
          sender: 'AI assistant',
          content: 'I\'ve analyzed the data and found 3 voltage violations.',
          isAI: true
        }
      ]
    },
    {
      id: '3',
      title: 'Show me asset utilzation for p1ulv7543 from 1st Jan 2024 to 31st Dec 2024',
      description: 'Asset power flow metrics, high, low, average utilzation, violations',
      timestamp: '21 Mar',
      messages: []
    },
    {
      id: '4',
      title: 'What is the connection impact of Load Interconnection(50kW) on P1ULV7543',
      description: '50kW at P1ULV7543, Powerflow summary, Constraints and Violations',
      timestamp: '21 Mar',
      messages: []
    },
    {
      id: '5',
      title: 'How to mitigate connection impacts of Load Interconnection(50kW) on P1ULV7543',
      description: 'Mitigation solution-type, reduce 3 violations',
      timestamp: '20 Mar',
      messages: []
    },
    {
      id: '6',
      title: 'Show me training metrics for P9U',
      description: 'Training data set, accuracy, speed',
      timestamp: '19 Mar',
      messages: []
    },
  ]);

  // Active chat session
  const [activeChat, setActiveChat] = useState(null); // No active chat by default

  // Create a new chat
  const createNewChat = () => {
    const newChat = {
      id: Date.now().toString(),
      title: 'New conversation',
      description: 'Start a new conversation with the AI assistant',
      timestamp: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
      messages: []
    };
    
    setChatSessions([newChat, ...chatSessions]);
    setActiveChat(newChat.id); // Set this as active chat
  };

  // Get the active chat data
  const getActiveChatData = () => {
    return chatSessions.find(chat => chat.id === activeChat);
  };

  // Update messages in a chat
  const updateChatMessages = (chatId, newMessages) => {
    setChatSessions(prevSessions => 
      prevSessions.map(session => 
        session.id === chatId 
          ? { ...session, messages: newMessages } 
          : session
      )
    );
  };

  // Go back to chat list
  const handleBackToList = () => {
    setActiveChat(null);
  };

  return (
    <div className={`h-full overflow-hidden ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
      {activeChat === null ? (
        // Chat List View - Show only when no chat is active
        <div className={`h-full flex flex-col ${
          darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
        }`}>

          {/* New Chat Button */}
          <div className="p-2">
            <button 
              onClick={createNewChat}
              className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-md ${
                darkMode 
                  ? 'bg-gray-800 hover:bg-gray-700 text-white' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-900'
              }`}
            >
              <Plus size={16} />
              <span>New chat</span>
            </button>
          </div>

          {/* Chat List */}
          <div className="flex-1 overflow-y-auto">
            {chatSessions.map((chat) => (
              <div 
                key={chat.id}
                onClick={() => setActiveChat(chat.id)}
                className={`p-3 cursor-pointer transition-colors ${
                  darkMode 
                    ? 'hover:bg-gray-800 text-white' 
                    : 'hover:bg-gray-100 text-gray-900'
                }`}
              >
                <div className="flex justify-between items-start">
                  <div className="font-medium">{chat.title}</div>
                  <div className="text-xs text-gray-500">
                    {chat.timestamp}
                  </div>
                </div>
                <p className={`text-sm mt-1 line-clamp-2 ${
                  darkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {chat.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // Chat Window View - Show only when a chat is active
        <div className="h-full relative">
          {/* Back button */}
          <div className={`absolute top-0 left-0 z-10 p-2 ${
            darkMode ? 'bg-gray-900 bg-opacity-70' : 'bg-white bg-opacity-70'
          }`}>
            <button
              onClick={handleBackToList}
              className={`p-2 rounded-full ${
                darkMode 
                  ? 'hover:bg-gray-800 text-gray-300' 
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
            >
              <ArrowLeft size={20} />
            </button>
          </div>
          
          {/* Active Chat Window */}
          <ChatWindow 
            darkMode={darkMode} 
            onPreviewClick={onPreviewClick}
            messages={getActiveChatData()?.messages || []}
            onMessagesUpdate={(newMessages) => updateChatMessages(activeChat, newMessages)}
          />
        </div>
      )}
    </div>
  );
};

export default AssistantContainer;