import React, { useState, useEffect, useRef } from 'react';
import { Search } from 'lucide-react';

const MapSearch = ({ darkMode, geoJsonData, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Calculate center point for LineString features
  const calculateLineCenter = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return null;
    
    const totalPoints = coordinates.length;
    const midIndex = Math.floor(totalPoints / 2);
    
    return coordinates[midIndex];
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      setSuggestions([]);
      setIsOpen(false);
      return;
    }

    const results = [];
    geoJsonData.forEach(data => {
      if (data.type === 'FeatureCollection') {
        data.features.forEach(feature => {
          const name = feature.properties.name;
          const type = feature.properties.type;
          
          if (name.toLowerCase().includes(query.toLowerCase()) || type.toLowerCase().includes(query.toLowerCase())) {
            let centerCoordinates;
            if (feature.geometry.type === 'Point') {
              centerCoordinates = feature.geometry.coordinates;
            } else if (feature.geometry.type === 'LineString') {
              centerCoordinates = calculateLineCenter(feature.geometry.coordinates);
            }

            if (centerCoordinates) {
              results.push({
                id: name,
                type: feature.properties.type,
                feature: feature,
                displayName: `${feature.properties.type}: ${name}`,
                center: centerCoordinates
              });
            }
          }
        });
      }
    });

    setSuggestions(results);
    setIsOpen(true);
  };

  const handleSelect = (suggestion) => {
    onSelect(suggestion);
    setSearchQuery('');
    setSuggestions([]);
    setIsOpen(false);
  };

  return (
    <div className={`relative w-48 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg z-[900]`}>
      <div className="relative flex items-center">
        <Search 
          className={`absolute left-3 h-5 w-5 ${
            darkMode ? 'text-gray-400' : 'text-gray-500'
          }`}
        />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search assets..."
          className={`w-full pl-10 py-2 rounded-lg focus:outline-none focus:ring-2 ${
            darkMode 
              ? 'bg-gray-800 text-white focus:ring-blue-500' 
              : 'bg-white text-gray-900 focus:ring-blue-400'
          }`}
        />
      </div>
      
      {isOpen && suggestions.length > 0 && (
        <div className={`absolute w-full mt-2 rounded-lg shadow-lg ${
          darkMode ? 'bg-gray-800' : 'bg-white'
        } max-h-[60vh] overflow-y-auto z-50`}>
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              onClick={() => handleSelect(suggestion)}
              className={`px-4 py-2 cursor-pointer ${
                darkMode
                  ? 'hover:bg-gray-700'
                  : 'hover:bg-gray-100'
              }`}
            >
              {suggestion.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MapSearch;