import { color } from 'framer-motion';
import React from 'react';

const NetworkWelcome = ({ selectedMode, darkMode }) => {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <div className="text-center max-w-4xl">
        {/* SVG container */}
        <img
          src="/icons/landingpage.svg"
          alt="Select Network"
          className="w-64 h-48 mx-auto mb-4"
        />

        {/* Header Text */}
        <h2 className="text-xl font-semibold mb-2">Select a Network</h2>
        <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-8`}>Please make a selection to get started and see the details.</p>

        {/* Info Box */}
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`} style={{ width: 975, height: 250, background: 'white', borderRadius: 6, border: '1px #EAEAEC solid' }} >
          <h3 className={`${darkMode ? 'text-white' : 'text-black'} text-lg font-bold mb-10`}>Performance Highlights of AI-based {selectedMode === 'DYNAMIC PLANNING' ? 'Dynamic Planning' : 'Distribution System State Estimation'}</h3>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-4 mb-6 px-4">
            <div className="text-center" style={{ width: 237, height: 70, paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: '#ECF0FD', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center', gap: 9, display: 'inline-flex' }}>
              <div className="text-xl font-bold text-black">{selectedMode === 'DYNAMIC PLANNING' ? '99.7%' : '99.8%'}</div>
              <div className="text-sm" style={{ color: '#6C6C6C' }}>{selectedMode === 'DYNAMIC PLANNING' ? 'AC unbalanced power flow accuracy' : 'Accuracy even with incomplete observability'}</div>
            </div>

            <div className="text-center" style={{ width: 237, height: 70, paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: '#ECF0FD', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center', gap: 9, display: 'inline-flex' }}>
              <div className="text-xl font-bold text-black">{selectedMode === 'DYNAMIC PLANNING' ? '4.25 min' : '1 sec'}</div>
              <div className="text-sm" style={{ color: '#6C6C6C' }}>{selectedMode === 'DYNAMIC PLANNING' ? 'AI digital twin training time' : 'per DSSE inference on average'}</div>
            </div>

            <div className="text-center" style={{ width: 237, height: 70, paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: '#ECF0FD', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center', gap: 9, display: 'inline-flex' }}>
              {/* <div style={{color: 'black', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>FOR A LARGE CIRCUIT</div> */}
              <div className="text-xl font-bold text-black">{selectedMode === 'DYNAMIC PLANNING' ? '8760' : 'Automated'}</div>
              <div className="text-sm" style={{ color: '#6C6C6C' }}>{selectedMode === 'DYNAMIC PLANNING' ? 'power flow analysis in' : 'Topology estimation'}</div>
              <div className="text-xl font-bold text-black">{selectedMode === 'DYNAMIC PLANNING' ? '63 sec' : ''}</div>
            </div>

            <div className="text-center" style={{ width: 237, height: 70, paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: '#ECF0FD', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center', gap: 9, display: 'inline-flex' }}>
              <div className="text-xl font-bold text-black">{selectedMode === 'DYNAMIC PLANNING' ? '7.2 msec' : 'Automated'}</div>
              <div className="text-sm" style={{ color: '#6C6C6C' }}>{selectedMode === 'DYNAMIC PLANNING' ? 'per power flow run on average' : 'Bad data detection'}</div>
            </div>
          </div>

          {/* Description */}
          <p className="text-sm text-gray-600 mb-4">
            {selectedMode === 'DYNAMIC PLANNING' ? 'Horizontal scalability to run thousands of simultaneous time series power flows across entire utility service territories and at multiple forecast sensitivities' 
            : 'Horizontal scalability to run real-time DSSE across entire utility service territories'}
          </p>

          {/* Learn More Button */}
          {/* <button className="inline-flex items-center px-4 py-2 border border-solid text-sm font-medium rounded-md shadow-sm bg-white hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          style={{color: 'black', fontSize: 14, fontWeight: '700', wordWrap: 'break-word'}}>
            Learn more
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default NetworkWelcome;