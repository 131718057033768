import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { Line, Bar, Radar } from "react-chartjs-2";
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, RadialLinearScale, elements } from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import { format, parseISO, differenceInMinutes, set } from 'date-fns';
import RangeSlider from "./util/RangeSlider/rangeSlider";
import RightPanel from "./util/RightPanel/rightPanel";
import KeyObservations from "./util/Observations/keyObservations";
import LoadingOverlay from "./util/LoadingOverlay/loadingOverlay";
import { ZoomIn, ZoomOut, RefreshCw, BarChart2, LineChart, ChevronDown, ChevronUp, FileText } from 'lucide-react';
import NetworkWelcome from "./components/networkWelcome";

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, zoomPlugin, RadialLinearScale);

// Cache object to store API responses with timestamps
const cache = {
    data: {},
    setItem: (key, value) => {
        const item = {
            value: value,
            timestamp: new Date().toISOString()
        };
        cache.data[key] = item;
        try {
            localStorage.setItem('dashboardCache', JSON.stringify(cache.data));
        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    },
    getItem: (key) => {
        const item = cache.data[key];
        if (item) {
            return {
                value: item.value,
                timestamp: new Date(item.timestamp)
            };
        }
        return null;
    },
};

// Cache expiration time in minutes
const CACHE_EXPIRATION_TIME = 30;

// Load cache from localStorage on initial load
try {
    const storedCache = localStorage.getItem('dashboardCache');
    if (storedCache) {
        cache.data = JSON.parse(storedCache);
    }
} catch (error) {
    console.error('Error loading from localStorage:', error);
}

const scenarioMap = {
    base: 'Base',
    er4109: 'ER050-P1ULV4109',
    er4109_1: 'ER025-P1ULV4109',
    er4139: 'ER075-P1ULV4139',
    er7633: 'ER075-P1ULV7543',
};

const TabButton = ({ label, active, onClick }) => (
    <button
        onClick={onClick}
        className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors whitespace-nowrap ${active
            ? 'border-black text-black hover:bg-transparent'
            : 'border-transparent text-blue-500 hover:text-blue-500 hover:border-gray-300 hover:bg-transparent'
            }`}
    >
        {label}
    </button>
);

const Dashboard = ({ darkMode, selectedScenario, onScenarioChange, selectedTimepoint, selectedNetworks, selectedMode, prePostMode }) => {
    // Check if we're in Grid mode
    const isGridMode = selectedMode === 'GRID ORCHESTRATION';
    const [selectedMetrics] = useState(['Over Voltage Violation', 'Under Voltage Violation', 'Line Violation', 'Power Violation']);
    const defaultDateRange = [parseISO('2024-01-01'), parseISO('2024-12-31T11:59:59')];
    const gridModeDefaultDateRange = [parseISO('2025-03-25T00:00:00'), parseISO('2025-03-26T01:45:00')];
    const [timeRange, setTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [vTimeRange, setVTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [pTimeRange, setPTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [rTimeRange, setRTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [tTimeRange, setTTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [cTimeRange, setCTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [mTimeRange, setMTimeRange] = useState(isGridMode ? gridModeDefaultDateRange : defaultDateRange);
    const [rightPanelOpen, setRightPanelOpen] = useState(false);
    const [rightPanelData, setRightPanelData] = useState(null);
    const [underVoltageViolations, setUnderVoltageViolations] = useState([]);
    const [overVoltageViolations, setOverVoltageViolations] = useState([]);
    const [lineViolations, setLineViolations] = useState([]);
    const [transformerViolations, setTransformerViolations] = useState([]);
    const [voltageData, setVoltageData] = useState([]);
    const [loadProfileData, setLoadProfileData] = useState([]);
    const [transformerData, setTransformerData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [estimationData, setEstimationData] = useState([]);
    const [chartType, setChartType] = useState('bar');
    const [keyObservations, setKeyObservations] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [error, setError] = useState(null);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [externalSelectedTime, setExternalSelectedTime] = useState(null);
    const [activeTab, setActiveTab] = useState('summary');

    // Add reset keys for range sliders
    const [timeRangeResetKey, setTimeRangeResetKey] = useState(0);
    const [vTimeRangeResetKey, setVTimeRangeResetKey] = useState(0);
    const [pTimeRangeResetKey, setPTimeRangeResetKey] = useState(0);
    const [rTimeRangeResetKey, setRTimeRangeResetKey] = useState(0);
    const [tTimeRangeResetKey, setTTimeRangeResetKey] = useState(0);
    const [cTimeRangeResetKey, setCTimeRangeResetKey] = useState(0);
    const [mTimeRangeResetKey, setMTimeRangeResetKey] = useState(0);

    const chartRef = useRef(null);
    const voltageChartRef = useRef(null);
    const powerChartRef = useRef(null);
    const reactivePowerChartRef = useRef(null);
    const transformerChartRef = useRef(null);
    const currentChartRef = useRef(null);
    const estimationChartRef = useRef(null);

    // Reset to summary tab when switching modes
    useEffect(() => {
        setActiveTab('summary');
        // Update all time ranges based on the current mode
        const newDateRange = isGridMode ? gridModeDefaultDateRange : defaultDateRange;
        setTimeRange(newDateRange);
        setVTimeRange(newDateRange);
        setPTimeRange(newDateRange);
        setRTimeRange(newDateRange);
        setTTimeRange(newDateRange);
        setCTimeRange(newDateRange);
        setMTimeRange(newDateRange);

        // Reset all range sliders
        setTimeRangeResetKey(prev => prev + 1);
        setVTimeRangeResetKey(prev => prev + 1);
        setPTimeRangeResetKey(prev => prev + 1);
        setRTimeRangeResetKey(prev => prev + 1);
        setTTimeRangeResetKey(prev => prev + 1);
        setCTimeRangeResetKey(prev => prev + 1);
        setMTimeRangeResetKey(prev => prev + 1);
    }, [selectedMode]);

    const getScenarioParam = (network, selectedScenario) => {
        // In Grid mode, use dsse for specific endpoints
        if (isGridMode) {
            return 'dsse';
        }

        // Otherwise use the normal scenario parameter logic
        if (network === 'large') {
            if (selectedScenario === 'base') {
                return 'base';
            } else if (selectedScenario === 'er1256') {
                return prePostMode === 'post' ? '1155-post' : '1155-pre';
            }
            return 'base';
        } else {
            switch (selectedScenario) {
                case 'base': return 'base';
                case 'er4109': return 'er050-4109';
                case 'er4109_1': return 'er025-4109';
                case 'er4139': return prePostMode === 'post' ? '4139-post' : '4139-pre';
                case 'er7633': return prePostMode === 'post' ? '7543-post' : '7543-pre';
                default: return 'base';
            }
        }
    };

    const fetchData = useCallback(async (forceRefresh = false) => {
        setLoading(true);
        setLoadingProgress(0);
        setError(null);

        // Base endpoints to fetch - we'll modify this based on mode
        let baseEndpoints = [
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/underVoltage',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/overVoltage',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/pf-states',
            // 'http://localhost:3000/api/v1/dashboard/graph/load-profile',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/key-observations',
        ];

        // Only include line/transformer violations endpoints in Dynamic Planning mode
        if (!isGridMode) {
            baseEndpoints.push(
                'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/lineviolations',
                'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/transformerviolations',
                `https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/asset-details/graph?bus_name=${selectedNetworks.small ? 'tr(r:p1udt942-p1udt942lv)' : 'tr(r:p9udt479-p9udt479lv)'}`,
                `https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/asset-details/graph?bus_name=${selectedNetworks.small ? 'l(r:p1udt942lv-p1ulv4193)' : 'l(r:p9udt372lv-p9ulv1022)'}`
            );
        }
        // if (isGridMode) {
        //     baseEndpoints.push(
        //         `http://localhost:3000/api/v1/network-viewer/asset-details/graph?bus_name=${selectedNetworks.small ? 'p1ulv4193' : 'p9ulv1022'}`,
        //     );
        // }

        try {
            let combinedData = {
                underVoltage: [],
                overVoltage: [],
                voltageData: [],
                loadProfile: [],
                lineViolations: [],
                transformerViolations: [],
                transformerData: [],
                currentData: [],
                estimationData: [],
                keyObservations: {}
            };
            const networks = [];

            // Fetch data for all selected networks if the scenario is valid for that network
            if (selectedNetworks.small && isScenarioAvailableForNetwork('small', selectedScenario)) {
                networks.push('small');
            }
            if (selectedNetworks.large && isScenarioAvailableForNetwork('large', selectedScenario)) {
                networks.push('large');
            }

            for (const network of networks) {
                for (let i = 0; i < baseEndpoints.length; i++) {
                    let endpoint = baseEndpoints[i];

                    // Always use 'dsse' scenario for Grid mode for specific endpoints
                    const scenarioParam = getScenarioParam(network, selectedScenario);

                    // Add scenario param to endpoint
                    if (endpoint.includes('?')) {
                        endpoint += `&scenario=${scenarioParam}`;
                    } else {
                        endpoint += `?scenario=${scenarioParam}`;
                    }

                    // Add network specification
                    if (network === 'large') {
                        endpoint += '&feeder_name=large';
                    } else {
                        endpoint += '&feeder_name=small';
                    }

                    const cacheKey = `${endpoint}_${network}_${selectedScenario}_${selectedMode}`;
                    let data;
                    let cachedItem = cache.getItem(cacheKey);

                    if (!forceRefresh && cachedItem && differenceInMinutes(new Date(), cachedItem.timestamp) < CACHE_EXPIRATION_TIME) {
                        data = cachedItem.value;
                    } else {
                        const response = await fetch(endpoint);
                        data = await response.json();
                        cache.setItem(cacheKey, data);
                    }

                    // Update progress
                    const progress = ((networks.indexOf(network) * baseEndpoints.length + i + 1) / (networks.length * baseEndpoints.length)) * 100;
                    setLoadingProgress(Math.round(progress));

                    // Combine data based on endpoint type
                    if (endpoint.includes('underVoltage')) {
                        if (!data.message) {
                            combinedData.underVoltage = [...combinedData.underVoltage, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                y: item.count
                            }))];
                        }
                    } else if (endpoint.includes('overVoltage')) {
                        if (!data.message) {
                            combinedData.overVoltage = [...combinedData.overVoltage, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                y: item.count
                            }))];
                        }
                    } else if (endpoint.includes('pf-states')) {
                        combinedData.voltageData = [...combinedData.voltageData, ...data.map(item => ({
                            x: parseISO(item.timestamp).toISOString(),
                            mag_a: parseFloat(item.v_mag_a_pu),
                            mag_b: parseFloat(item.v_mag_b_pu),
                            mag_c: parseFloat(item.v_mag_c_pu),
                            ang_a: parseFloat(item.v_ang_a),
                            ang_b: parseFloat(item.v_ang_b),
                            ang_c: parseFloat(item.v_ang_c),
                            p_a_kw: parseFloat(item.p_a_kw),
                            p_b_kw: parseFloat(item.p_b_kw),
                            p_c_kw: parseFloat(item.p_c_kw),
                            q_a_kvar: parseFloat(item.q_a_kvar),
                            q_b_kvar: parseFloat(item.q_b_kvar),
                            q_c_kvar: parseFloat(item.q_c_kvar),
                        }))];
                    } else if (endpoint.includes('load-profile')) {
                        combinedData.loadProfile = [...combinedData.loadProfile, ...data.map(item => ({
                            x: parseISO(item.timestamp).toISOString(),
                            kw: parseFloat(item.kw),
                            kvar: parseFloat(item.kvar)
                        }))];
                    } else if (endpoint.includes('lineviolations')) {
                        if (Array.isArray(data)) {
                            combinedData.lineViolations = [...combinedData.lineViolations, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                y: item.violations
                            }))];
                        }
                    } else if (endpoint.includes('transformerviolations')) {
                        if (Array.isArray(data)) {
                            combinedData.transformerViolations = [...combinedData.transformerViolations, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                y: item.violations
                            }))];
                        }
                    } else if (endpoint.includes('bus_name=tr')) {
                        if (Array.isArray(data)) {
                            combinedData.transformerData = [...combinedData.transformerData, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                s_mag_a: parseFloat(item.s_mag_a),
                                s_mag_b: parseFloat(item.s_mag_b),
                                s_mag_c: parseFloat(item.s_mag_c),
                            }))];
                        } else if (data && typeof data === 'object') {
                            if (data.assetDetails && Array.isArray(data.assetDetails)) {
                                combinedData.transformerData = [...combinedData.transformerData, ...data.assetDetails.map(item => ({
                                    x: parseISO(item.timestamp).toISOString(),
                                    s_mag_a: parseFloat(item.s_mag_a || 0),
                                    s_mag_b: parseFloat(item.s_mag_b || 0),
                                    s_mag_c: parseFloat(item.s_mag_c || 0),
                                }))];
                            } else {
                                console.log("Transformer data is not an array, trying to process as single object:", data);
                                try {
                                    if (data.timestamp) {
                                        combinedData.transformerData.push({
                                            x: parseISO(data.timestamp).toISOString(),
                                            s_mag_a: parseFloat(data.s_mag_a || 0),
                                            s_mag_b: parseFloat(data.s_mag_b || 0),
                                            s_mag_c: parseFloat(data.s_mag_c || 0),
                                        });
                                    }
                                } catch (error) {
                                    console.error("Error processing transformer data:", error);
                                }
                            }
                        }
                    } else if (endpoint.includes('bus_name=l')) {
                        if (Array.isArray(data)) {
                            combinedData.currentData = [...combinedData.currentData, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                i_mag_a: parseFloat(item.i_mag_a),
                                i_mag_b: parseFloat(item.i_mag_b),
                                i_mag_c: parseFloat(item.i_mag_c),
                            }))];
                        } else if (data && typeof data === 'object') {
                            if (data.assetDetails && Array.isArray(data.assetDetails)) {
                                combinedData.currentData = [...combinedData.currentData, ...data.assetDetails.map(item => ({
                                    x: parseISO(item.timestamp).toISOString(),
                                    i_mag_a: parseFloat(item.i_mag_a || 0),
                                    i_mag_b: parseFloat(item.i_mag_b || 0),
                                    i_mag_c: parseFloat(item.i_mag_c || 0),
                                }))];
                            } else {
                                console.log("Current data is not an array, trying to process as single object:", data);
                                try {
                                    if (data.timestamp) {
                                        combinedData.currentData.push({
                                            x: parseISO(data.timestamp).toISOString(),
                                            i_mag_a: parseFloat(data.i_mag_a || 0),
                                            i_mag_b: parseFloat(data.i_mag_b || 0),
                                            i_mag_c: parseFloat(data.i_mag_c || 0),
                                        });
                                    }
                                } catch (error) {
                                    console.error("Error processing current data:", error);
                                }
                            }
                        }
                    } else if (endpoint.includes('bus_name=p')) {
                        if (Array.isArray(data)) {
                            combinedData.estimationData = [...combinedData.estimationData, ...data.map(item => ({
                                x: parseISO(item.timestamp).toISOString(),
                                vmag_a: parseFloat(item.vmag_a),
                                vmag_b: parseFloat(item.vmag_b),
                                vmag_c: parseFloat(item.vmag_c),
                                v_mag_a_measure: parseFloat(item.v_mag_a_measure),
                                v_mag_b_measure: parseFloat(item.v_mag_b_measure),
                                v_mag_c_measure: parseFloat(item.v_mag_c_measure),
                            }))];
                        } else if (data && typeof data === 'object') {
                            if (data.assetDetails && Array.isArray(data.assetDetails)) {
                                combinedData.estimationData = [...combinedData.estimationData, ...data.assetDetails.map(item => ({
                                    x: parseISO(item.timestamp).toISOString(),
                                    vmag_a: parseFloat(item.vmag_a || 0),
                                    vmag_b: parseFloat(item.vmag_b || 0),
                                    vmag_c: parseFloat(item.vmag_c || 0),
                                    v_mag_a_measure: parseFloat(item.v_mag_a_measure || 0),
                                    v_mag_b_measure: parseFloat(item.v_mag_b_measure || 0),
                                    v_mag_c_measure: parseFloat(item.v_mag_c_measure || 0),
                                }))];
                            } else {
                                console.log("Estimation data is not an array, trying to process as single object:", data);
                                try {
                                    if (data.timestamp) {
                                        combinedData.estimationData.push({
                                            x: parseISO(data.timestamp).toISOString(),
                                            vmag_a: parseFloat(data.vmag_a || 0),
                                            vmag_b: parseFloat(data.vmag_b || 0),
                                            vmag_c: parseFloat(data.vmag_c || 0),
                                            v_mag_a_measure: parseFloat(data.v_mag_a_measure || 0),
                                            v_mag_b_measure: parseFloat(data.v_mag_b_measure || 0),
                                            v_mag_c_measure: parseFloat(data.v_mag_c_measure || 0),
                                        });
                                    }
                                } catch (error) {
                                    console.error("Error processing estimation data:", error);
                                }
                            }
                        }
                    } else if (endpoint.includes('key-observations')) {
                        combinedData.keyObservations = { ...combinedData.keyObservations, ...data };
                    }
                }
            }

            // Update all states with combined data
            setUnderVoltageViolations(combinedData.underVoltage);
            setOverVoltageViolations(combinedData.overVoltage);
            setVoltageData(combinedData.voltageData);
            setLoadProfileData(combinedData.loadProfile);
            setKeyObservations(combinedData.keyObservations);

            // Only update line/transformer violations in Dynamic Planning mode
            if (!isGridMode) {
                setLineViolations(combinedData.lineViolations);
                setTransformerViolations(combinedData.transformerViolations);
                setTransformerData(combinedData.transformerData);
                setCurrentData(combinedData.currentData);
            } else if (isGridMode) {
                setEstimationData(combinedData.estimationData);
            } else {
                // Reset these values in Grid mode to prevent stale data
                setLineViolations([]);
                setTransformerViolations([]);
                setTransformerData([]);
                setCurrentData([]);
            }

            setLastUpdateTime(new Date());
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [selectedScenario, selectedNetworks, prePostMode, isGridMode]);

    useEffect(() => {
        fetchData();

        // Set up automatic refresh
        const refreshInterval = setInterval(() => {
            fetchData(true);  // Force refresh
        }, CACHE_EXPIRATION_TIME * 60 * 1000);  // Convert minutes to milliseconds

        return () => clearInterval(refreshInterval);  // Clean up on unmount
    }, [fetchData, prePostMode]);

    useEffect(() => {
        if (selectedTimepoint) {
            setExternalSelectedTime(selectedTimepoint);
        }
        if (activeTab === 'summary') {
            setRightPanelOpen(false);
        }
    }, [selectedTimepoint, activeTab]);

    // Add network scenario helper functions
    const isScenarioAvailableForNetwork = (network, scenario) => {
        if (network === 'large') {
            return ['base', 'er1256'].includes(scenario);
        }
        return true; // Small network has all scenarios
    };

    const getChartRefForTab = (tab) => {
        switch (tab) {
            case 'loads':
                return powerChartRef;
            case 'voltage':
                return voltageChartRef;
            case 'violations':
                return chartRef;
            default:
                return null;
        }
    };

    const data = useMemo(() => {
        const datasets = [];

        // Always include voltage violations
        datasets.push({
            label: 'Over Voltage Violation',
            data: overVoltageViolations,
            borderColor: 'rgb(220, 38, 38)', // Darker red border
            backgroundColor: 'rgba(248, 113, 113, 0.8)', // Brighter red with high opacity
            borderWidth: 1.5,
        });

        datasets.push({
            label: 'Under Voltage Violation',
            data: underVoltageViolations,
            borderColor: 'rgb(29, 78, 216)', // Darker blue border
            backgroundColor: 'rgba(96, 165, 250, 0.8)', // Brighter blue with high opacity
            borderWidth: 1.5,
        });

        // Only include line/transformer violations in Dynamic Planning mode
        if (!isGridMode) {
            datasets.push({
                label: 'Line Violation',
                data: lineViolations,
                borderColor: 'rgb(5, 150, 105)', // Darker green border
                backgroundColor: 'rgba(52, 211, 153, 0.8)', // Brighter green with high opacity
                borderWidth: 1.5,
            });

            datasets.push({
                label: 'Power Violation',
                data: transformerViolations,
                borderColor: 'rgb(217, 119, 6)', // Darker yellow/orange border
                backgroundColor: 'rgba(251, 191, 36, 0.8)', // Brighter yellow with high opacity
                borderWidth: 1.5,
            });
        }

        return { datasets };
    }, [underVoltageViolations, overVoltageViolations, lineViolations, transformerViolations, isGridMode]);

    const voltageChartData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_a })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y-magnitude',
            },
            {
                label: 'Phase B Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_b })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-magnitude',
            },
            {
                label: 'Phase C Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_c })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-magnitude',
            },
        ]
    }), [voltageData]);

    const activePowerData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_a_kw })),
                borderColor: 'rgb(191, 189, 36)',
                backgroundColor: 'rgba(191, 189, 36, 0.5)',
                yAxisID: 'y-power',
            },
            {
                label: 'Phase B Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_b_kw })),
                borderColor: 'rgb(25, 53, 212)',
                backgroundColor: 'rgba(25, 53, 212, 0.5)',
                yAxisID: 'y-power',
            },
            {
                label: 'Phase C Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_c_kw })),
                borderColor: 'rgb(202, 235, 16)',
                backgroundColor: 'rgba(202, 235, 16, 0.5)',
                yAxisID: 'y-power',
            }
        ]
    }), [voltageData]);

    const reactivePowerData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_a_kvar })),
                borderColor: 'rgb(255, 159, 64)',
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                yAxisID: 'y-rpower',
            },
            {
                label: 'Phase B Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_b_kvar })),
                borderColor: 'rgb(153, 102, 255)',
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                yAxisID: 'y-rpower',
            },
            {
                label: 'Phase C Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_c_kvar })),
                borderColor: 'rgb(201, 203, 207)',
                backgroundColor: 'rgba(201, 203, 207, 0.5)',
                yAxisID: 'y-rpower',
            }
        ]
    }), [voltageData]);

    const transformerChartData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Apparent Power',
                data: transformerData.map(item => ({ x: item.x, y: item.s_mag_a })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y-transformer',
            },
            {
                label: 'Phase B Apparent Power',
                data: transformerData.map(item => ({ x: item.x, y: item.s_mag_b })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-transformer',
            },
            {
                label: 'Phase C Apparent Power',
                data: transformerData.map(item => ({ x: item.x, y: item.s_mag_c })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-transformer',
            },
        ]
    }), [transformerData]);

    const currentChartData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Current',
                data: currentData.map(item => ({ x: item.x, y: item.i_mag_a })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y-current',
            },
            {
                label: 'Phase B Current',
                data: currentData.map(item => ({ x: item.x, y: item.i_mag_b })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-current',
            },
            {
                label: 'Phase C Current',
                data: currentData.map(item => ({ x: item.x, y: item.i_mag_c })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-current',
            },
        ]
    }), [currentData]);

    const estimationChartData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.vmag_a })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y-estimation',
            },
            {
                label: 'Phase B Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.vmag_b })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-estimation',
            },
            {
                label: 'Phase C Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.vmag_c })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-estimation',
            },
            {
                label: 'Phase A Measured Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.v_mag_a_measure })),
                borderColor: 'rgb(255, 206, 86)',
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
                yAxisID: 'y-estimation',
            },
            {
                label: 'Phase B Measured Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.v_mag_b_measure })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-estimation',
            },
            {
                label: 'Phase C Measured Voltage',
                data: estimationData.map(item => ({ x: item.x, y: item.v_mag_c_measure })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-estimation',
            },
        ]
    }), [estimationData]);

    const ChartComponent = chartType === 'line' ? Line : Bar;
    const VoltageChartComponent = Line;
    const PowerChartComponent = Line;
    const ReactivePowerChartComponent = Line;
    const TransformerChartComponent = Line;
    const CurrentChartComponent = Line;
    const EstimationChartComponent = Line;

    const commonOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM d',
                    }
                },
                title: {
                    display: true,
                    text: 'Time',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
    }), [darkMode]);

    const updateChartOptions = (options, scale) => {
        const newOptions = { ...options };
        if (scale === 'hourly') {
            newOptions.scales.x.time.unit = 'hour';
            newOptions.scales.x.time.displayFormats = { hour: 'HH:mm' };
        } else {
            newOptions.scales.x.time.unit = 'day';
            newOptions.scales.x.time.displayFormats = { day: 'MMM d' };
        }
        return newOptions;
    };

    const options = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: `Violations for Feeder: ${selectedNetworks.large ? 'P9U' : 'P1U'}`,
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            x: {
                ...commonOptions.scales.x,
                min: timeRange[0],
                max: timeRange[1],
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Violations',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
                stacked: chartType === 'bar',
            }
        },
        onClick: async (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = data.datasets[datasetIndex].data[index];
                const formattedTimestamp = parseISO(clickedData.x).toISOString();

                // Handle multiple networks
                const networks = [];
                if (selectedScenario === 'base') {
                    networks.push('small');
                } else {
                    if (selectedNetworks.small && isScenarioAvailableForNetwork('small', selectedScenario)) {
                        networks.push('small');
                    }
                    if (selectedNetworks.large && isScenarioAvailableForNetwork('large', selectedScenario)) {
                        networks.push('large');
                    }
                }

                try {
                    let combinedViolations = [];

                    for (const network of networks) {
                        let endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/asset-details';

                        // Use dsse for Grid mode, otherwise use normal scenario param
                        const scenarioParam = getScenarioParam(network, selectedScenario);
                        endpoint += `?scenario=${scenarioParam}`;

                        if (network === 'large') {
                            endpoint += '&feeder_name=large';
                        }
                        if (network === 'small') {
                            endpoint += '&feeder_name=small';
                        }

                        // Only fetch detailed violations for voltage violations
                        // In Grid mode, we don't have line/power violations
                        if (data.datasets[datasetIndex].label !== 'Line Violation' &&
                            data.datasets[datasetIndex].label !== 'Power Violation') {
                            const response = await fetch(`${endpoint}&timestamp=${formattedTimestamp}`);
                            const detailedData = await response.json();

                            if (detailedData.violations) {
                                combinedViolations = [...combinedViolations, ...detailedData.violations];
                            }
                        }
                    }

                    // Prepare panel data based on violation type
                    if (data.datasets[datasetIndex].label !== 'Line Violation' &&
                        data.datasets[datasetIndex].label !== 'Power Violation') {
                        setRightPanelData({
                            title: `${data.datasets[datasetIndex].label} Details`,
                            data: {
                                "Violation Type": data.datasets[datasetIndex].label,
                                "Number of Violations": clickedData.y,
                                "Timestamp": parseISO(clickedData.x).toUTCString(),
                                "Asset Violations": combinedViolations.map(violation => ({
                                    "Asset ID": violation.asset_id,
                                    "Phase": violation.phase,
                                    "Voltage": violation.voltage,
                                    "Network": violation.asset_id.toLowerCase().startsWith('p9ulv') ? 'P9U' : 'P1U'
                                }))
                            }
                        });
                    } else {
                        setRightPanelData({
                            title: `${data.datasets[datasetIndex].label} Details`,
                            data: {
                                "Violation Type": data.datasets[datasetIndex].label,
                                "Number of Violations": clickedData.y,
                                "Timestamp": format(clickedData.x, "yyyy-MM-dd HH:mm"),
                            }
                        });
                    }
                    setRightPanelOpen(true);
                } catch (error) {
                    console.error('Error fetching violation details:', error);
                }
            }
        }
    }), [commonOptions, darkMode, chartType, data.datasets, selectedScenario, timeRange, selectedNetworks]);

    const voltageOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Voltage Magnitude Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: vTimeRange[0],
                max: vTimeRange[1],
            },
            'y-magnitude': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Voltage Magnitude (p.u.)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = voltageChartData.datasets[datasetIndex].data[index];
                const title = `Voltage Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Magnitude": clickedData.y,
                            "Angle": voltageData[index].ang_a,
                        },
                        "Phase B": {
                            "Magnitude": voltageData[index].mag_b,
                            "Angle": voltageData[index].ang_b,
                        },
                        "Phase C": {
                            "Magnitude": voltageData[index].mag_c,
                            "Angle": voltageData[index].ang_c,
                        }

                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, voltageChartData.datasets, voltageData, vTimeRange]);

    const pOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Active Power Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: pTimeRange[0],
                max: pTimeRange[1],
            },
            'y-power': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Active Power (kW)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = activePowerData.datasets[datasetIndex].data[index];
                const title = `Active Power Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Active Power": clickedData.y,
                            "Reactive Power": voltageData[index].q_a_kvar
                        },
                        "Phase B": {
                            "Active Power": voltageData[index].p_b_kw,
                            "Reactive Power": voltageData[index].q_b_kvar
                        },
                        "Phase C": {
                            "Active Power": voltageData[index].p_c_kw,
                            "Reactive Power": voltageData[index].q_c_kvar
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, activePowerData.datasets, voltageData, pTimeRange]);

    const rOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Reactive Power Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: rTimeRange[0],
                max: rTimeRange[1],
            },
            'y-rpower': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Reactive Power (kVAR)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = reactivePowerData.datasets[datasetIndex].data[index];
                const title = `Reactive Power Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Reactive Power": clickedData.y,
                            "Active Power": voltageData[index].p_a_kw
                        },
                        "Phase B": {
                            "Reactive Power": voltageData[index].q_b_kvar,
                            "Active Power": voltageData[index].p_b_kw
                        },
                        "Phase C": {
                            "Reactive Power": voltageData[index].q_c_kvar,
                            "Active Power": voltageData[index].p_c_kw
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, reactivePowerData.datasets, voltageData, rTimeRange]);

    const tOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Transformer Apparent Power Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: tTimeRange[0],
                max: tTimeRange[1],
            },
            'y-transformer': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Apparent Power (kVA)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = transformerChartData.datasets[datasetIndex].data[index];
                const title = `Transformer Apparent Power Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Apparent Power": clickedData.y,
                        },
                        "Phase B": {
                            "Apparent Power": transformerData[index].s_mag_b,
                        },
                        "Phase C": {
                            "Apparent Power": transformerData[index].s_mag_c,
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, transformerChartData.datasets, transformerData, tTimeRange]);

    const cOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Current Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: cTimeRange[0],
                max: cTimeRange[1],
            },
            'y-current': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Current (A)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = currentChartData.datasets[datasetIndex].data[index];
                const title = `Current Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Current": clickedData.y,
                        },
                        "Phase B": {
                            "Current": currentData[index].i_mag_b,
                        },
                        "Phase C": {
                            "Current": currentData[index].i_mag_c,
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, currentChartData.datasets, currentData, cTimeRange]);

    const eOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Voltage Estimates vs Measurements for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: mTimeRange[0],
                max: mTimeRange[1],
            },
            'y-estimation': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Voltage Magnitude (p.u.)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = estimationChartData.datasets[datasetIndex].data[index];
                const title = `Voltage Estimation Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Voltage": clickedData.y,
                            "Measured Voltage": estimationData[index].v_mag_a_measure
                        },
                        "Phase B": {
                            "Voltage": estimationData[index].vmag_b,
                            "Measured Voltage": estimationData[index].v_mag_b_measure
                        },
                        "Phase C": {
                            "Voltage": estimationData[index].v_mag_c,
                            "Measured Voltage": estimationData[index].v_mag_c_measure
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    },), [commonOptions, darkMode, estimationChartData.datasets, estimationData, mTimeRange]);

    // New radar chart data for the STATE ESTIMATION mode
    const radarChartData = useMemo(() => {
        // Function to safely get total count from violation arrays
        const getViolationTotal = (violationArray) => {
            // Handle the case where data isn't loaded yet
            if (!violationArray || !Array.isArray(violationArray) || violationArray.length === 0) {
                return 0;
            }

            // If it has a message property, it's likely an error response
            if (violationArray.message) {
                return 0;
            }

            // Sum up all violation counts - handle different data structures
            return violationArray.reduce((total, item) => {
                // Different properties might hold the count depending on the endpoint
                const count = parseInt(item.y) || parseInt(item.count) || parseInt(item.violations) || 0;
                return total + count;
            }, 0);
        };

        // Get max value for a specific power type across all readings
        const getMaxPower = (type) => {
            if (!voltageData || !Array.isArray(voltageData) || voltageData.length === 0) {
                return 0;
            }

            // Find max value for the given power type
            let maxVal = -1;

            if (type === 'active') {
                voltageData.forEach(item => {
                    if (!isNaN(item.p_a_kw) && !isNaN(item.p_b_kw)) {
                        const phaseSum =
                            parseFloat(item.p_a_kw) + parseFloat(item.p_b_kw);
                        maxVal = Math.max(maxVal, phaseSum);
                    }
                });
            } else if (type === 'reactive') {
                voltageData.forEach(item => {
                    if (!isNaN(item.q_a_kvar) && !isNaN(item.q_b_kvar)) {
                        const phaseSum =
                            parseFloat(item.q_a_kvar) + parseFloat(item.q_b_kvar);
                        maxVal = Math.max(maxVal, phaseSum);
                    }
                });
            }

            // If we still have 0, use a default value
            return Math.abs((maxVal)) || null;
        };

        // Calculate totals/max for each category - with fallback values that match the image
        const defaultValues = [1693, 1493, 948, 593, 1284];

        // Calculate actual values with fallbacks
        const overVoltageTotal = getViolationTotal(overVoltageViolations);
        const underVoltageTotal = getViolationTotal(underVoltageViolations);
        const lineViolationsTotal = isGridMode ? 0 : getViolationTotal(lineViolations);
        const transformerViolationsTotal = isGridMode ? 0 : getViolationTotal(transformerViolations);

        const labels = isGridMode
            ? ['Over voltage', 'Under voltage'] // Only these metrics in Grid mode
            : ['Over voltage', 'Under voltage', 'Line', 'Asset'];

        const data = isGridMode
            ? [overVoltageTotal, underVoltageTotal]
            : [overVoltageTotal, underVoltageTotal, lineViolationsTotal, transformerViolationsTotal];

        return {
            labels,
            datasets: [
                {
                    label: 'Violations',
                    data,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgb(54, 162, 235)',
                    pointBackgroundColor: isGridMode
                        ? ['red', 'red']
                        : ['red', 'red', 'red', 'red'],
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(54, 162, 235)',
                    pointRadius: 5,
                    pointHoverRadius: 7,
                }
            ]
        };
    }, [overVoltageViolations, underVoltageViolations, lineViolations, transformerViolations, voltageData, isGridMode]);

    // Radar chart options
    const radarChartOptions = useMemo(() => {
        // Find the maximum value in our dataset or use a default
        const maxValue = Math.max(...(radarChartData.datasets[0].data || [1000]));

        return {
            scales: {
                r: {
                    beginAtZero: true,
                    angleLines: {
                        display: true,
                        color: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    },
                    grid: {
                        circular: true,
                        color: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    },
                    ticks: {
                        color: darkMode ? '#E5E7EB' : '#1F2937',
                        backdropColor: 'transparent',
                        z: 100,
                        // Use a reasonable step size
                        stepSize: Math.ceil(maxValue / 5)
                    },
                    pointLabels: {
                        color: darkMode ? '#E5E7EB' : '#1F2937',
                        font: {
                            size: 12
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const index = context.dataIndex;
                            const value = context.raw;
                            const label = radarChartData.labels[index];

                            return `${label}: ${value.toLocaleString()}`;
                        }
                    }
                }
            },
            maintainAspectRatio: false,
        };
    }, [darkMode, radarChartData]);

    const ChartTypeSelector = ({ value, onChange, darkMode }) => (
        <div className="flex items-center space-x-2">
            <span className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Chart Type:</span>
            <button
                onClick={() => onChange('line')}
                className={`p-2 rounded ${value === 'line'
                    ? (darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                    : (darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')}`}
            >
                <LineChart size={20} />
            </button>
            <button
                onClick={() => onChange('bar')}
                className={`p-2 rounded ${value === 'bar'
                    ? (darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                    : (darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')}`}
            >
                <BarChart2 size={20} />
            </button>
        </div>
    );

    const handleCloseRightPanel = () => {
        setRightPanelOpen(false);
    };

    const filteredData = useMemo(() => ({
        ...data,
        datasets: data.datasets.filter(ds => selectedMetrics.includes(ds.label))
    }), [data, selectedMetrics]);

    const handleRangeChange = useCallback((newRange) => {
        setTimeRange(newRange);
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    }, []);

    const handleVoltageRangeChange = useCallback((newRange) => {
        setVTimeRange(newRange);
        if (voltageChartRef.current) {
            voltageChartRef.current.resetZoom();
        }
    }, []);

    const handlePRangeChange = useCallback((newRange) => {
        setPTimeRange(newRange);
        if (powerChartRef.current) {
            powerChartRef.current.resetZoom();
        }
    }, []);

    const handleRRangeChange = useCallback((newRange) => {
        setRTimeRange(newRange);
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.resetZoom();
        }
    }, []);

    const handleTRangeChange = useCallback((newRange) => {
        setTTimeRange(newRange);
        if (transformerChartRef.current) {
            transformerChartRef.current.resetZoom();
        }
    }, []);

    const handleCRangeChange = useCallback((newRange) => {
        setCTimeRange(newRange);
        if (currentChartRef.current) {
            currentChartRef.current.resetZoom();
        }
    }, []);

    const handleERangeChange = useCallback((newRange) => {
        setMTimeRange(newRange);
        if (estimationChartRef.current) {
            estimationChartRef.current.resetZoom();
        }
    }, []);

    // Modified reset functions to also reset the time range sliders
    const handleZoomIn = (chartRef) => {
        if (chartRef?.current) {
            chartRef.current.zoom(1.5);
        }
    };

    const handleZoomOut = (chartRef) => {
        if (chartRef?.current) {
            chartRef.current.zoom(0.9);
        }
    };

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
            // Also reset the time range slider using the appropriate date range
            setTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setTimeRangeResetKey(prev => prev + 1);
        }
    };

    const handleVoltageResetZoom = () => {
        if (voltageChartRef.current) {
            voltageChartRef.current.resetZoom();
            setVTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setVTimeRangeResetKey(prev => prev + 1);
        }
    };

    const handlePResetZoom = () => {
        if (powerChartRef.current) {
            powerChartRef.current.resetZoom();
            // Also reset the power time range slider
            setPTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setPTimeRangeResetKey(prev => prev + 1);
        }
    };

    const handleRResetZoom = () => {
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.resetZoom();
            // Also reset the reactive power time range slider
            setRTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setRTimeRangeResetKey(prev => prev + 1);
        }
    };

    const handleTResetZoom = () => {
        if (transformerChartRef.current) {
            transformerChartRef.current.resetZoom();
            // Also reset the transformer time range slider
            setTTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setTTimeRangeResetKey(prev => prev + 1);
        }
    };

    const handleCResetZoom = () => {
        if (currentChartRef.current) {
            currentChartRef.current.resetZoom();
            // Also reset the current time range slider
            setCTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setCTimeRangeResetKey(prev => prev + 1);
        }
    }

    const handleEResetZoom = () => {
        if (estimationChartRef.current) {
            estimationChartRef.current.resetZoom();
            // Also reset the estimation time range slider
            setMTimeRange([...(isGridMode ? gridModeDefaultDateRange : defaultDateRange)]);
            setMTimeRangeResetKey(prev => prev + 1);
        }
    };

    if (!Object.values(selectedNetworks).some(Boolean) || !selectedScenario) {
        return (
            <div className="h-full overflow-auto">
                <NetworkWelcome
                    darkMode={darkMode}
                />
            </div>
        );
    }

    if (loading || error) {
        return (
            <div className="h-full overflow-auto">
                <LoadingOverlay
                    darkMode={darkMode}
                    progress={loadingProgress}
                    error={error}
                    onRetry={() => fetchData(true)}
                />
            </div>
        );
    }

    return (
        <div className={`flex flex-col h-full ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
            {/* Tab Navigation */}
            <div className="border-b border-gray-200 dark:border-gray-700 px-4">
                <div className="flex overflow-x-auto hide-scrollbar">
                    <TabButton
                        label="Summary"
                        active={activeTab === 'summary'}
                        onClick={() => setActiveTab('summary')}
                    />
                    <TabButton
                        label="Loads (Input)"
                        active={activeTab === 'loads'}
                        onClick={() => setActiveTab('loads')}
                    />
                    <TabButton
                        label="Voltage (Output)"
                        active={activeTab === 'voltage'}
                        onClick={() => setActiveTab('voltage')}
                    />
                    {/* Only show Power and Current tabs in Dynamic Planning mode */}
                    {!isGridMode && (
                        <>
                            <TabButton
                                label="Power (Output)"
                                active={activeTab === 'power'}
                                onClick={() => setActiveTab('power')}
                            />
                            <TabButton
                                label="Current (Output)"
                                active={activeTab === 'current'}
                                onClick={() => setActiveTab('current')}
                            />
                        </>
                    )}
                    {/* {isGridMode && (
                        <>
                            <TabButton
                                label="Voltage Comparisions"
                                active={activeTab === 'estimation'}
                                onClick={() => setActiveTab('estimation')}
                            />
                        </>
                    )} */}
                    <TabButton
                        label="Violations"
                        active={activeTab === 'violations'}
                        onClick={() => setActiveTab('violations')}
                    />
                </div>
            </div>

            {/* Tab Content - Set to overflow-y-auto for scrolling */}
            <div className="flex-1 overflow-y-auto p-4">
                {activeTab === 'summary' ? (
                    <div>
                        <div className="flex justify-between items-center mb-6">
                            <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                Key Observations
                            </h2>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <KeyObservations observations={keyObservations} darkMode={darkMode} />
                        </div>
                        {/* Spider/Radar Chart */}
                        <div className={`mt-6 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <h2 className={`text-xl font-semibold mb-4 ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                Total violations breakdown
                            </h2>
                            <div className="h-[280px] w-full">
                                <Radar
                                    data={radarChartData}
                                    options={radarChartOptions}
                                    fallbackContent={
                                        <div className="flex h-full items-center justify-center">
                                            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                                                Loading violation data...
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            {/* Legend for the radar chart - showing values with the appropriate colors */}
                            <div className={`grid ${isGridMode ? 'grid-cols-2 md:grid-cols-2' : 'grid-cols-2 md:grid-cols-4'} gap-4 mt-6`}>
                                {radarChartData.labels.map((label, index) => {
                                    const value = radarChartData.datasets[0].data[index];
                                    // Adjust which indices are red based on mode
                                    const isRedValue = isGridMode
                                        ? [0, 1].includes(index) // Only voltage violations are red in Grid mode
                                        : [0, 1, 2, 3].includes(index); // All violations are red in Dynamic mode
                                    return (
                                        <div key={index} className="flex flex-col items-center">
                                            <span className={`text-lg font-bold ${isRedValue ? 'text-red-500' : ''}`}>
                                                {value.toLocaleString()}
                                            </span>
                                            <span className="text-sm text-center text-gray-500 dark:text-gray-400">{label}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : activeTab === 'loads' ? (
                    <div className="space-y-8">
                        {/* Active Power Chart */}
                        <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className={`text-lg font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                    Active Power
                                </h3>
                            </div>
                            <div className="h-[300px]">
                                <PowerChartComponent ref={powerChartRef} options={pOptions} data={activePowerData} />
                            </div>
                            <div className="p-4">
                                <RangeSlider
                                    min={isGridMode ? parseISO('2025-03-25') : parseISO('2024-01-01')}
                                    max={isGridMode ? parseISO('2025-03-26T01:45:00') : parseISO('2024-12-31T11:59:59')}
                                    value={pTimeRange}
                                    onChange={handlePRangeChange}
                                    darkMode={darkMode}
                                    resetKey={pTimeRangeResetKey}
                                />
                            </div>
                            <div className="flex justify-center p-4 space-x-2">
                                <button onClick={() => handleZoomIn(powerChartRef)} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomIn size={16} />
                                </button>
                                <button onClick={() => handleZoomOut(powerChartRef)} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomOut size={16} />
                                </button>
                                <button onClick={handlePResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <RefreshCw size={16} />
                                </button>
                            </div>
                        </div>

                        {/* Reactive Power Chart */}
                        <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className={`text-lg font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                    Reactive Power
                                </h3>
                            </div>
                            <div className="h-[300px]">
                                <ReactivePowerChartComponent ref={reactivePowerChartRef} options={rOptions} data={reactivePowerData} />
                            </div>
                            <div className="p-4">
                                <RangeSlider
                                    min={isGridMode ? parseISO('2025-03-25') : parseISO('2024-01-01')}
                                    max={isGridMode ? parseISO('2025-03-26T01:45:00') : parseISO('2024-12-31T11:59:59')}
                                    value={rTimeRange}
                                    onChange={handleRRangeChange}
                                    darkMode={darkMode}
                                    resetKey={rTimeRangeResetKey}
                                />
                            </div>
                            <div className="flex justify-center p-4 space-x-2">
                                <button onClick={() => handleZoomIn(reactivePowerChartRef)} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomIn size={16} />
                                </button>
                                <button onClick={() => handleZoomOut(reactivePowerChartRef)} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomOut size={16} />
                                </button>
                                <button onClick={handleRResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <RefreshCw size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                        {/* Chart Controls */}
                        <div className="flex justify-between items-center mb-4">
                            <h3 className={`text-lg font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                {activeTab === 'voltage' && 'Voltage Magnitude'}
                                {activeTab === 'power' && 'Power'}
                                {activeTab === 'current' && 'Current'}
                                {activeTab === 'estimation' && 'Voltage Estimation'}
                                {activeTab === 'violations' && 'Violations'}
                            </h3>
                            {activeTab === 'violations' && prePostMode !== 'post' && (overVoltageViolations.message !== 'No violations found.' || underVoltageViolations.message !== 'No violations found.' || (!isGridMode && (lineViolations.length > 1 || transformerViolations.length > 1))) && (
                                <ChartTypeSelector value={chartType} onChange={setChartType} darkMode={darkMode} />
                            )}
                        </div>

                        {/* Chart */}
                        <div className="h-[500px]">
                            {activeTab === 'voltage' && <VoltageChartComponent ref={voltageChartRef} options={voltageOptions} data={voltageChartData} />}
                            {!isGridMode && activeTab === 'power' && <TransformerChartComponent ref={transformerChartRef} options={tOptions} data={transformerChartData} />}
                            {!isGridMode && activeTab === 'current' && <CurrentChartComponent ref={currentChartRef} options={cOptions} data={currentChartData} />}
                            {isGridMode && activeTab === 'estimation' && <EstimationChartComponent ref={estimationChartRef} options={eOptions} data={estimationChartData} />}
                            {activeTab === 'violations' && prePostMode !== 'post' && (overVoltageViolations.message !== 'No violations found.' || underVoltageViolations.message !== 'No violations found.' || (!isGridMode && (lineViolations.length > 1 || transformerViolations.length > 1))) ?
                                <ChartComponent ref={chartRef} options={options} data={filteredData} />
                                : (activeTab === 'violations' && (
                                    <div className="flex justify-center items-center h-full">
                                        <span className={`text-m ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                            {isGridMode ? 'No Voltage Violations found.' : 'No Voltage/Line/Power Violations found.'}
                                        </span>
                                    </div>
                                ))}
                        </div>

                        {/* Range Slider */}
                        {(activeTab === 'violations' && prePostMode !== 'post' &&
                            (overVoltageViolations.message !== 'No violations found.' ||
                                underVoltageViolations.message !== 'No violations found.' ||
                                (!isGridMode && (lineViolations.length > 1 || transformerViolations.length > 1)))) ||
                            activeTab !== 'violations' ? (
                            <div className="p-4">
                                <RangeSlider
                                    min={isGridMode ? parseISO('2025-03-25') : parseISO('2024-01-01')}
                                    max={isGridMode ? parseISO('2025-03-26T01:45:00') : parseISO('2024-12-31T11:59:59')}
                                    value={activeTab === 'voltage' ? vTimeRange : activeTab === 'power' ? tTimeRange : activeTab === 'current' ? cTimeRange : activeTab === 'estimation' ? mTimeRange : timeRange }
                                    onChange={activeTab === 'voltage' ? handleVoltageRangeChange : activeTab === 'power' ? handleTRangeChange : activeTab === 'current' ? handleCRangeChange : activeTab === 'estimation' ? handleERangeChange : handleRangeChange}
                                    darkMode={darkMode}
                                    resetKey={activeTab === 'voltage' ? vTimeRangeResetKey : activeTab === 'power' ? tTimeRangeResetKey : activeTab === 'current' ? cTimeRangeResetKey : activeTab === 'estimation' ? mTimeRangeResetKey : timeRangeResetKey}
                                />
                            </div>
                        ) : null}

                        {/* Zoom Controls */}
                        {(activeTab === 'violations' && prePostMode !== 'post' &&
                            (overVoltageViolations.message !== 'No violations found.' ||
                                underVoltageViolations.message !== 'No violations found.' ||
                                (!isGridMode && (lineViolations.length > 1 || transformerViolations.length > 1)))) ||
                            activeTab !== 'violations' ? (
                            <div className="flex justify-center mt-4 space-x-2">
                                <button onClick={() => {
                                    if (activeTab === 'voltage') handleZoomIn(voltageChartRef);
                                    if (activeTab === 'power') handleZoomIn(transformerChartRef);
                                    if (activeTab === 'current') handleZoomIn(currentChartRef);
                                    if (activeTab === 'estimation') handleZoomIn(estimationChartRef);
                                    if (activeTab === 'violations') handleZoomIn(chartRef);
                                }} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomIn size={16} />
                                </button>
                                <button onClick={() => {
                                    if (activeTab === 'voltage') handleZoomOut(voltageChartRef);
                                    if (activeTab === 'power') handleZoomOut(transformerChartRef);
                                    if (activeTab === 'current') handleZoomOut(currentChartRef);
                                    if (activeTab === 'estimation') handleZoomOut(estimationChartRef);
                                    if (activeTab === 'violations') handleZoomOut(chartRef);
                                }} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <ZoomOut size={16} />
                                </button>
                                <button onClick={() => {
                                    if (activeTab === 'voltage') handleVoltageResetZoom();
                                    if (activeTab === 'power') handleTResetZoom();
                                    if (activeTab === 'current') handleCResetZoom();
                                    if (activeTab === 'estimation') handleEResetZoom();
                                    if (activeTab === 'violations') handleResetZoom();
                                }} className={`p-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}>
                                    <RefreshCw size={16} />
                                </button>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>

            <RightPanel
                isOpen={rightPanelOpen}
                onClose={handleCloseRightPanel}
                panelData={rightPanelData}
                darkMode={darkMode}
            />
        </div>
    );
};

export default Dashboard;