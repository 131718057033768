import React, { useState, useEffect, useRef } from 'react';
import Dashboard from '../dashboard';
import AISummary from './aiSummary';
import Timeline from './timeline';
import { FileText } from 'lucide-react';

const DashboardModal = ({ isOpen, onClose, darkMode, selectedScenario, selectedNetworks, selectedTimepoint, selectedMode, prePostMode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-[950] overflow-hidden">
      {/* Backdrop */}
      <div className="fixed bg-black bg-opacity-50" onClick={onClose}></div>

      {/* Modal - Positioned with fixed height and bounds */}
      <div className={`fixed left-80 right-0 top-28 bottom-0 ${darkMode ? 'bg-gray-900' : 'bg-white'} flex flex-col`}>
        {/* Dashboard Content - Fixed height with overflow handling */}
        <div className="flex-1 overflow-hidden">
          <Dashboard darkMode={darkMode} selectedScenario={selectedScenario} selectedNetworks={selectedNetworks} selectedTimepoint={selectedTimepoint} selectedMode={selectedMode} prePostMode={prePostMode} />
        </div>
      </div>
    </div>
  );
};

const NetworkViewerTabs = ({
  children,
  darkMode,
  selectedScenario,
  onScenarioChange,
  analysisData,
  isLoading,
  selectedNetworks,
  onNetworkFilterChange,
  onDashboardOpen,
  onQueueOpen,
  onPreviewOpen,
  geoJsonData,
  onSelect,
  selectedTimepoint,
  onTimeSelect,
  onSearch,
  activeTab,
  onTabChange,
  selectedMode,
  defaultTimestamp,
  worstPerformingHour,
  onClearTimestamp,
  prePostMode = 'pre',
  onPrePostToggle,
  loadingProgress,
  error,
  onRetry,
  setWorstPerformingHour
}) => {
  const [localActiveTab, setLocalActiveTab] = useState('network');
  const currentActiveTab = activeTab || localActiveTab;
  const [prevMode, setPrevMode] = useState(selectedMode);
  const [prevWorstHour, setPrevWorstHour] = useState(worstPerformingHour);
  const timerRef = useRef(null);

  // Log when worst hour changes
  useEffect(() => {
    if (worstPerformingHour !== prevWorstHour) {
      console.log(`NetworkViewerTabs: Worst hour changed from ${prevWorstHour || 'null'} to ${worstPerformingHour || 'null'}`);
      setPrevWorstHour(worstPerformingHour);
    }
  }, [worstPerformingHour, prevWorstHour]);

  // Handle mode changes
  useEffect(() => {
    if (prevMode !== selectedMode) {
      console.log(`NetworkViewerTabs: Mode changed from ${prevMode} to ${selectedMode}`);
      setPrevMode(selectedMode);

      // Force reset selected timepoint to null when mode changes
      if (onTimeSelect) {
        console.log(`NetworkViewerTabs: Forcing timepoint reset due to mode change`);
        onTimeSelect(null);
      }

      // Create a special timeout to ensure state is fully reset before dispatching event
      setTimeout(() => {
        // Dispatch mode change event
        const event = new CustomEvent('modeChanged', {
          detail: {
            previousMode: prevMode,
            currentMode: selectedMode,
            forceReset: true // Add a flag indicating this should force all date resets
          }
        });
        window.dispatchEvent(event);

        console.log(`NetworkViewerTabs: Dispatched modeChanged event with forceReset for ${selectedMode}`);
      }, 200);
    }
  }, [selectedMode, prevMode, onTimeSelect, currentActiveTab]);

  const handleTabChange = (tab) => {
    // Use the prop handler if provided, otherwise update local state
    if (onTabChange) {
      onTabChange(tab);
    } else {
      setLocalActiveTab(tab);
    }

    // Force tooltip refresh when switching back to network tab
    if (tab === 'network' && selectedTimepoint) {
      // Allow time for the tab switch to complete
      setTimeout(() => {
        // Create a DOM event that Timeline can listen for
        const event = new CustomEvent('tabSwitchToNetwork', {
          detail: { timestamp: Date.now(), mode: selectedMode }
        });
        window.dispatchEvent(event);
      }, 200);
    }
  };

  // Clear timestamp handler - mode aware
  const handleClearTimestamp = () => {
    if (onClearTimestamp) {
      // Use the worst performing hour for the current mode
      onClearTimestamp(selectedMode);
    } else if (worstPerformingHour) {
      // Fallback: Use the current mode's worst performing hour
      onTimeSelect(worstPerformingHour);
    } else {
      // No worst hour available, just clear selection
      onTimeSelect(null);
    }
  };

  const hasCompletedSetup = Object.values(selectedNetworks).some(Boolean) && selectedScenario;
  const [localScenario, setLocalScenario] = useState(selectedScenario);
  const [localNetworks, setLocalNetworks] = useState(selectedNetworks);

  // Track when scenario or networks change to reset timepoint
  useEffect(() => {
    if (selectedScenario !== localScenario) {
      setLocalScenario(selectedScenario);
      // Reset timepoint when scenario changes
      if (onTimeSelect) {
        onTimeSelect(null);
      }
      // Also reset worst performing hour for the current mode
      if (setWorstPerformingHour) {
        setWorstPerformingHour(null, selectedMode);
      }
    }
  }, [selectedScenario, localScenario, onTimeSelect, setWorstPerformingHour, selectedMode]);

  useEffect(() => {
    const networkChanged = JSON.stringify(selectedNetworks) !== JSON.stringify(localNetworks);
    if (networkChanged) {
      setLocalNetworks(selectedNetworks);
      // Reset timepoint when networks change
      if (onTimeSelect) {
        onTimeSelect(null);
      }
      // Also reset worst performing hour for the current mode
      if (setWorstPerformingHour) {
        setWorstPerformingHour(null, selectedMode);
      }
    }
  }, [selectedNetworks, localNetworks, onTimeSelect, setWorstPerformingHour, selectedMode]);

  // Handle Pre/Post toggle and notify parent component
  const handlePrePostToggle = (mode) => {
    if (onPrePostToggle) {
      onPrePostToggle(mode);
    }
    // Reset worst performing hour when toggling pre/post mode
    if (setWorstPerformingHour) {
      setWorstPerformingHour(null, selectedMode);
    }
  };

  // Handle time selection
  const handleTimeSelect = (timepoint) => {
    console.log(`NetworkViewerTabs handleTimeSelect: ${timepoint} for ${selectedMode}`);
    if (onTimeSelect) {
      onTimeSelect(timepoint); // This will update the App state
    }
  };

  return (
    <div className={`h-[calc(100vh-3.5rem)] flex flex-col ${darkMode ? 'bg-gray-900' : 'bg-white'} overflow-hidden`}>
      <div className="flex flex-1 overflow-hidden">
        {/* AI Summary Panel - Fixed width */}
        <div className="w-80 border-r border-gray-50 dark:border-gray-400 overflow-y-auto">
          <AISummary
            darkMode={darkMode}
            selectedScenario={selectedScenario}
            onScenarioChange={onScenarioChange}
            onExpand={onDashboardOpen}
            onQueueClick={onQueueOpen}
            onPreviewClick={onPreviewOpen}
            analysisData={analysisData}
            isLoading={isLoading}
            onNetworkFilterChange={onNetworkFilterChange}
            selectedNetworks={selectedNetworks}
            onSearch={onSearch}
            selectedMode={selectedMode}
            prePostMode={prePostMode}
            onPrePostToggle={handlePrePostToggle}
          />
        </div>

        {/* Main Content Area - Flex grow with overflow handling */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Tabs Container */}
          <div className="flex items-center space-x-2 px-4 pt-2 border-b border-gray-200 dark:border-gray-400 bg-inherit">
            <button
              onClick={() => handleTabChange('network')}
              className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-colors relative ${currentActiveTab === 'network'
                  ? 'text-white bg-black hover:bg-black'
                  : 'text-blue-600 bg-white hover:bg-gray-100'
                }`}
              disabled={!hasCompletedSetup}
            >
              Network Viewer
              {currentActiveTab === 'network' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>

            <button
              onClick={() => handleTabChange('dashboard')}
              className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-colors relative ${currentActiveTab === 'dashboard'
                  ? 'text-white bg-black hover:bg-black'
                  : 'text-blue-600 bg-white hover:bg-gray-100'
                }`}
              disabled={!hasCompletedSetup}
            >
              {selectedMode === 'GRID ORCHESTRATION' ? 'Analysis Dashboard' : '8760 Analysis Results'}
              {currentActiveTab === 'dashboard' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>
            <div className="right-0 absolute flex items-center space-x-2 pb-1">
              <button className={`flex items-center p-2 rounded ${darkMode ? 'border-gray-300 hover:bg-gray-500 text-white' : 'border-black hover:bg-gray-100 text-black'
                }`}>
                <FileText size={20} />
                <span className="ml-2">Generate Report</span>
              </button>
            </div>
          </div>

          {/* Timeline Component - Fixed height */}
          {!isLoading && hasCompletedSetup && (
            <Timeline
              darkMode={darkMode}
              selectedScenario={selectedScenario}
              geoJsonData={geoJsonData}
              onSelect={onSelect}
              selectedNetworks={selectedNetworks}
              onTimeSelect={handleTimeSelect}
              selectedTimepoint={selectedTimepoint}
              showSearchAndLegend={currentActiveTab === 'network'}
              defaultTimestamp={defaultTimestamp}
              onClearTimestamp={handleClearTimestamp}
              prePostMode={prePostMode}
              isLoading={isLoading}
              loadingProgress={loadingProgress}
              error={error}
              onRetry={onRetry}
              selectedMode={selectedMode}
              worstPerformingHour={worstPerformingHour}
            />
          )}

          {/* Content Area - Flex grow with overflow handling */}
          <div className="flex-1 overflow-hidden">
            {currentActiveTab === 'network' ? (
              children
            ) : (
              <DashboardModal
                isOpen={currentActiveTab === 'dashboard'}
                onClose={() => handleTabChange('network')}
                darkMode={darkMode}
                selectedScenario={selectedScenario}
                selectedNetworks={selectedNetworks}
                selectedTimepoint={selectedTimepoint}
                selectedMode={selectedMode}
                prePostMode={prePostMode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkViewerTabs;